import React from 'react'
import { Stack, Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const LoginLink: React.FC = () => {
  const { t } = useTranslation('main')

  return (
    <Stack
      sx={{ textAlign: 'center', marginTop: 'auto', pt: 5 }}
      mb={2.25}
      spacing={1.375}
    >
      <Typography variant="body2">
        <>
          {t('footer.haveLogin')}
          <MuiLink
            component={RouterLink}
            to="/"
            sx={{ fontWeight: 700, textDecoration: 'none' }}
          >
            {t('footer.haveLoginLink')}
          </MuiLink>
        </>
      </Typography>
    </Stack>
  )
}
