import { FC, useEffect } from 'react'

import {
  errorToString,
  gtmEvents,
  SubmissionTrackingStatus,
} from '@procom-labs/common'

import { msalInstance } from '@auth-portal/config'
import { useTrackingWrapper } from '@auth-portal/hooks'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

export const MSALProvider: FC = ({ children }) => {
  const { track } = useTrackingWrapper()

  useEffect(() => {
    let callBackId: string | null = null
    const initializeMsal = async (): Promise<void> => {
      try {
        await msalInstance.initialize()
        const accounts = msalInstance.getAllAccounts()
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0])
        }

        callBackId = msalInstance.addEventCallback((event: EventMessage) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult
            const { account } = payload
            msalInstance.setActiveAccount(account)
          }
        })
      } catch (error) {
        track({
          eventName: gtmEvents.InitializingMsSsoLogin,
          submissionStatus: SubmissionTrackingStatus.Failed,
          loginType: 'MS_SSO_Login',
          isGTMTracking: true,
          isMixPanelTracking: true,
          isTrackingEnabled: true,
          errorMessage: errorToString(error),
        })
      }
    }
    initializeMsal()

    return () => {
      if (callBackId) msalInstance.removeEventCallback(callBackId)
    }
  }, [track])
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
