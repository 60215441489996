import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, DialogContent, DialogTitle, Typography } from '@mui/material'
import { GorillaDialog } from '@procom-labs/atoms'

export const ContactConfirmationModal: React.FC<{
  isOpen: boolean
}> = ({ isOpen }) => {
  const { t } = useTranslation('main')
  const navigate = useNavigate()

  return (
    <GorillaDialog isLightMode fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          textAlign="center"
          mt={2}
        >
          {t('form.alerts.heading')}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography my={2}>{t('form.alerts.subHeading')}</Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mb: 4,
            mt: 2,
          }}
          onClick={() => navigate('/')}
        >
          {t('form.reset.backLoginBtn')}
        </Button>
      </DialogContent>
    </GorillaDialog>
  )
}
