import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { AlertTitle } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar, { SnackbarOrigin, SnackbarProps } from '@mui/material/Snackbar'

export interface AlertState extends SnackbarOrigin {
  open?: boolean
  autoHide?: boolean
  message: React.ReactNode
  title?: React.ReactNode
  timeout?: number
  variant?: 'outlined' | 'standard' | 'filled'
  severity: 'error' | 'info' | 'success' | 'warning'
}

export type AlertContextType = {
  addAlert: (payload: Partial<AlertState>) => void
  removeAlert: () => void
}

type AuthContextProviderProps = {
  children: ReactNode
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const AlertContext = createContext({} as AlertContextType)

const initialAlertState: AlertState = {
  open: false,
  autoHide: true,
  vertical: 'top',
  horizontal: 'center',
  message: '',
  title: null,
  variant: 'filled',
  severity: 'success',
  timeout: 5000,
}

export const AlertContextProvider: FC<AuthContextProviderProps> = ({
  children,
}: AuthContextProviderProps) => {
  const [alert, setAlert] = useState<AlertState>(initialAlertState)
  const {
    open,
    title,
    message,
    severity,
    variant,
    vertical,
    horizontal,
    timeout,
    autoHide,
  } = alert
  const removeAlert = useCallback((): void => {
    setAlert((prev) => ({
      ...prev,
      open: false,
    }))
  }, [])

  const addAlert = useCallback((param: Partial<AlertState>): void => {
    setAlert(() => ({
      ...initialAlertState,
      ...param,
      open: true,
    }))
  }, [])

  const contextValue: AlertContextType = useMemo(
    () => ({
      addAlert,
      removeAlert,
    }),
    [addAlert, removeAlert]
  )
  const snackBarprops: SnackbarProps = {
    open,
    ...(autoHide && { autoHideDuration: timeout }),
    anchorOrigin: {
      vertical,
      horizontal,
    },
    onClose: removeAlert,
  }

  return (
    <AlertContext.Provider value={contextValue}>
      <Snackbar {...snackBarprops}>
        <Alert
          onClose={removeAlert}
          variant={variant}
          severity={severity}
          sx={{
            width: '100%',
            mt: { xs: 8.5, md: 0 },
          }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  )
}
