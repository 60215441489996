import { FC, useCallback, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import { Button } from '@mui/material'
import { LoginTypes } from '@procom-labs/common'
import { MfaLoginCodeVerification } from '@procom-labs/organisms'

import { BaseLayout, Header } from '@auth-portal/components'
import { rollbarInstance } from '@auth-portal/providers'
import { authService, mfaService } from '@auth-portal/services'
import { mfaStore } from '@auth-portal/store'

const initialValues = {
  verificationCode: '',
}

export const LoginMfa: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main')

  const loginType: LoginTypes | null = useObservable(
    () => authService.loginType$
  )

  const isCognitoUserSessionExpired = useObservable(
    () => authService.isCognitoUserSessionExpired$
  )

  const currentCognitoUserEmail = useObservable(
    () => authService.currentCognitoUserEmail$
  )

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required(t('common.form.code.error')),
  })

  const handleSubmit = useCallback(() => {}, [])

  useEffect(() => {
    if (isCognitoUserSessionExpired) {
      authService.isCognitoUserSessionExpiredSubject.value = false
      navigate('/?sessionExpired=true')
    }
  }, [isCognitoUserSessionExpired, navigate])

  const handleResetCognito = useCallback(() => {
    authService.resetCognito()
  }, [])

  return (
    <BaseLayout>
      <>
        <Header />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          <Form>
            {currentCognitoUserEmail ? (
              <MfaLoginCodeVerification
                rollbarInstance={rollbarInstance}
                authService={authService}
                mfaService={mfaService}
                mfaStore={mfaStore}
                currentCognitoUserEmail={currentCognitoUserEmail}
                loginType={loginType}
              />
            ) : null}

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginBlockEnd: 2 }}
              id="cta-mfa-verify-code"
            >
              {t('common.btn.verify')}
            </Button>
            <Button
              component={Link}
              to="/"
              fullWidth
              id="btn-back-to-login"
              onClick={handleResetCognito}
              sx={{ marginBlockEnd: 4 }}
            >
              {t('form.reset.backLoginBtn')}
            </Button>
          </Form>
        </Formik>
      </>
    </BaseLayout>
  )
}
