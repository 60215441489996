import Values from 'values.js'

import { Palette, ThemeOptions } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

import { PaletteModeCode } from '../../enums'
import { getDarkThemeConfig } from './dark'

const SPACING_BASE = 8

export const PALETTE = getDarkThemeConfig(PaletteModeCode.dark)
  .palette as Palette

// https://uxplanet.org/responsive-design-best-practices-c6d3f5fd163b
const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    k2: 1900,
    k4: 3000,
  },
}

const SHAPE = {
  borderRadius: 4, // from default
  radius: {
    small: '2px',
    normal: '4px',
    larger: '6px',
    large: '8px',
  },
}

const MIXINS = {
  toolbar: {
    height: 64,
  },
  menuButton: {
    display: 'block',
    color: 'inherit',

    '&.button-variant-text': {
      borderRadius: '0px',
      paddingBottom: 0,

      '&:hover': {
        backgroundColor: 'inherit',
      },
      '& > .MuiButton-label': {},
    },

    '&.button-variant-contained': {
      paddingLeft: '12px',
      paddingRight: '12px',
      color: PALETTE?.common?.white,
      '&:hover': {
        color: PALETTE?.text?.secondary,
      },
    },

    '&.link-button': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '&:hover': {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'inherit',
        },
      },
    },

    paddingLeft: '6px',
    paddingRight: '6px',
    marginRight: SPACING_BASE,
    '&:last-child': {
      marginRight: 0,
    },
    minWidth: '80px',

    '& .MuiButton-endIcon': {
      marginLeft: '1.5px',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '-3px',
      transition: '0.5s transform ease',
    },

    '&.selected': {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      },
    },
  },

  menuButtonLabel: {
    width: 'auto',
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '.button-variant-text &': {
      borderBottom: '3.5px transparent solid',
      '&:hover': {
        borderBottom: `3.5px ${PALETTE?.primary?.main} solid`,
      },
    },
  },
}

export const getColorShadeLabel = (index: number): string => {
  if (index < 10) {
    return PaletteModeCode.light
  }
  if (index > 10) {
    return PaletteModeCode.dark
  }
  return 'main'
}

export const baseThemeConfig: ThemeOptions = {
  breakpoints: BREAKPOINTS,

  spacing: SPACING_BASE,

  palette: PALETTE,

  typography: {
    fontFamily: 'Manrope, Roboto, sans-serif',
    htmlFontSize: 10,
    h2: {
      fontWeight: 500,
    },
    h2Bold800: {
      fontSize: '6rem',
      fontWeight: 800,
      lineHeight: 1.2,
      fontFamily: 'Manrope, sans-serif',
    },
    h4: {
      fontSize: '3.4rem',
      fontWeight: 600,
      lineHeight: 1.23,
    },
    h5Bold700: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 1.334,
      fontFamily: 'Manrope, sans-serif',
    },
    h6: {
      fontWeight: 700,
    },
    h6Bold800: {
      fontSize: '2rem',
      fontWeight: 800,
      lineHeight: 1.334,
      fontFamily: 'Manrope, sans-serif',
    },
    h22: {
      fontSize: '2.2rem',
      fontWeight: 400,
      lineHeight: 1.334,
      fontFamily: 'Manrope, sans-serif',
    },
    h22Bold800: {
      fontSize: '2.2rem',
      fontWeight: 800,
      lineHeight: 1.18,
      fontFamily: 'Manrope, sans-serif',
    },
    h30: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 0.86,
      fontFamily: 'Manrope, sans-serif',
    },
    h30Bold800: {
      fontSize: '3rem',
      fontWeight: 800,
      lineHeight: 0.86,
      fontFamily: 'Manrope, sans-serif',
    },
    subtitle1: {
      lineHeight: 1.25,
    },
    subtitle1Bold: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.25,
      fontFamily: 'Manrope, sans-serif',
    },
    subtitle1Label: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.25,
      fontFamily: 'Manrope, sans-serif',
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
    },
    body1Bold: {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: '1.5',
      fontFamily: 'Roboto, sans-serif',
    },
    body1Bold500: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '1.5',
      fontFamily: 'Roboto, sans-serif',
    },
    body1Bold700: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '0.75',
      fontFamily: 'Roboto, sans-serif',
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
    },
    body2Bold: {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '1.57',
      fontFamily: 'Roboto, sans-serif',
    },
    body2Bolder: {
      fontSize: '1.4rem',
      fontWeight: 700,
      lineHeight: '1.57',
      fontFamily: 'Roboto, sans-serif',
    },
    body3: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.57',
      fontFamily: 'Roboto, sans-serif',
    },
  } as TypographyOptions,

  shape: SHAPE,
  mixins: MIXINS,

  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        '*': {
          scrollbarColor: 'rgba(196, 196, 196, 1), rgba(229, 229, 229, 1)',
          scrollbarWidth: 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '0.5rem',
          height: '0.5rem',
        },
        '*::-webkit-scrollbar-track': {
          background: 'rgba(229, 229, 229, 1)',
          borderRadius: '100vw',
        },
        '*::-webkit-scrollbar-thumb': {
          background: 'rgba(196, 196, 196, 1)',
          borderRadius: '100vh',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: 'rgba(147, 147, 147, 1)',
        },

        ':root': {
          fontSize: '62.5%',
          ...new Values(theme.palette.secondary.main).all(10).reduce(
            (obj, item, index) =>
              Object.assign(obj, {
                [`--brand-${getColorShadeLabel(index)}-${
                  item.weight
                }`]: `rgb(${item.rgb.join(',')})`,
              }),
            {}
          ),
        },

        body: {
          fontSize: '1.6rem',
        },

        'h1 ~ p,h2 ~ p,h3 ~ p,h4 ~ p,h5 ~ p,h6 ~ p': {
          marginTop: 0,
        },
        'ul,ol': {
          margin: 0,
          padding: 0,
        },
        '.MuiCalendarPicker-root': {
          backgroundColor: theme.palette.common.white,
        },
        '.remove-focus.Mui-focused': {
          color: `${theme.palette.common.purple} !important`,
        },
        '.MuiFormLabel-asterisk': {
          whiteSpace: 'nowrap',
        },
        '.MuiDataGrid-menu, .MuiDataGrid-panel': {
          '.MuiPaper-root': {
            border: '1px solid',
            backgroundColor: theme.palette.background.default,
          },
        },
        '.MuiFormControlLabel-asterisk': {
          display: 'none',
        },
      }),
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            boxShadow: 'none',
            fontWeight: 800,
            textTransform: 'lowercase',
            whiteSpace: 'nowrap', // The design has decided that we should not let text inside the button to wrap
            '&.MuiButton-contained .MuiCircularProgress-root.MuiCircularProgress-colorPrimary':
              {
                color: '#8f8f8f',
              },
            '&.icon-button': {
              minWidth: 0,
              borderRadius: '50%',
              '&.MuiButton-sizeLarge': {
                padding: 12,
              },
              '&.MuiButton-sizeMedium': {
                padding: 8,
              },
              '&.MuiButton-sizeSmall': {
                padding: 5,
              },
              '& .MuiSvgIcon-root': {
                fontSize: 'inherit',
              },
            },
            '&.icon-only': {
              minWidth: 0,
              paddingLeft: 14,
              paddingRight: 14,
              '.MuiButton-startIcon': {
                margin: 0,
              },
            },
            'a&:hover':
              ownerState.color && ownerState.color !== 'inherit'
                ? {
                    color: theme.palette[ownerState.color].main,
                    '&.MuiButton-contained': {
                      color: theme.palette[ownerState.color].contrastText,
                    },
                  }
                : undefined,
          }
        },
        text: {
          textDecoration: 'underline',
          textUnderlineOffset: '4px',
          '&:hover': {
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
          },
        },
        sizeSmall: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.3rem',
          },
        },
        sizeMedium: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.4rem',
          },
        },
        sizeLarge: {
          [`@media (min-width: ${BREAKPOINTS.values.xs})`]: {
            fontSize: '1.6rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'lowercase',
          letterSpacing: '-0.79px',
          paddingTop: 6,
          paddingBottom: 6,
          fontWeight: 700,
          '.MuiButtonBase-root': {
            paddingInline: 16,
          },
          '.MuiToggleButton-primary': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
          },
          '&.Mui-selected,&.Mui-selected:hover &:focus': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.common.white,
          },
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}0a`,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiSvgIcon-root': {
            color: theme.palette.common.purple,
          },
          '.MuiDivider-root': {
            borderColor: theme.palette.common.purple,
          },
        }),
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.common.offWhite,
          color: theme.palette.common.purple,
          ...((theme.palette.mode === 'light' ||
            theme.palette.logoMode === 'light') && {
            backgroundColor: theme.palette.common.white,
          }),
          '.MuiSvgIcon-root.MuiSvgIcon-colorPrimary': {
            color: theme.palette.common.purple,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected,&.Mui-selected.Mui-focusVisible, &.Mui-selected:hover':
            {
              backgroundColor: `${theme.palette.common.purple}29`,
            },
          '&.Mui-focusVisible': {
            backgroundColor: `${theme.palette.common.purple}19`,
          },
          ':hover': {
            backgroundColor: 'rgb(0, 0, 0, 0.04)',
          },
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: () => ({
          boxShadow: 'none',
        }),
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowX: 'hidden',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 20px 20px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: 0,
          boxShadow: 'none',
          padding: '3.2rem',
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
          },
        }),
        asterisk: ({ theme }) => ({
          color: theme.palette.error.main,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontFamily: 'Manrope,Roboto,sans-serif',
          fontWeight: 700,
          '&.MuiInputLabel-root': {
            fontWeight: 400,
          },
        }),
        asterisk: ({ theme }) => ({
          color: theme.palette.error.main,
        }),
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.common.offWhite,
          color: theme.palette.common.purple,
          ...(theme.palette.mode === 'light' && {
            backgroundColor: theme.palette.common.white,
          }),
          '.MuiSvgIcon-root': {
            color: theme.palette.common.purple,
          },
        }),
        option: {
          '&.Mui-focused': {
            backgroundColor: 'rgb(0, 0, 0, 0.04) !important',
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          rowGap: '4px',
          '& .MuiOutlinedInput-root': {
            '&.Mui-disabled, &.Mui-readOnly': {
              backgroundColor: theme.palette.action.disabledBackground,
              cursor: 'default',

              '-webkit-text-fill-color': theme.palette.action.disabled,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
            '& .Mui-readOnly': {
              cursor: 'default',
            },
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiSvgIcon-root': {
            color: theme.palette.action.disabled,
          },
          '& .MuiOutlinedInput-root.Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline':
            {
              border: '1px solid',
              borderColor: theme.palette.error.main,
            },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...(theme.palette.mode === 'light' && {
            '.Mui-disabled:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${theme.palette.action.disabledBackground} inset`,
            },
          }),
        }),
        input: ({ theme }) => ({
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${
              theme.palette.mode === 'light'
                ? theme.palette.common.white
                : theme.palette.action.disabledBackground
            } inset`,
            WebkitTextFillColor: theme.palette.primary.main,
          },
        }),

        notchedOutline: ({ theme }) => ({
          ...(theme.palette.mode !== PaletteModeCode.light.toString() && {
            borderColor: theme.palette.primary.main,
          }),
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            '&:has(.MuiAlert-action)': {
              flexWrap: 'wrap',
              '.MuiAlert-icon': {
                position: 'absolute',
                margin: 0,
              },
              '.MuiAlert-action': {
                alignItems: 'center',
                padding: 0,
              },
              '.MuiAlert-message': {
                marginLeft: theme.spacing(4),
              },
            },
          },
          boxShadow: 'none',
          borderRadius: 10,
          fontSize: '1.6rem',
          paddingBlock: theme.spacing(1.2),
        }),
        outlinedError: ({ theme }) => ({
          background: theme.palette.background.default,
        }),
        filledSuccess: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'info' && {
              backgroundColor: theme.palette.info.light,
              color: theme.palette.info.main,
              boxShadow: `inset 0 0 0 0.5px`,
            }),
          textTransform: 'lowercase',
        }),
        label: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '14px',
            fontWeight: '700',
            padding: '10px 15px',
          }),
        }),
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            height: 4,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'lowercase',
          fontWeight: 700,
          lineHeight: 1.71,
        },
      },
    },
  },
}
