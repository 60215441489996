import { AuthProfileType, LoginTypes } from '@procom-labs/common'

export enum SignUpRoutes {
  SignUp = '/signup',
  SignUpEmail = '/signup-email',
}

export enum SocialLoginContext {
  Signup = 'signup',
  Login = 'login',
}

export enum LogoSizeOptions {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const loginToProfileTypeMap = new Map<LoginTypes, AuthProfileType[]>([
  [LoginTypes.Employer, [AuthProfileType.Recruiter]],
  [LoginTypes.Contractor, [AuthProfileType.Contractor]],
  [LoginTypes.Customer, [AuthProfileType.ClientService]],
  [
    LoginTypes.Client,
    [
      AuthProfileType.HiringManager,
      AuthProfileType.AccountManager,
      AuthProfileType.ResourceManager,
    ],
  ],
])
