import React, { ReactElement, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Observable } from 'rxjs'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Button, FormGroup, IconButton, InputAdornment } from '@mui/material'
import CircularProgressIcon from '@mui/material/CircularProgress'
import { GorillaAlert } from '@procom-labs/atoms'
import { errorToString } from '@procom-labs/common'

import { useTrackingWrapper } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'

import { RenderCheckbox, validatePassword } from './password-validation'

interface Credentials {
  password: string
  confirmPassword: string
}

interface ValidationType {
  transKey?: TFuncKey
  valid: boolean
}

const passwordValidations: { [key: string]: TFuncKey } = {
  Min6Characters: 'form.passwordValidation.min6Characters',
  Min1number: 'form.passwordValidation.min1Number',
  Min1CapitalLetter: 'form.passwordValidation.min1CapitalLetter',
  Min1LowerCaseLetter: 'form.passwordValidation.min1LowerCaseLetter',
  PasswordMatch: 'form.passwordValidation.passwordMatch',
}

const initialCredentials: Credentials = {
  password: '',
  confirmPassword: '',
}

export const ChangePassword: React.FC<{
  // eslint-disable-next-line no-unused-vars
  submitRequest: (values: any) => Observable<any>
  submitLabel: string
  email: string
  gtmEvent: string
}> = ({ submitRequest, submitLabel, email, gtmEvent }) => {
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)
  const [credentials] = useState<Credentials>(initialCredentials)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { t } = useTranslation('main')
  const { track } = useTrackingWrapper()
  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const error = !!errorString

  const [passwordValidation, setPasswordValidation] = useState<
    ValidationType[]
  >([
    {
      transKey: passwordValidations.Min6Characters,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1number,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1CapitalLetter,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1LowerCaseLetter,
      valid: false,
    },
    {
      transKey: passwordValidations.PasswordMatch,
      valid: false,
    },
  ])

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  // Need to redo this to optimize the code
  const passwordValidate = (values: Credentials): void => {
    validatePassword(
      values || initialCredentials,
      passwordValidation,
      setPasswordValidation
    )
  }

  const validate = (values: Credentials): Partial<Credentials> => {
    const errors: Partial<Credentials> = {}

    if (!values.password) {
      errors.password = t('form.changePassword.requiredError')
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t('form.changePassword.requiredError')
    }
    passwordValidate(values)
    return errors
  }

  const handleSubmit = (values: any): void => {
    setSubmitIsLoading(true)
    submitRequest(values)
      .subscribe({
        next: ({ clientPortalRedirectionEnabled }) => {
          if (clientPortalRedirectionEnabled) {
            authService.redirectToClientPortal()
          }
        },
        error: (err) => {
          setErrorString(errorToString(err))
          track({
            eventName: gtmEvent,
            submissionStatus: 'false',
            email,
            errorMessage: errorToString(err),
          })
        },
      })
      .add(() => setSubmitIsLoading(false))
  }

  return (
    <>
      {error && (
        <GorillaAlert severity="error" sx={{ mb: '24px' }}>
          {errorString}
        </GorillaAlert>
      )}

      <Formik
        initialValues={credentials}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ submitForm, touched, isValid }) => {
          const disableSubmit: boolean =
            submitIsLoading ||
            !isValid ||
            passwordValidation.some((e) => !e.valid) ||
            !Object.keys(touched).length
          return (
            <Form
              className="form-root"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !disableSubmit) {
                  submitForm()
                }
              }}
            >
              <Field
                component={TextField}
                name="password"
                InputLabelProps={{ shrink: true }}
                type={showPassword ? 'text' : 'password'}
                label={t('form.changePassword.label')}
                fullWidth
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('common.aria.passwordVisibility')}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup sx={{ mb: '24px', width: '100%' }}>
                {passwordValidation.map((validation) =>
                  validation.transKey !== passwordValidations.PasswordMatch ? (
                    <RenderCheckbox
                      key={validation.transKey}
                      validation={validation}
                    />
                  ) : (
                    ''
                  )
                )}
              </FormGroup>
              <Field
                component={TextField}
                name="confirmPassword"
                InputLabelProps={{ shrink: true }}
                type={showPassword ? 'text' : 'password'}
                label={t('form.changePassword.label2')}
                fullWidth
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('common.aria.passwordVisibility')}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup sx={{ mb: '24px', width: '100%' }}>
                {passwordValidation.map((validation) =>
                  validation.transKey === passwordValidations.PasswordMatch ? (
                    <RenderCheckbox
                      key={validation.transKey}
                      validation={validation}
                    />
                  ) : (
                    ''
                  )
                )}
              </FormGroup>
              <Button
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                endIcon={
                  submitIsLoading ? (
                    <CircularProgressIcon size={24} />
                  ) : (
                    <ArrowForwardIcon />
                  )
                }
                disabled={disableSubmit}
                sx={{ mt: 2, mb: 3 }}
              >
                {submitLabel}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </>
  ) as ReactElement
}
