import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { catchError, from, map, Subscription, switchMap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  AlertTitle,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import { GorillaAlert } from '@procom-labs/atoms'
import {
  ClientQueryParams,
  clientService,
  CommonMessages,
  errorToString,
  FeatureFlagNames,
  getVendorCode,
  gtmEvents,
  gtmEventsPrefix,
  IFeatureFlag,
  LocalStorageAttributes,
  LoginTypes,
  PageTypes,
  RecaptchaActions,
  removeProtectedLocalStorageAttribute,
  SubmissionTrackingStatus,
  useFeatureFlags,
  useGtmVpv,
  useIsClientHCMWorks,
  useIsVendorSoftChoice,
  useIsVendorSymbiotic,
  useIsVendorDemoSandbox,
  VendorCodes,
} from '@procom-labs/common'

import { SocialMediaLogin } from '@auth-portal/components/social-media-login'
import { useMsSsoLogin } from '@auth-portal/hooks/use-ms-sso-login'
import { OpenLinksEnum } from '@auth-portal/routes/routes.enum'
import { authService } from '@auth-portal/services'
import { utilsService } from '@auth-portal/services/util.service'
import { mfaStore } from '@auth-portal/store'
import { loginToProfileTypeMap, SocialLoginContext } from '@auth-portal/types'

import { useRecaptcha, useTrackingWrapper } from '../hooks'
import { Header } from './header'
import { MicrosoftLoginButton } from './ms-ss-login-button'
import { TermsAndConditions } from './terms-and-conditions'

interface Credentials {
  email: string
  password: string
}

const initialValues: Credentials = {
  email: '',
  password: '',
}

const initialStorageKey = 'portal.rememberme'

export const LoginForm: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main')
  const [searchParams] = useSearchParams()
  const { getRecaptchaToken } = useRecaptcha()
  const { featureFlags } = useFeatureFlags()
  const { handleMsSsoLogin } = useMsSsoLogin()

  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const [newPassword, setNewPassword] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<Credentials>(initialValues)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)
  const [userHasLoggedOut, setUserHasLoggedOut] = useState<boolean>(false)
  const [userSessionExpired, setUserSessionExpired] = useState<boolean>(false)
  const [useNotAllowed, setUserNotAllowed] = useState<boolean>(false)
  const userEmailParam = searchParams.get('email')
  const [userHasAccount, setUserHasAccount] = useState<boolean>(false)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const [isMsSsoEnabled, setIsMsSsoEnabled] = useState<boolean>(false)
  const [isMSMultiTenantClient, setIsMSMultiTenantClient] =
    useState<boolean>(false)

  const { track } = useTrackingWrapper()

  const loginType: LoginTypes | null = useObservable(
    () => authService.loginType$
  )

  const isMfaCodeRequested = useObservable(
    () => authService.isMfaCodeRequested$
  )
  const subscriptionRef = useRef<Subscription | null>()

  const isClientHCMWorks = useIsClientHCMWorks()
  const isVendorSymbiotic = useIsVendorSymbiotic()
  const isVendorSoftChoice = useIsVendorSoftChoice()
  const isVendorDemoSandbox = useIsVendorDemoSandbox()
  const isVendorAllowedForContractorPortal = [
    VendorCodes.PCGL,
    VendorCodes.DEMO,
    VendorCodes.DEMOSANDBOX,
  ].includes(getVendorCode())

  let loginTypeParam = searchParams.get(ClientQueryParams.loginType)
  if (loginTypeParam === LoginTypes.Employer && isClientHCMWorks) {
    loginTypeParam = LoginTypes.Contractor
  }

  // TODO: this should not be necessary if all pages comes through intial-route first
  useEffect(() => {
    if (loginTypeParam && loginTypeParam.toLowerCase() !== loginType) {
      localStorage.setItem(ClientQueryParams.loginType, loginTypeParam)
      authService.setLoginType()
    }
  }, [loginTypeParam, loginType])

  const rememberMeStorageKey = useObservable(
    () =>
      clientService.clientName$.pipe(
        map((name) => `${name ? name.toLowerCase() : 'portal'}.rememberme`)
      ),
    initialStorageKey
  )

  const error = !!errorString

  const clearAlertsIfNecessary = (): void => {
    if (!searchParams.get('new-password') && (newPassword || error)) {
      setErrorString(undefined)
      setNewPassword(false)
    }
  }

  const handleErrorMessages = useCallback(
    (errorMessage: string | undefined) => {
      setErrorString(errorMessage)
    },
    []
  )

  const isContractorLogin = useMemo(
    () => loginType?.toLowerCase() === LoginTypes.Contractor.toLowerCase(),
    [loginType]
  )

  const isCustomerLogin = useMemo(
    () => loginType?.toLowerCase() === LoginTypes.Customer.toLowerCase(),
    [loginType]
  )

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('form.email.requiredError'))
      .email(t('form.email.invalidError')),
    password: Yup.string().required(t('form.login.password.requiredError')),
  })

  useEffect(() => {
    if (userEmailParam) {
      const email = decodeURIComponent(userEmailParam)
      setCredentials({
        email,
        password: '',
      })
    }
  }, [searchParams, userEmailParam])

  useEffect(() => {
    setUserHasLoggedOut(false)
  }, [loginTypeParam])

  const recallStoredRememberMe = useCallback(() => {
    const rememberedEmail = localStorage.getItem(rememberMeStorageKey)
    if (rememberedEmail && !userHasAccount) {
      setCredentials((prevState) => {
        if (prevState.email !== rememberedEmail) {
          return {
            email: rememberedEmail,
            password: '',
          }
        }
        return prevState
      })
      setRememberMe(!!rememberedEmail)
    }
  }, [rememberMeStorageKey, userHasAccount])

  const determineIfNewPassword = useCallback(() => {
    if (searchParams.get('new-password')) {
      const emailParam = searchParams.get('email')
      const email = emailParam ? decodeURIComponent(emailParam) : ''
      setCredentials({
        password: credentials.password,
        email,
      })
      setNewPassword(true)
    }
  }, [credentials.password, searchParams])

  const handleStrayClicks = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const handleSubmit = (values: any, actions: any): void => {
    const { email, password } = values

    if (rememberMe) {
      localStorage.setItem(rememberMeStorageKey, email)
    } else {
      localStorage.removeItem(rememberMeStorageKey)
    }

    setSubmitIsLoading(true)

    subscriptionRef.current = authService
      .authenticate({ username: email, password })
      .pipe(
        catchError((err) => {
          return from(
            getRecaptchaToken(RecaptchaActions.GetAnonymousUserInfo)
          ).pipe(
            switchMap((token) => {
              return authService.getPasswordInfo(email, token).pipe(
                map((data) => {
                  if (data.hasContractorProfile && data.hasPasswordExpired) {
                    track({
                      eventName: gtmEvents.FormLogin,
                      submissionStatus: 'false',
                      email,
                      errorMessage: 'Expired password',
                    })
                    navigate('/reset-expired-password', {
                      state: {
                        email,
                        code: data?.code,
                      },
                      replace: true,
                    })
                  } else {
                    throw new Error(err.message)
                  }
                })
              )
            })
          )
        })
      )
      .subscribe({
        next: (response) => {
          const mfaType = response?.mfaType
          if (mfaType) mfaStore.dispatch({ mfaType })
        },
        complete: () => {
          track({
            eventName: gtmEvents.FormLogin,
            submissionStatus: 'true',
            email,
          })
        },
        error: (err) => {
          setErrorString(errorToString(err))
          actions.resetForm({ values })
          track({
            eventName: `${gtmEventsPrefix.Notification}${gtmEvents.FormLogin}`,
            submissionStatus: 'false',
            email,
            errorMessage: errorToString(err),
          })
        },
      })

    subscriptionRef.current.add(() => setSubmitIsLoading(false))
  }

  useEffect(() => {
    if (rememberMeStorageKey !== initialStorageKey) {
      recallStoredRememberMe()
    }
    if (searchParams.get('accountExists')) setUserHasAccount(true)
    if (searchParams.get('emailVerified')) setEmailVerified(true)
    if (searchParams.get('logout')) setUserHasLoggedOut(true)
    else if (searchParams.get('sessionExpired')) {
      removeProtectedLocalStorageAttribute(
        LocalStorageAttributes.IsResumeCopilotConsentGiven
      )
      setUserSessionExpired(true)
    } else if (searchParams.get('notAllowed')) setUserNotAllowed(true)

    determineIfNewPassword()
  }, [
    determineIfNewPassword,
    recallStoredRememberMe,
    rememberMeStorageKey,
    searchParams,
  ])

  useEffect(() => {
    const errorParam = searchParams.get('error') || ''

    if (
      [
        CommonMessages.SomethingWrong.toString(),
        CommonMessages.LoginFailed.toString(),
      ].includes(errorParam)
    )
      setErrorString(t('common.alert.somethingWrong'))
  }, [searchParams, t])

  useEffect(() => {
    const subscription = authService
      .getSiteConfigurationByOriginBase()
      .subscribe({
        next: ({ data }) => {
          setIsMsSsoEnabled(data.isMicrosoftSsoEnabled)
        },
      })
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [])

  useGtmVpv({
    pageType: PageTypes.Login,
    pageTitle: PageTypes.Login,
  })

  useEffect(() => {
    if (isMfaCodeRequested) navigate('/login-mfa')
  }, [isMfaCodeRequested, navigate])

  useEffect(() => {
    let subscription = new Subscription()
    if (featureFlags?.length === 0) {
      subscription = utilsService.getVendorFeatureFlags().subscribe({
        next: (flags: IFeatureFlag[]) => {
          const MSMultiTenantFlag = flags.find(
            (flag) => flag.featureName === FeatureFlagNames.MS_SSO_Multitenant
          )
          const {
            enabled: isMSMultiTenant = false,
            eligibleProfileTypes = [],
          } = MSMultiTenantFlag || {}
          const userLoginType =
            loginTypeParam || localStorage.getItem('loginType')

          if (userLoginType) {
            const modifiedUserLoginType = (userLoginType
              .charAt(0)
              .toUpperCase() +
              userLoginType.slice(1).toLowerCase()) as LoginTypes

            const profileTypes = loginToProfileTypeMap.get(
              modifiedUserLoginType
            )
            const shouldShowMsSsoButton =
              isMSMultiTenant &&
              eligibleProfileTypes.some((profileType) =>
                profileTypes?.includes(profileType)
              )
            setIsMSMultiTenantClient(shouldShowMsSsoButton)
          } else {
            setIsMSMultiTenantClient(false)
          }
        },
      })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [isContractorLogin, isCustomerLogin, loginTypeParam, featureFlags?.length])

  const onMsSsoLogin = useCallback(async () => {
    try {
      await handleMsSsoLogin(handleErrorMessages)
    } catch (err) {
      track({
        eventName: gtmEvents.FormMsSSOLogin,
        submissionStatus: SubmissionTrackingStatus.Failed,
        loginType: 'MS_SSO_Login',
        isGTMTracking: true,
        isMixPanelTracking: true,
        isTrackingEnabled: true,
        errorMessage: errorToString(err),
      })
    }
  }, [handleMsSsoLogin, handleErrorMessages, track])

  return (
    <div
      className="form-outer reset-password-form"
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      <Header heading={t('form.login.headingWithSocial')} />
      {isMSMultiTenantClient && <MicrosoftLoginButton onClick={onMsSsoLogin} />}

      {isContractorLogin && !isClientHCMWorks && !isVendorSoftChoice && (
        <>
          <SocialMediaLogin context={SocialLoginContext.Login} />

          <Typography
            component="p"
            variant="body1"
            mb={4.5}
            sx={{ textDecoration: 'capital' }}
          >
            {t('form.contractorSignup.or')}
          </Typography>
        </>
      )}

      {isCustomerLogin && isMsSsoEnabled && (
        <SocialMediaLogin
          context={SocialLoginContext.Login}
          loginType={LoginTypes.Customer}
        />
      )}

      {isCustomerLogin && isVendorSymbiotic && (
        <Typography
          component="p"
          variant="subtitle1"
          mb={5.37}
          sx={{ textAlign: 'center' }}
        >
          {t('form.contractorSignup.or')}
        </Typography>
      )}

      {emailVerified && (
        <GorillaAlert
          severity="success"
          sx={{
            mt: 3,
            mb: 3,
          }}
        >
          {t('form.login.alerts.verified')}
        </GorillaAlert>
      )}
      {userHasLoggedOut && (
        <GorillaAlert
          severity="success"
          action={
            <IconButton
              aria-label={t('common.aria.close')}
              color="inherit"
              size="small"
              onClick={() => {
                setUserHasLoggedOut(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{
            mt: 3,
            mb: 3,
          }}
        >
          {t('form.login.alerts.logout')}
        </GorillaAlert>
      )}
      {useNotAllowed && (
        <GorillaAlert
          severity="error"
          action={
            <IconButton
              aria-label={t('common.aria.close')}
              color="inherit"
              size="small"
              onClick={() => {
                setUserNotAllowed(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{
            mt: 3,
            mb: 3,
          }}
        >
          {t('form.login.alerts.notAllowed')}
        </GorillaAlert>
      )}
      {userSessionExpired && (
        <GorillaAlert
          severity="error"
          action={
            <IconButton
              aria-label={t('common.aria.close')}
              color="inherit"
              size="small"
              onClick={() => {
                setUserSessionExpired(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          sx={{
            mt: 3,
            mb: 3,
          }}
        >
          {t('form.login.alerts.sessionExpired')}
        </GorillaAlert>
      )}
      {newPassword && (
        <GorillaAlert severity="success" sx={{ mt: 3, mb: 3 }}>
          {t('form.login.alerts.resetPassword')}
        </GorillaAlert>
      )}

      {newPassword && (
        <GorillaAlert severity="info" sx={{ mb: '24px' }}>
          {t('form.login.alerts.newPassword')}
        </GorillaAlert>
      )}
      {error && (
        <GorillaAlert severity="error" sx={{ mb: '24px' }}>
          {errorString}
        </GorillaAlert>
      )}
      {userHasAccount && (
        <GorillaAlert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>{t('form.login.alerts.accountExistsTitle')}</AlertTitle>
          {t('form.login.alerts.accountExists')}
        </GorillaAlert>
      )}

      {isCustomerLogin && !isClientHCMWorks && !isVendorSymbiotic && !isVendorDemoSandbox ? (
        <TermsAndConditions />
      ) : null}
      {!isCustomerLogin ||
      (isCustomerLogin && (isClientHCMWorks || isVendorDemoSandbox)) ||
      isVendorSymbiotic ? (
        <Formik
          initialValues={credentials}
          validationSchema={validationSchema}
          validate={clearAlertsIfNecessary}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values }) => {
            return (
              <Form className="form-root" noValidate>
                <Box
                  sx={{
                    px: {
                      xs: 1,
                      sm: 4,
                      lg: 5,
                      xl: 7,
                      k2: 12.5,
                    },
                  }}
                >
                  {!isCustomerLogin && !isContractorLogin && (
                    <Box mb={4}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          navigate(OpenLinksEnum.LoginDigitCode)
                        }}
                        sx={{
                          marginBottom: 4,
                        }}
                      >
                        {t('form.createAccount.oneTimeCode')}
                      </Button>
                      <Typography
                        component="p"
                        variant="subtitle1"
                        sx={{ mb: 2 }}
                      >
                        {t('form.contractorSignup.or')}
                      </Typography>
                    </Box>
                  )}

                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    InputLabelProps={{ shrink: true }}
                    value={values?.email || ''}
                    label={t('form.email.label')}
                    fullWidth
                    autoComplete="email"
                  />

                  <Field
                    component={TextField}
                    value={values?.password || ''}
                    name="password"
                    InputLabelProps={{ shrink: true }}
                    type={showPassword ? 'text' : 'password'}
                    label={t('form.login.password.label')}
                    fullWidth
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={t('common.aria.passwordVisibility')}
                            onClick={() => {
                              setShowPassword(!showPassword)
                            }}
                            onMouseDown={handleStrayClicks}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    sx={{ width: '100%' }}
                    spacing={2}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={rememberMe}
                          onClick={() => setRememberMe(!rememberMe)}
                          color="primary"
                        />
                      }
                      componentsProps={{ typography: { lineHeight: 1.5 } }}
                      label={t('form.login.rememberEmail')}
                    />
                    <Link
                      component={RouterLink}
                      underline="none"
                      to={`/reset-password${
                        values.email !== '' ? `?email=${values.email}` : ''
                      }`}
                      id="btn-forgot-password"
                    >
                      <Typography
                        component="span"
                        variant="body1Bold700"
                        sx={{ lineHeight: 1.5 }}
                      >
                        {t('form.login.forgotPassword')}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
                <TermsAndConditions />
                <LoadingButton
                  variant="contained"
                  size="large"
                  type="submit"
                  color="secondary"
                  sx={{ minWidth: '81px', mb: 3, mt: 4.5 }}
                  loading={submitIsLoading}
                  id="cta-login"
                >
                  {t('form.login.heading')}
                </LoadingButton>

                {loginType?.toLowerCase() ===
                  LoginTypes.Contractor.toLowerCase() &&
                !isClientHCMWorks &&
                !isVendorSoftChoice &&
                isVendorAllowedForContractorPortal ? (
                  <>
                    <Typography variant="body2">
                      <>
                        {t('footer.noLogin')}
                        <Link
                          component={RouterLink}
                          to="/signup"
                          sx={{
                            fontWeight: 700,
                            textDecoration: 'none',
                          }}
                        >
                          {t('footer.noLoginLink')}
                        </Link>
                      </>
                    </Typography>
                  </>
                ) : null}
              </Form>
            )
          }}
        </Formik>
      ) : null}
    </div>
  )
}
