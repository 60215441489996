import { IClientSettings } from '@common/models/clients-settings'

import { ImageExtensions } from '../../../enums'
import { TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE } from '../../../util/helpers'

export const HcmWorksSettings: IClientSettings = {
  common: {
    name: 'hcmWorks',
    effectiveDate: TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE,
    logo: {
      imgExtension: ImageExtensions.PNG,
    },
  },
}
