export const openPopupWindow = (href: string, title: string): void => {
  // Opens a new window to login with a social network account
  const width = 500
  const height = 500
  // Allow for borders.
  const leftPosition = window.screen.width / 2 - (width / 2 + 10)
  // Allow for title and status bars.
  const topPosition = window.screen.height / 2 - (height / 2 + 50)
  // Open the window.
  window.open(
    href,
    title,
    // eslint-disable-next-line max-len
    `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`
  )
}

export const closePopupWindow = (
  href: string,
  reload: boolean = false
): void => {
  window.opener.location.reload(reload)
  window.opener.location.href = href
  window.close()
}
