import React, { ReactElement } from 'react'

import { BaseLayout, ChangePasswordForm } from '@auth-portal/components'
import { useGtmVpv, PageTypes } from '@procom-labs/common'
import { useTranslation } from 'react-i18next'

const ChangePasswordRoute: React.FC<{}> = () => {
  const { t } = useTranslation('main')
  useGtmVpv({
    pageType: PageTypes.ChangePassword,
    pageTitle: t('form.changePassword.title', { lng: 'en' }),
  })

  return (
    <BaseLayout>
      <ChangePasswordForm />
    </BaseLayout>
  ) as ReactElement
}
export default ChangePasswordRoute
