import React, { useEffect } from 'react'

import { Box, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GorillaGrid } from '@procom-labs/atoms'
import { AppTypes, clientService, PaletteModeCode } from '@procom-labs/common'
import BrandLogoDarkSvg from '@procom-labs/common/src/assets/brand/logo-black-text.svg'
import BrandLogoSvg from '@procom-labs/common/src/assets/brand/logo.svg'

import authPortalBackgroundSvg from '@auth-portal/assets/portal/background.svg'
import { authService } from '@auth-portal/services'

import { Banner } from './banner'
import { Footer } from './footer'

const innerGridStyles = {
  borderRadius: '20px',
  alignItems: 'center',
  minHeight: '810px',
  paddingX: { xs: 4, xl: 6, k2: 10 },
  pb: '50px',
  my: 3,
  textAlign: 'center',
}

export const BaseLayout: React.FC<{}> = React.memo(({ children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const brandLogoSrc =
    theme.palette.logoMode === PaletteModeCode.light
      ? BrandLogoDarkSvg
      : BrandLogoSvg

  useEffect(() => {
    clientService.getSettings(AppTypes.auth)
    authService.setLoginType()
  }, [])
  return (
    <Grid
      container
      component="main"
      sx={{
        overflow: 'auto',
        height: '100vh',
        ...(theme.palette.logoMode === PaletteModeCode.light
          ? { backgroundColor: '#fff' }
          : {
              background: `url(${authPortalBackgroundSvg})`,
            }),
        backgroundSize: 'cover',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        pl: { md: 2, lg: 12.5 },
        pr: { md: 2, lg: 0 },
      }}
    >
      {isDesktop ? (
        <Grid item sm={12} lg={7.35}>
          <Banner />
        </Grid>
      ) : null}

      <GorillaGrid
        container
        item
        isGlassMode
        isThemeBackgroundApplied
        xs={12}
        lg={4.65}
        sx={innerGridStyles}
      >
        <Grid item xs={12}>
          {children}
          <Box mt={5}>
            <Footer />
          </Box>
        </Grid>
      </GorillaGrid>
      {isDesktop ? (
        <Box
          sx={{
            bottom: 50,
            position: 'absolute',
            right: 100,
          }}
        >
          <img src={brandLogoSrc} alt="GorillaWorks" />
        </Box>
      ) : null}
    </Grid>
  )
})
