import { VendorCodes } from '../enums'
import { getClientPortalHost } from './get-client-portal-host'
import { getClientVendorCode } from './get-client-vendor-code'

export const getVendorCode = (): VendorCodes => {
  const vendorCodeFromStorage = sessionStorage.getItem('VendorCode')
  if (vendorCodeFromStorage) {
    return vendorCodeFromStorage as VendorCodes
  } else {
    const clientPortalHost = getClientPortalHost()
    const vendorCode = getClientVendorCode(
      clientPortalHost || window.location.hostname
    )
    sessionStorage.setItem('VendorCode', vendorCode)
    return vendorCode
  }
}
