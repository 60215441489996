import { toKebabCase } from './case'

export interface Lens<S, A> {
  $key: (kebabCase?: boolean) => string
  $get: (s: S) => A
  $set: (a: A, s: S) => S
}

export function compose<S, A, X>(
  parent: Lens<S, A>,
  child: Lens<A, X>
): Lens<S, X> {
  return {
    $key: (kebabCase) => {
      const parentKey = parent.$key(kebabCase)
      const seprator = kebabCase ? '' : '.'
      const value = parentKey
        ? `${parent.$key(kebabCase)}${seprator}${child.$key(kebabCase)}`
        : child.$key(kebabCase)

      return kebabCase ? toKebabCase(value) : value
    },
    $get: (s) => child.$get(parent.$get(s)),
    $set: (y, s) => parent.$set(child.$set(y, parent.$get(s)), s),
  }
}

export function lensProp<S, K extends keyof S>(name: K): Lens<S, S[K]> {
  return {
    $key: () => name.toString(),
    $get: (s) => {
      return s[name]
    },
    $set: (a, s) => ({ ...s, [name]: a }),
  }
}

export function lensIndex<A>(i: number): Lens<A[], A> {
  return {
    $key: () => `[${i}]`,
    $get: (xs) => xs[i],
    $set: (x, xs) => xs.map((old, ci) => (ci === i ? x : old)),
  }
}

const idLens: Lens<any, any> = {
  $key: () => '',
  $get: (s) => s,
  $set: (s) => s,
}

export type LensProxy<P, S> = Lens<P, S> & {
  [K in keyof S]: LensProxy<P, S[K]>
}

export function lensProxy<S, P = S>(
  parent: Lens<P, S> = idLens as any
): LensProxy<P, S> {
  return new Proxy(parent as any, {
    get(target: any, key: any) {
      if (key in target) return target[key]
      const composedItem = compose<any, any, any>(
        parent as any,
        Number(key) === +key ? lensIndex(+key) : lensProp(key)
      )
      return lensProxy(composedItem)
    },
  })
}
