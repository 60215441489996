import React, { FC, useMemo } from 'react'
import { Stack, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import {
  ClientNames,
  CompanyNames,
  IClientSettings,
  clientService,
} from '@procom-labs/common'
import { useObservable } from 'rxjs-hooks'
import { useTranslation } from 'react-i18next'

export const TermsAndConditions: FC = () => {
  const { t } = useTranslation()
  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$
  )

  const displayClientConnections = useMemo(
    () =>
      clientSettings?.common.name === ClientNames.KPMG
        ? CompanyNames.ClientConnections
        : '',
    [clientSettings?.common.name]
  )

  return (
    <Stack direction="column" textAlign="center" mt={3.75}>
      <Typography variant="body1" lineHeight={1.2}>
        {t('footer.agreement', {
          companyName: displayClientConnections,
        })}
      </Typography>
      <Typography variant="body1" lineHeight={1.2}>
        <Link
          component={RouterLink}
          to="/terms-of-use"
          target="_blank"
          sx={{
            fontWeight: 700,
            textDecoration: 'none',
          }}
        >
          {t('footer.terms')}
        </Link>
        {t('footer.and')}
        <Link
          component={RouterLink}
          to="/privacy-policy"
          target="_blank"
          sx={{
            fontWeight: 700,
            textDecoration: 'none',
          }}
        >
          {t('footer.privacyPolicy')}
        </Link>
      </Typography>
    </Stack>
  )
}
