import React, { ReactElement } from 'react'

import { Box } from '@mui/material'

import { styled } from '@mui/material/styles'
import { GorillaGrid } from '@procom-labs/atoms'

const Header = styled('div')(({ theme }) => ({
  borderBottom: 'none',
  marginBottom: 16,

  [theme.breakpoints.up('sm')]: {
    marginBottom: 24,
  },
}))

export const LegalDocuments: React.FC<{
  handleBack?: () => void
}> = ({ children }) =>
  (
    <GorillaGrid
      isGlassMode
      isThemeBackgroundApplied
      sx={{
        borderRadius: '20px',
        pl: { xs: 1, sm: 2, md: 8 },
        pr: { xs: 1, sm: 2, md: 15 },
      }}
    >
      <Header>
        <Box>{children}</Box>
      </Header>
    </GorillaGrid>
  ) as ReactElement
