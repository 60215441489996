import { useCallback } from 'react'

import { IFeatureFlag } from '../models'
import { featureFlagStore, useSubjectSelector } from '../store'

export const useFeatureFlags = (): {
  featureFlags: IFeatureFlag[]
  setFeatureFlags: (flags: IFeatureFlag[]) => void
} => {
  const { featureFlags } = useSubjectSelector(featureFlagStore, [
    'featureFlags',
  ])
  const setFeatureFlags = useCallback((flags) => {
    featureFlagStore.setFeatureFlags(flags)
  }, [])

  return { featureFlags, setFeatureFlags }
}
