import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { authService } from '@auth-portal/services'
import { ClientQueryParams, useSubscriptionRef } from '@procom-labs/common'

import { rollbarInstance } from '../providers'

const InitialRoute: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const userInfosSubscriptionRef = useSubscriptionRef()
  const authPortalRoute = searchParams.get(ClientQueryParams.authPortalRoute)

  const isGetUserEnabled = useMemo(
    () =>
      !!authPortalRoute &&
      !['verifyEmail', 'activateFboInvitation'].includes(authPortalRoute),
    [authPortalRoute]
  )

  const saveClientPortalParams = useCallback(
    (params: string[]): void => {
      params.forEach((param) => {
        const paramValue = searchParams.get(param)
        const searchParam = paramValue ? decodeURIComponent(paramValue) : null
        // We delete every stored params to make sure they are no leftovers from the last login attempt
        localStorage.removeItem(param)

        if (searchParam) localStorage.setItem(param, searchParam)
      })
    },
    [searchParams]
  )

  useEffect(() => {
    searchParams.delete('lang')
  }, [])

  const paramsRoute = useMemo(
    () =>
      Object.fromEntries(
        Array.from(searchParams.entries()).filter(
          ([key]) => !Object.keys(ClientQueryParams).includes(key)
        )
      ),
    [searchParams]
  )

  const redirectToInitialRoute = useCallback(() => {
    const route = searchParams.get(ClientQueryParams.authPortalRoute)
    const redirectToParam = route ? decodeURIComponent(route) : null

    if (redirectToParam) {
      const code = searchParams.get('code') || ''
      const token = searchParams.get('token') || ''
      const email = searchParams.get('email') || ''
      const loginType = searchParams.get(ClientQueryParams.loginType) || ''

      // Every requested pages goes through this route first.
      // Then if a callbackURL is valid, the user is redirect to a route that match the redirectToParam.
      const routesMapping: any = {
        changePassword: {
          pathname: '/change-password',
          search: `?${new URLSearchParams({
            ...paramsRoute,
          })}`,
        },
        login: {
          pathname: '/',
          search: `?${new URLSearchParams({
            ...paramsRoute,
          })}`,
        },
        signup: {
          pathname: '/signup',
          search: `?${new URLSearchParams({
            ...paramsRoute,
            loginType,
          })}`,
        },
        signupEmail: {
          pathname: '/signup-email',
          search: `?${new URLSearchParams({
            ...paramsRoute,
            loginType,
          })}`,
        },
        completeRegistration: {
          pathname: '/complete-registration',
          search: `?${new URLSearchParams({
            ...paramsRoute,
          })}`,
        },
        verifyEmail: {
          pathname: '/verify-email',
          search: `?${new URLSearchParams({
            ...paramsRoute,
            loginType,
          })}`,
          state: {
            token,
          },
        },
        activateFboInvitation: {
          pathname: '/activate-fbo-invitation',
          search: `?${new URLSearchParams({
            ...paramsRoute,
          })}`,
        },
        resetExpiredPassword: {
          pathname: '/reset-expired-password',
          state: {
            code,
            email,
          },
        },
        welcome: {
          pathname: '/welcome',
          search: `?${new URLSearchParams({
            ...paramsRoute,
            loginType,
          })}`,
        },
        resetPassword: {
          pathname: '/reset-password',
          search: `?${new URLSearchParams({
            ...paramsRoute,
            email,
          })}`,
        },
      }

      if (email) {
        rollbarInstance.configure({
          payload: {
            person: { id: email, email }, // id is same as email to avoid TS error
          },
        })
      }
      const routeMapped = routesMapping[redirectToParam]
      navigate(routeMapped, {
        replace: true,
        state: routeMapped?.state,
      })
    }
  }, [navigate, paramsRoute, searchParams])

  useEffect(() => {
    saveClientPortalParams(Object.values(ClientQueryParams))
    if (isGetUserEnabled) {
      userInfosSubscriptionRef.current = authService
        .refreshUserSessionInfo()
        .subscribe({
          next: ({ idToken }) =>
            authService.redirectToClientPortal({ token: idToken }),
        })

      // When getUserInfosAndRedirect completes or throw an error (meaning no valid user), we redirect the user to the requested route
      userInfosSubscriptionRef.current.add(() => redirectToInitialRoute())
    } else {
      redirectToInitialRoute()
    }
  }, [
    isGetUserEnabled,
    redirectToInitialRoute,
    saveClientPortalParams,
    userInfosSubscriptionRef,
  ])

  return null
}

export default InitialRoute
