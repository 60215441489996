import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Observable, tap } from 'rxjs'

import Typography from '@mui/material/Typography'
import { gtmEvents } from '@procom-labs/common'

import { useTrackingWrapper } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'

import { ChangePassword } from './change-password'
import { Header } from './header'

export const ChangePasswordForm: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main')
  const [searchParams] = useSearchParams()
  const paramCode = searchParams.get('code')
  const paramEmail = searchParams.get('email')
  const { track } = useTrackingWrapper()
  const email = useMemo(
    () => (paramEmail ? decodeURIComponent(paramEmail) : ''),
    [paramEmail]
  )

  const handleSubmit = useCallback(
    (values: any): Observable<any> => {
      const confirmationCode = paramCode ? decodeURIComponent(paramCode) : ''

      return authService
        .changePassword(values.password, email, confirmationCode)
        .pipe(
          tap(() => {
            track({
              eventName: gtmEvents.FormChangePassword,
              submissionStatus: 'true',
              email,
            })
            navigate(
              `/?${new URLSearchParams({
                'new-password': 'true',
                email,
              })}`
            )
          })
        )
    },
    [email, navigate, paramCode, track]
  )

  return (
    <div className="form-outer change-password-form">
      <Header heading={t('form.changePassword.title')} />
      <Typography
        component="p"
        variant="body2"
        className="form-description"
        align="center"
        sx={{
          marginBottom: { xs: 1, sm: 2 },
          textAlign: { xs: 'left', sm: 'center' },
        }}
      >
        {t('form.changePassword.dsc')}
      </Typography>

      <ChangePassword
        submitRequest={handleSubmit}
        submitLabel={t('form.changePassword.submitLabel')}
        email={email}
        gtmEvent="notification-form-change-password"
      />
    </div>
  )
}
