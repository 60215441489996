import React from 'react'
import { Toolbar, ToolbarProps, useScrollTrigger } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const PortalToolbar: React.FC<
  ToolbarProps & {
    height?: number
  }
> = ({ children, sx, height = 64, ...restProps }) => {
  const theme = useTheme()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: height,
  })

  return (
    <Toolbar
      disableGutters
      sx={{
        [trigger ? 'px' : 'mx']: { xs: 1, sm: 2, md: 4, lg: 12 },
        background: trigger ? theme.palette.background.default : undefined,
        borderBottom: !trigger
          ? `1px solid ${theme.palette.text.primary}`
          : undefined,
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Toolbar>
  )
}
