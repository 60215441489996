import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PaletteModeCode } from '@procom-labs/common'
import clientPortalBackgroundSvg from '@procom-labs/common/src/assets/brand/client-background.svg'

export const ClientPortalBackground = styled(Box)(({ theme }) => ({
  ...(theme.palette.logoMode === PaletteModeCode.light
    ? { backgroundColor: '#fff' }
    : {
        background: `url(${clientPortalBackgroundSvg})`,
      }),

  backgroundSize: 'cover',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  zIndex: -999,
}))
