import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import { GorillaDialog } from '@procom-labs/atoms'
import { gtmEvents, useAppReloadRequired } from '@procom-labs/common'

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { ModalCloseButton } from '../modal-close-button'

export const NewAppVersionPrompt: FC = () => {
  const { t } = useTranslation('main')
  const sendDataToGTM = useGTMDispatch()
  const [open, setOpen] = useState(false)
  const { isReloadRequired } = useAppReloadRequired()
  const handleLaterBtnClick = (event?: any, reason?: string): void => {
    if (reason && reason === 'backdropClick') return
    sendDataToGTM({
      eventName: gtmEvents.PostponeReceivingNewBuildNumber,
      message: 'User selected update app later',
    })
    setOpen(false)
  }
  const handleConfirmModal = (): void => {
    setOpen(false)
    window.location.reload()
  }
  useEffect(() => {
    const isReloadModalTriggred =
      sessionStorage.getItem('isReloadModalTriggred') || false
    if (isReloadRequired && !open && !isReloadModalTriggred) {
      sessionStorage.setItem('isReloadModalTriggred', 'true')
      sendDataToGTM({
        eventName: gtmEvents.OpenReloadingDialogForNewBuildNumber,
        message: 'Modal was opened!',
      })
      setOpen(true)
    }
  }, [isReloadRequired, open, sendDataToGTM])
  return (
    <GorillaDialog
      isLightMode
      open={open}
      onClose={handleLaterBtnClick}
      disableEscapeKeyDown
      maxWidth="xs"
      sx={{ mt: { xs: 5 } }}
      PaperProps={{
        sx: {
          paddingY: { xs: 3.5, lg: 6.5 },
          paddingX: { xs: 2.5, lg: 5 },
        },
      }}
    >
      <ModalCloseButton
        id="close-reload-app-modal-btn"
        handleClose={handleLaterBtnClick}
      />
      <DialogContent sx={{ padding: 0, mb: 4, overflowY: 'revert' }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Typography
            variant="h22Bold800"
            gutterBottom
            component="p"
            sx={{
              textAlign: 'center',
            }}
          >
            {t('common.appVersionPrompt.title')}{' '}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            component="p"
            sx={{ textAlign: 'center', whiteSpace: 'pre-line' }}
          >
            {t('common.appVersionPrompt.note')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          id="update-app-later-btn"
          variant="outlined"
          onClick={handleLaterBtnClick}
        >
          {t('common.appVersionPrompt.later')}
        </Button>
        <Button
          color="secondary"
          id="update-app-now-btn"
          variant="contained"
          onClick={handleConfirmModal}
        >
          {t('common.appVersionPrompt.confirm')}
        </Button>
      </DialogActions>
    </GorillaDialog>
  )
}
