import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import mixpanel from 'mixpanel-browser'
import { useLocation } from 'react-router-dom'
import { of } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { useCommonTrackData, useDocumentTitle, useServices } from '../hooks'
import { capitalize } from '../util'

type TrackProps = {
  name: string
  properties?: any
  trackPage?: boolean
}

interface ITracking {
  track: (event: TrackProps) => void
}

interface IButtonTrackingPayload {
  text: string
  type: string
  trackingEventName?: string
}

const TrackingContext = createContext<ITracking>({} as ITracking)

const generateUniqueID = (
  userId: string,
  firstName: string,
  lastName: string
): string => {
  return `${userId} - ${firstName} ${lastName}`
}

export const TrackingProvider: React.FC<{ token: string }> = ({
  children,
  token,
}) => {
  const { authService } = useServices()
  const location = useLocation()
  const [panelLoaded, setPanelLoaded] = useState(false)
  const commonProperties = useCommonTrackData()
  const currentUser = useObservable(() =>
    authService ? authService.currentUser$ : of(null)
  )
  // Mixpanel Configuration
  useEffect(() => {
    if (currentUser) {
      try {
        mixpanel.init(token, {
          persistence: 'localStorage',
        })
        const { id, firstName, lastName, email } = currentUser
        const uniqueID = generateUniqueID(id, firstName, lastName)
        mixpanel.identify(uniqueID)
        // default properties
        mixpanel.register({
          $distinct_id: uniqueID, // This helps Mixpanel associate the event with the identified user.
          ...commonProperties,
        })

        // register user with mixpanel
        mixpanel.people.set({
          userId: id,
          $distinct_id: uniqueID,
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
        })

        setPanelLoaded(true)
      } catch (error) {
        /* empty */
      }
    }
  }, [commonProperties, currentUser, token])

  // Track Events
  const track = useCallback((event: TrackProps) => {
    const { name, properties } = event
    try {
      mixpanel.track(name, properties)
    } catch (error) {
      /* empty */
    }
  }, [])

  const { setTitle } = useDocumentTitle()
  // Track PageViews
  useEffect(() => {
    if (panelLoaded) {
      try {
        const locationPathName = location?.pathname?.split('/')[1]
        const pageTitle = capitalize(locationPathName)
          ?.trim()
          ?.replace(/-/g, ' ')
        setTitle(pageTitle)
        mixpanel.track_pageview()
      } catch (err) {
        /* empty */
      }
    }
  }, [location, panelLoaded, setTitle])

  // Click Tracking
  const handleClickEvent = useCallback((event: MouseEvent) => {
    try {
      const link = (event.target as HTMLElement).closest(`a`)
      if (link) {
        mixpanel.track('Click Tracking', {
          text: link.ariaLabel ? link.ariaLabel : link.textContent,
          type: 'Link',
        })
      }

      const button = (event.target as HTMLElement).closest(`button`)
      if (button) {
        if (button.textContent) {
          let payload: IButtonTrackingPayload = {
            text: button.textContent,
            type: 'Button',
          }
          if (button.name) {
            payload = {
              ...payload,
              trackingEventName: button.name,
            }
          }
          mixpanel.track('Click Tracking', payload)
        } else if (button.ariaLabel) {
          // Icon Button
        }
      }

      const listItem = (event.target as HTMLElement).closest(`li`)
      if (listItem) {
        mixpanel.track('Click Tracking', {
          text: listItem.textContent,
          type: 'MenuItem',
        })
      }
    } catch (error) {
      /* empty */
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickEvent)
    return () => {
      document.removeEventListener('click', handleClickEvent)
    }
  }, [handleClickEvent])

  const mixPanelTrack = useMemo(() => {
    return { track }
  }, [track])

  return (
    <TrackingContext.Provider value={mixPanelTrack}>
      {children}
    </TrackingContext.Provider>
  )
}

export const useMixPanelTracking = (): ITracking => {
  return useContext(TrackingContext)
}
