import { AuthProfileType } from '@common/enums/auth'
import { EmploymentTypes } from '@common/enums/candidate'
import { JobStatus } from '@common/enums/job'
import { IInviteUser } from '@common/models/user'

export interface ICollaborator {
  firstName: string
  lastName: string
  email: string
  profiles: AuthProfileType[]
  showSalaryOrBillRate: boolean
  isGuestUser: boolean
  id?: string
}

export interface ICollaboratorUserInfo {
  isGuestUser: boolean
  isProfileClaimed: boolean
  email: string
  isAccessRestricted: boolean
}

export interface ICollaboratorNew extends IInviteUser {
  jobId: string | null
  jobTitle: string | null
  talentPoolTitle: string | null
  destinationURL: string
  showBillRateOrSalary: boolean
  isGuestUser: boolean
}

export interface ICollaboratorSuggested {
  id: string
  firstName: string
  lastName: string
  email: string
  avatarFileGuid?: string | null
}

export interface IisRateToggleEnabled {
  isEnabled: boolean
  id?: string
}

export enum EntityTypes {
  ClientJob = 'ClientJob',
  TalentPool = 'TalentPool',
  OnboardingRequest = 'OnboardingRequest',
  OnboardingPackage = 'OnboardingPackage',
}

export interface IActiveItem {
  id: string
  title: string
  employmentType?: EmploymentTypes
  collaborators: ICollaborator[]
  hiringManagerEmail?: string
  createdByEmail?: string
  portalJobStatus?: JobStatus
  clientName?: string
  clientCode: string
  vendorCode?: string
}

export enum CollaboratorTypes {
  Collaborator = 'Collaborator',
  Guest = 'Guest',
  AccountManager = 'AccountManager',
}
