import { ClientQueryParams, getClientPortalHost } from '@procom-labs/common'

const getClientHostURL = (): string => {
  const host = getClientPortalHost()
  // This condition is only to test in local environment
  if (host && host !== 'localhost') {
    return `https://${host}`
  }
  return `http://localhost:8081`
}

export const getCallbackURL = (): string =>
  localStorage.getItem(ClientQueryParams.callbackURL) ||
  `${getClientHostURL() || ''}/auth-return`

export const getOriginBaseUrl = (): string =>
  localStorage.getItem(ClientQueryParams.originBase) || getClientHostURL() || ''

export const getDestinationURL = (): string =>
  localStorage.getItem(ClientQueryParams.destinationURL) || ''
