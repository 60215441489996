import { BaseLayout, Header } from '@auth-portal/components'
import { ExpiredPasswordForm } from '@auth-portal/components/expired-password-form'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const ExpiredPasswordRoute: React.FC<{}> = () => {
  const { t } = useTranslation('main')

  return (
    <BaseLayout>
      <div className="form-outer change-password-form">
        <Header heading={t('form.expiredPassword.title')} />

        <ExpiredPasswordForm />
      </div>
    </BaseLayout>
  )
}
