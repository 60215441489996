import { ILogoSize, LogoSizeOptions } from '@auth-portal/types'

export const LogoSizes: Array<ILogoSize> = [
  {
    size: LogoSizeOptions.Small,
    width: 140,
    height: 40,
  },
  {
    size: LogoSizeOptions.Medium,
    width: 179,
    height: 35,
  },
  {
    size: LogoSizeOptions.Large,
    width: 300,
    height: 88,
    padding: 5,
    margin: '48px auto 24px',
  },
]
