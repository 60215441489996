import { Observable } from 'rxjs'

import { SuperSubject } from '../../models'

export abstract class BaseSubjectStore<T extends any> {
  protected state: { [Property in keyof T]: SuperSubject<T[Property]> }

  constructor() {
    this.state = this.initializeState()
  }

  abstract getDefaultValue(): Required<T>

  private initializeState(): {
    [Property in keyof T]: SuperSubject<T[Property]>
  } {
    const defaultValue = this.getDefaultValue()
    return Object.keys(defaultValue).reduce((obj, key) => {
      const result = obj
      result[key as keyof T] = new SuperSubject<T[keyof T]>(
        defaultValue[key as keyof T]
      )
      return result
    }, {} as { [Property in keyof T]: SuperSubject<T[Property]> })
  }

  select = <K extends keyof T>(
    fields: K | K[]
  ): Partial<{ [Property in keyof T]: Observable<T[Property]> }> => {
    let selectedFields: K[]
    if (!Array.isArray(fields)) {
      selectedFields = [fields]
    } else {
      selectedFields = fields
    }

    return selectedFields.reduce((obj, key) => {
      const result = obj
      result[key as keyof T] = this.state[key as keyof T].observable$
      return result
    }, {} as { [Property in keyof T]: Observable<T[Property]> })
  }

  dispatch = (newState: Partial<T>): void => {
    Object.keys(newState).forEach((key) => {
      const subject = this.state[key as keyof T]
      subject.value = newState[key as keyof T] as T[keyof T]
    })
  }

  getStateValue(): T {
    return Object.keys(this.state).reduce((obj, key) => {
      const result = obj
      result[key as keyof T] = this.state[key as keyof T].value
      return result
    }, {} as T)
  }

  reset(): void {
    this.dispatch(this.getDefaultValue())
  }
}
