import React, { useCallback, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { from } from 'rxjs'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import { Box, Grid, Link, Typography } from '@mui/material'
import { RecaptchaActions } from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import {
  BaseLayout,
  ContactConfirmationModal,
  Header,
  MultiSelectionForm,
  SignupFormOtp,
  VerifyDigitalCode,
} from '@auth-portal/components'
import { TermsAndConditions } from '@auth-portal/components/terms-and-conditions'
import { useRecaptcha } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'

interface FormValues {
  email: string
}

const defaultValues: FormValues = {
  email: '',
}

export const LoginDigitCode: React.FC<{}> = () => {
  const { t, i18n } = useTranslation('main')
  const { getRecaptchaToken } = useRecaptcha()
  const { addAlert } = useAlert()
  const [initialValues] = useState(defaultValues)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [showVerifyCodeScreen, setShowVerifyCodeScreen] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showMultiSelectionForm, setShowMultiSelectionForm] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('form.email.requiredError'))
      .email(t('form.email.invalidError')),
  })

  const handleGenerateOtp = useCallback(
    (email: string, actions) => {
      from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).subscribe({
        next: (token: string) => {
          authService.generateSignInOtp(email, token, i18n.language).subscribe({
            next: () => {
              setShowVerifyCodeScreen(true)
              setEmail(email)
            },
            error: () => {
              actions.resetForm({ values: { email } })
              setIsLoading(false)
              addAlert({
                severity: 'error',
                message: t('somethingWrong'),
              })
            },
          })
        },
      })
    },
    [
      authService,
      i18n.language,
      addAlert,
      setEmail,
      setShowVerifyCodeScreen,
      setIsLoading,
      getRecaptchaToken,
    ]
  )

  const handleVerifyClientDomain = useCallback(
    (email: string) => {
      from(getRecaptchaToken(RecaptchaActions.ValidateEmailDomain)).subscribe({
        next: (token) => {
          authService.handleCheckClientDomain(email, token).subscribe({
            next: (response) => {
              if (response) {
                //User doesn't exist but domain exist
                setShowCreateForm(true)
              } else {
                //User and Domain doesn't exist
                setShowMultiSelectionForm(true)
              }
            },
            error: () => {
              setIsLoading(false)
              addAlert({
                severity: 'error',
                message: t('somethingWrong'),
              })
            },
          })
        },
      })
    },
    [
      authService,
      addAlert,
      setShowCreateForm,
      setShowMultiSelectionForm,
      setIsLoading,
      getRecaptchaToken,
    ]
  )

  const handleSubmit = useCallback(
    (values: FormValues, actions) => {
      setEmail(values.email)
      setIsLoading(true)
      from(getRecaptchaToken(RecaptchaActions.GetAnonymousUserInfo)).subscribe({
        next: (token) => {
          authService.getIfUserExist(values.email, token).subscribe({
            next: (response) => {
              if (response) {
                handleGenerateOtp(values.email, actions)
              }
            },
            error: () => {
              handleVerifyClientDomain(values.email)
              actions.resetForm({ values })
            },
          })
        },
      })
    },
    [
      authService,
      getRecaptchaToken,
      handleGenerateOtp,
      addAlert,
      handleVerifyClientDomain,
    ]
  )

  const handleClientCreated = useCallback(() => {
    setShowVerifyCodeScreen(true)
  }, [setShowVerifyCodeScreen, email])

  const handleOpen = useCallback(() => {
    setIsConfirmationOpen(true)
  }, [])

  return (
    <BaseLayout>
      <Grid>
        <Header
          heading={
            showVerifyCodeScreen || showCreateForm || showMultiSelectionForm
              ? undefined
              : t('form.createAccount.otpHeading')
          }
        />
        {showVerifyCodeScreen && !!email ? (
          <VerifyDigitalCode email={email} />
        ) : (
          <>
            {showCreateForm || showMultiSelectionForm ? (
              <>
                {showCreateForm ? (
                  <SignupFormOtp
                    email={email}
                    handleClientCreated={handleClientCreated}
                  />
                ) : (
                  <MultiSelectionForm
                    email={email}
                    handleOpen={handleOpen}
                    handleClientCreated={handleClientCreated}
                  />
                )}
              </>
            ) : (
              <>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {() => (
                    <Box px={4}>
                      <Form className="form-root" noValidate>
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          label={t('form.email.label')}
                          fullWidth
                        />

                        <LoadingButton
                          variant="contained"
                          size="large"
                          color="secondary"
                          type="submit"
                          sx={{ mb: 2.5, mt: 5 }}
                          loading={isLoading}
                        >
                          {t('common.btn.submit')}
                        </LoadingButton>

                        <Link
                          component={RouterLink}
                          underline="none"
                          to="/"
                          sx={{ mt: 2, mb: 5 }}
                        >
                          <Typography
                            variant="body1Bold700"
                            lineHeight={1.5}
                            component="span"
                          >
                            {t('form.reset.backLoginBtn')}
                          </Typography>
                        </Link>
                      </Form>
                    </Box>
                  )}
                </Formik>
                <TermsAndConditions />
              </>
            )}
          </>
        )}
      </Grid>
      <ContactConfirmationModal isOpen={isConfirmationOpen} />
    </BaseLayout>
  )
}
