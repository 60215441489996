import React, { ReactElement } from 'react'

import { BaseLayout, LoginForm } from '@auth-portal/components'

const LoginPage: React.FC<{}> = () => {
  return (
    <BaseLayout>
      <LoginForm />
    </BaseLayout>
  ) as ReactElement
}

export default LoginPage
