import React, { ReactElement } from 'react'

import { BaseLayout, VerifyEmail } from '@auth-portal/components'

const VerifyEmailRoute: React.FC<{}> = () =>
  (
    <BaseLayout>
      <VerifyEmail />
    </BaseLayout>
  ) as ReactElement

export default VerifyEmailRoute
