import React, { ReactElement } from 'react'

import { SignupForm, BaseLayout } from '@auth-portal/components'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'
import { PageTypes } from '@procom-labs/common'

const SignupWithEmail: React.FC = () => {
  useGtmVpv({
    pageType: PageTypes.Register,
    pageTitle: PageTypes.Register,
  })
  return (
    <BaseLayout>
      <SignupForm />
    </BaseLayout>
  ) as ReactElement
}
export default SignupWithEmail
