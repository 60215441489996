import { Dialog, DialogProps } from '@mui/material'

import { ThemeConfig } from '@procom-labs/common'
import { WithGorillaInnerTheme } from './with-inner-theme'

const themeConfig: ThemeConfig = {
  key: 'dialog',
  config: {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              '&.MuiPickersPopper-paper': {
                backgroundColor: theme.palette.common.white,
              },
            }
          },
        },
      },
    },
  },
}
export const GorillaDialog = WithGorillaInnerTheme<DialogProps>(
  Dialog,
  themeConfig
)
