import React, { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useObservable } from 'rxjs-hooks'
import { useTranslation } from 'react-i18next'

import { Preloader } from '@procom-labs/atoms'

import { clientService, IClientSettings, LoginTypes } from '@procom-labs/common'
import { authService } from '@auth-portal/services'
import { ClientPortalBanner } from './client-portal-banner'

const OuterMost = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  '.c-preloader': {
    padding: '20px',
  },
}))

export const Banner: React.FC<{}> = React.memo(() => {
  const { i18n } = useTranslation('main')

  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$,
    null
  )

  const clientName = useObservable(() => clientService.clientName$)

  const loginType: LoginTypes | null =
    useObservable(() => authService.loginType$) || LoginTypes.Client

  const isClientPortal = useMemo(
    () =>
      loginType && loginType.toLowerCase() === LoginTypes.Client.toLowerCase(),
    [loginType]
  )

  if (!clientSettings || !clientName || !loginType) {
    return <Preloader />
  }

  if (isClientPortal) {
    return (
      <OuterMost>
        <ClientPortalBanner />
      </OuterMost>
    )
  }
  if (clientSettings && clientName && loginType) {
    return (
      <OuterMost>
        <Box flexWrap="nowrap" pl={20}>
          <Typography variant="h2">
            {i18n.t(
              `clientsSettings.${clientName.toLowerCase()}.bannerText.${loginType.toLowerCase()}.title`
            )}
            <br />
            <strong style={{ fontWeight: 800 }}>
              {i18n.t(
                `clientsSettings.${clientName.toLowerCase()}.bannerText.${loginType.toLowerCase()}.description`
              )}
            </strong>
          </Typography>
        </Box>
      </OuterMost>
    )
  }
  return null
})
