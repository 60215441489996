import { axios } from '@auth-portal/lib'
import { environment } from '@auth-portal/environment'
import { authService } from '@auth-portal/services/auth.service'
import { MfaService } from '@procom-labs/common'
import { mfaStore } from '@auth-portal/store'

export const mfaService = new MfaService(
  environment,
  axios,
  authService,
  mfaStore
)
