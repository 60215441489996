export enum ProtectedLinksEnum {
  Dashboard = '/dashboard',
  Job = '/jobs',
  Registration = '/registration',
  RateCalculator = '/contractor-rate-calculator',
  User = '/user',
  Timesheets = '/timesheets',
  Expenses = '/expenses',
  Workorders = '/workorders',
  Payments = '/payments',
  Candidates = '/candidates',
  Payroll = '/payroll',
  MatchCandidates = '/match',
  Onboarding = '/onboarding',
  RecruitmentSupport = '/recruitment-support',
  TalentPools = '/talent-lists',
  ContractorManagement = '/contractor-management',
  ConsultantManagement = '/consultant-management',
  Create = '/create',
}

export enum UnProtectedLinksEnum {
  Login = '/',
}

export const PageNotFound = '/page-not-found'
export const FrictionLessViewNoAccessContent = '/frictionless-view'
