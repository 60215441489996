import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Box, styled, SxProps, Theme } from '@mui/material'
import {
  AuthProfileType,
  getClientDomainProperty,
  IClientDomain,
  IClientSettings,
  LoginTypes,
  ProtectedLinksEnum,
} from '@procom-labs/common'

export const LogoWrapper: React.FC<{
  clientSettings: IClientSettings | null
  isAuthenticated?: Boolean
  currentProfileType?: AuthProfileType
  logoWrapperStyles?: React.CSSProperties
  sx?: SxProps<Theme>
  children: React.ReactNode
  clientPortalHostname: string | null
  authPortalHostname: string | null
  loginType?: LoginTypes
}> = React.memo(
  ({
    clientSettings,
    isAuthenticated = false,
    currentProfileType,
    logoWrapperStyles,
    sx,
    children,
    clientPortalHostname,
    authPortalHostname,
    loginType,
  }) => {
    const { t } = useTranslation('main')
    const homepageAnonymousUsersUrl = useMemo(
      () =>
        getClientDomainProperty(
          clientPortalHostname,
          authPortalHostname,
          'homepageAnonymousUsersUrl' as keyof IClientDomain
        ) as Pick<IClientDomain, 'homepageAnonymousUsersUrl'>,
      [authPortalHostname, clientPortalHostname]
    )

    const styledLogoComponent = useMemo(() => {
      let elem: any = 'div'
      if (isAuthenticated) {
        elem = RouterLink
      } else if (homepageAnonymousUsersUrl && loginType !== LoginTypes.Client) {
        elem = 'a'
      }
      return styled(elem)(() => ({
        ...logoWrapperStyles,
      }))
    }, [
      isAuthenticated,
      homepageAnonymousUsersUrl,
      loginType,
      logoWrapperStyles,
    ])

    if (isAuthenticated) {
      return (
        <Box
          component={styledLogoComponent}
          sx={sx}
          to={ProtectedLinksEnum.Dashboard}
          aria-label={t('common.aria.logoHomepage', {
            clientName: clientSettings?.common.name,
          })}
        >
          {children}
        </Box>
      )
    }

    if (homepageAnonymousUsersUrl && loginType !== LoginTypes.Client) {
      return (
        <Box
          component={styledLogoComponent}
          sx={sx}
          href={`${homepageAnonymousUsersUrl}`}
          aria-label={t('common.aria.logoHomepage', {
            clientName: clientSettings?.common.name,
          })}
        >
          {children}
        </Box>
      )
    }

    return (
      <Box component={styledLogoComponent} sx={sx}>
        {children}
      </Box>
    )
  }
)
