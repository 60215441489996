import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { combineLatest, EMPTY, from, of, Subscription, switchMap } from 'rxjs'
import * as Yup from 'yup'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import { GorillaAlert, GorillaDialog } from '@procom-labs/atoms'
import {
  errorToString,
  gtmEvents,
  gtmEventsPrefix,
  RecaptchaActions,
} from '@procom-labs/common'

import { useRecaptcha, useTrackingWrapper } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'

import { Header } from './header'
import { TermsAndConditions } from './terms-and-conditions'

interface FormValues {
  email: string
}

const initialValues: FormValues = {
  email: '',
}

export const ResetPasswordForm: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const [formValues, setFormValues] = useState<FormValues>(initialValues)
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)
  const { t } = useTranslation('main')
  const error = !!errorString
  const subscriptionRef = useRef<Subscription | null>()
  const { track } = useTrackingWrapper()

  const { getRecaptchaToken } = useRecaptcha()

  useEffect(() => {
    if (email) {
      setFormValues({ email })
    }
  }, [email])

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('form.email.requiredError'))
      .email(t('form.email.invalidError')),
  })

  const handleSubmit = (values: any, actions: any): void => {
    setSubmitIsLoading(true)

    subscriptionRef.current = from(
      getRecaptchaToken(RecaptchaActions.GetAnonymousUserInfo)
    )
      .pipe(
        switchMap((token) => authService.getPasswordInfo(values.email, token)),
        switchMap((data) => {
          return combineLatest([
            of(data),
            from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)),
          ])
        }),
        switchMap(([data, token]) => {
          if (!data.hasPasswordExpired) {
            return authService.requestPasswordReset(values.email, token)
          }
          if (data.hasContractorProfile && data.hasPasswordExpired) {
            navigate('/reset-expired-password', {
              state: {
                email,
                code: data?.code,
              },
              replace: true,
            })
          }
          return EMPTY
        })
      )
      .subscribe({
        complete: () => {
          track({
            eventName: gtmEvents.FormResetPassword,
            submissionStatus: 'true',
            email,
          })
          setShowFeedbackDialog(true)
          actions.resetForm()
        },
        error: (err) => {
          setErrorString(errorToString(err))
          track({
            eventName: `${gtmEventsPrefix.Notification}${gtmEvents.FormResetPassword}`,
            submissionStatus: 'false',
            email,
            errorMessage: errorToString(err),
          })
        },
      })

    subscriptionRef.current.add(() => {
      setSubmitIsLoading(false)
      actions.setSubmitting(false)
    })
  }

  const closeFeedbackDialog = (): void => {
    setShowFeedbackDialog(false)
  }

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  return (
    <>
      <div className="form-outer reset-password-form">
        {error && (
          <GorillaAlert severity="error" sx={{ mt: '24px', width: '100%' }}>
            {errorString}
          </GorillaAlert>
        )}
        <Header
          heading={t('form.reset.title')}
          description={t('form.reset.dsc')}
        />

        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Box
                sx={{
                  px: {
                    xs: 1,
                    sm: 4,
                    lg: 5,
                    xl: 7,
                    k2: 12.5,
                  },
                }}
              >
                <Form className="form-root" noValidate>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    InputLabelProps={{ shrink: true }}
                    label={t('form.email.label')}
                    fullWidth
                  />

                  <Link
                    component={RouterLink}
                    underline="none"
                    to="/"
                    sx={{ mt: 5, mb: 5 }}
                  >
                    <Typography
                      variant="body1Bold700"
                      lineHeight={1.5}
                      component="span"
                    >
                      {t('form.reset.backLoginBtn')}
                    </Typography>
                  </Link>

                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="secondary"
                    type="submit"
                    sx={{ mb: 2.5 }}
                    loading={submitIsLoading}
                  >
                    {t('common.btn.submit')}
                  </LoadingButton>
                </Form>
              </Box>
            )
          }}
        </Formik>
        <TermsAndConditions />
      </div>
      <GorillaDialog
        isLightMode
        open={showFeedbackDialog}
        onClose={closeFeedbackDialog}
        aria-labelledby="feedback-title"
        aria-describedby="feedback-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="feedback-title" sx={{ marginTop: '50px' }}>
          <Typography variant="h6" gutterBottom component="div">
            {t('form.reset.dialog.title')}
          </Typography>
          <IconButton
            aria-label={t('common.aria.close')}
            onClick={closeFeedbackDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="feedback-description">
            {t('form.reset.dialog.dsc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="login-form__dialog-actions"
          sx={{ marginBottom: '34px', padding: '0 24px' }}
        >
          <Button variant="outlined" onClick={() => navigate('/')}>
            {t('form.reset.backLoginBtn')}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={closeFeedbackDialog}
            autoFocus
          >
            {t('common.btn.okay')}
          </Button>
        </DialogActions>
      </GorillaDialog>
    </>
  ) as ReactElement
}
