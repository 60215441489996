import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { TFuncKey, useTranslation } from 'react-i18next'
import { Box, Stack, Typography, Link } from '@mui/material'

import { styled } from '@mui/material/styles'

import { clientService, LoginTypes } from '@procom-labs/common'
import { useObservable } from 'rxjs-hooks'
import { authService } from '@auth-portal/services'
import { SignUpRoutes } from '../../types'
import { LanguageSwitcher } from '../language-switcher'

const Disclaimer = styled(
  Box,
  {}
)(() => ({
  display: 'flex',
  justifyItems: 'center',
  flexDirection: 'column',
  width: '100%',
  marginTop: 'auto',
}))

export const Footer: React.FC<{}> = () => {
  const location = useLocation()
  const { t } = useTranslation('main')

  const loginType: LoginTypes | null = useObservable(
    () => authService.loginType$
  )

  const clientName = useObservable(() => clientService.clientName$)

  return (
    <Disclaimer>
      <Stack sx={{ textAlign: 'center' }} spacing={1.375}>
        {Object.values(SignUpRoutes).join().indexOf(location.pathname) >= 0 &&
        location.pathname !== '/' ? null : (
          <>
            {loginType === LoginTypes.Employer && (
              <Typography variant="body2">
                {t('footer.goToOur')}
                <Link
                  component={RouterLink}
                  to={`/?loginType=${LoginTypes.Contractor}`}
                >
                  {t(
                    `clientsSettings.${clientName?.toLowerCase()}.bannerText.contractor.description` as TFuncKey
                  )}
                </Link>
              </Typography>
            )}
          </>
        )}
      </Stack>
      <LanguageSwitcher />
    </Disclaimer>
  )
}
