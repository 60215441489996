import React, { useCallback, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { from } from 'rxjs'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import { Box, InputAdornment, Link, Stack, Typography } from '@mui/material'
import {
  ContactWithoutPasswordPayload,
  ContractorSignupTypes,
  getClientCode,
  getClientPortalHost,
  getVendorCode,
  gtmEvents,
  IAccountType,
  RecaptchaActions,
} from '@procom-labs/common'
import { PhoneInput } from '@procom-labs/molecules'

import { useRecaptcha, useTrackingWrapper } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'
import { getCallbackURL, getOriginBaseUrl } from '@auth-portal/util'

interface IOtpSignInForm {
  firstName: string
  lastName: string
  contactNumber: string
}

const defaultValues: IOtpSignInForm = {
  firstName: '',
  lastName: '',
  contactNumber: '',
}
export const SignupFormOtp: React.FC<{
  email: string
  handleClientCreated: () => void
}> = ({ email, handleClientCreated }) => {
  const { t, i18n } = useTranslation('main')
  const { getRecaptchaToken } = useRecaptcha()
  const { track } = useTrackingWrapper()

  const [initialValues] = useState<IOtpSignInForm>(defaultValues)
  const [submitIsLoading, setSubmitIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form.createAccount.firstNameError')),
    lastName: Yup.string().required(t('form.createAccount.lastNameError')),
    contactNumber: Yup.string()
      .required(t('form.createAccount.phoneNumberError'))
      .min(10, t('form.createAccount.phoneNumberInvalidLength')),
  })

  const handleGenerateOtp = useCallback(() => {
    from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).subscribe({
      next: (token) => {
        authService.generateSignInOtp(email, token, i18n.language).subscribe({
          next: () => {
            handleClientCreated()
            setSubmitIsLoading(false)
          },
        })
      },
    })
  }, [i18n.language, email, handleClientCreated, getRecaptchaToken])

  const handleSubmit = useCallback(
    (values: IOtpSignInForm, actions) => {
      setSubmitIsLoading(true)
      let payload: ContactWithoutPasswordPayload = {
        email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.contactNumber,
        language: i18n.language,
        authPortalBase: window.location.origin,
        callbackUrl: getCallbackURL(),
        originBase: getOriginBaseUrl(),
        vendorCode: getVendorCode(),
        clientCode: getClientCode(getClientPortalHost()) ?? '',
        recaptchaToken: '',
      }

      from(getRecaptchaToken(RecaptchaActions.Signup)).subscribe({
        next: (token) => {
          payload = {
            ...payload,
            recaptchaToken: token,
          }
          authService
            .createContactWithoutPassword(payload, IAccountType.Client)
            .subscribe({
              next: () => {
                handleGenerateOtp()
                track({
                  email,
                  signupType: ContractorSignupTypes.OneTimeCode,
                  eventName: gtmEvents.ContractorSignup,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phoneNumber: values.contactNumber,
                  vendorCode: getVendorCode(),
                  clientCode: getClientCode(getClientPortalHost()) ?? '',
                })
              },
              error: () => {
                actions.resetForm({ values })
                setSubmitIsLoading(false)
              },
            })
        },
      })
    },
    [email, i18n.language, getRecaptchaToken, handleGenerateOtp, track]
  )

  return (
    <Box>
      <Typography component="h1" variant="h30Bold800" align="center" my={6}>
        {t('form.createAccount.createAccount')}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <Form className="form-root">
            <Stack direction="row" gap={2} width="100%">
              <Field
                component={TextField}
                name="firstName"
                type="text"
                label={t('form.createAccount.firstName')}
                fullWidth
              />

              <Field
                component={TextField}
                name="lastName"
                type="text"
                label={t('form.createAccount.lastName')}
                fullWidth
              />
            </Stack>

            <Field
              component={TextField}
              name="contactNumber"
              label={t('form.createAccount.phoneNumber')}
              fullWidth
              InputProps={{
                inputComponent: PhoneInput,
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              onChange={(e: any) => {
                setFieldValue(
                  'contactNumber',
                  e.target.value.replace(/\D/g, '')
                )
              }}
            />

            <LoadingButton
              variant="contained"
              size="large"
              color="secondary"
              type="submit"
              sx={{ mb: 2.5, mt: 5 }}
              fullWidth
              loading={submitIsLoading}
            >
              {t('common.btn.submit')}
            </LoadingButton>

            <Link
              component={RouterLink}
              underline="none"
              to="/"
              sx={{ mt: 2.5, mb: 5 }}
            >
              <Typography
                variant="body1Bold700"
                lineHeight={1.5}
                component="span"
              >
                {t('form.reset.backLoginBtn')}
              </Typography>
            </Link>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
