import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'

import { Grid } from '@mui/material'
import { clientService, IClientSettings, PageTypes } from '@procom-labs/common'
import {
  Footer,
  TermsOfUsePage as TermsOfUseDetail,
} from '@procom-labs/organisms'

import { LegalDocumentLayout } from '@auth-portal/components'
import { environment } from '@auth-portal/environment'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'

const TermsOfUsePage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main')

  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$
  )

  useGtmVpv({
    pageType: PageTypes.TermUse,
    pageTitle: PageTypes.TermUse,
  })

  return (
    <LegalDocumentLayout
      title={t('common.inputs.termsAndPolicy.terms')}
      subTitle={
        clientSettings?.common?.effectiveDate
          ? t('common.termsAndPolicy.updated', {
              effectiveDate: clientSettings?.common.effectiveDate,
            })
          : undefined
      }
    >
      <Grid
        container
        sx={{ paddingBottom: 5, px: { xs: 1, sm: 2, md: 4, lg: 6 } }}
      >
        <TermsOfUseDetail handleBack={(): void => navigate(`/`)} />
      </Grid>
      <Footer environment={environment} />
    </LegalDocumentLayout>
  ) as ReactElement
}

export default TermsOfUsePage
