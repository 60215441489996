import { FC, useCallback, useEffect, useMemo } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { TFuncKey, Trans, useTranslation } from 'react-i18next'
import { EMPTY, from, Subscription, switchMap, tap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { Box, Divider, Link, Typography } from '@mui/material'
import { AlertMessage, LinkButton } from '@procom-labs/atoms'
import {
  EmailSupportLinks,
  LoginTypes,
  mfaCodeVerificationLens,
  MfaService,
  MfaStore,
  MfaTypes,
  RecaptchaActions,
  useRecaptcha,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { OtpInput, useAlert } from '@procom-labs/molecules'

export const MfaLoginCodeVerification: FC<{
  rollbarInstance: any
  authService: any
  mfaService: MfaService
  mfaStore: MfaStore
  currentCognitoUserEmail?: string
  showEmail?: boolean
  loginType?: LoginTypes | null
}> = ({
  rollbarInstance,
  authService,
  mfaService,
  mfaStore,
  currentCognitoUserEmail,
  showEmail = true,
  loginType,
}) => {
  const contactUsLink: string = useMemo(() => {
    return loginType?.toLowerCase() === LoginTypes.Client.toLowerCase()
      ? `mailto:${EmailSupportLinks.GorillaWorks}`
      : `mailto:${EmailSupportLinks.ClientConnections}`
  }, [loginType])

  const { t } = useTranslation('main')
  const { addAlert } = useAlert()

  const { getRecaptchaToken } = useRecaptcha({
    rollbarInstance,
  })

  const currentCognitoUser: CognitoUser | null = useObservable(
    () => authService.currentCognitoUser$
  )
  const { preferredMfaMethod, mfaType } = useSubjectSelector(mfaStore, [
    'preferredMfaMethod',
    'mfaType',
  ])
  const subscrptionRef = useSubscriptionRef()

  const cognitoUserHasMultipleMfaMethods = useObservable(
    () => authService.cognitoUserHasMultipleMfaMethods$
  )

  const handleVerifyCode = useCallback(
    (otpCode: string) =>
      from(getRecaptchaToken(RecaptchaActions.Mfa)).pipe(
        switchMap(() => {
          if (currentCognitoUser && currentCognitoUserEmail)
            return mfaService.handleSendMfaCode({
              code: otpCode,
              currentCognitoUserEmail,
              currentCognitoUser,
            })

          return EMPTY
        })
      ),
    [currentCognitoUser, currentCognitoUserEmail, getRecaptchaToken, mfaService]
  )

  const handleSelectMfaMethod = useCallback(
    (type: MfaTypes) => {
      if (currentCognitoUserEmail) {
        return authService
          .authenticate({
            username: currentCognitoUserEmail,
            password: authService.currentCognitoUserPasswordSubject.value,
          })
          .pipe(
            switchMap(() => {
              if (type)
                return mfaService.handleSelectMfaType({
                  mfaType: type,
                  currentCognitoUser:
                    authService.currentCognitoUserSubject.value ||
                    currentCognitoUser,
                  currentCognitoUserEmail,
                })
              return EMPTY
            })
          )
      }

      return EMPTY
    },
    [authService, currentCognitoUser, currentCognitoUserEmail, mfaService]
  )

  const handleSelectMfaMethodSubscription = useCallback(
    (type: MfaTypes, showAlert = false) => {
      subscrptionRef.current = handleSelectMfaMethod(type).subscribe({
        next: () => {
          if (showAlert)
            addAlert({
              severity: 'success',
              message: t('common.mfa.alerts.mfaMethodChanged', {
                mfaMethod: t(
                  `common.mfa.types.${type.toLowerCase()}` as TFuncKey
                ),
              }),
            })
        },
      })
    },
    [addAlert, handleSelectMfaMethod, t, subscrptionRef]
  )

  useEffect(() => {
    let subscription: Subscription | null = null
    if (
      currentCognitoUserEmail &&
      currentCognitoUser &&
      !preferredMfaMethod &&
      cognitoUserHasMultipleMfaMethods
    ) {
      subscription = mfaService
        .getPreferredMfaMethod(currentCognitoUserEmail)
        .pipe(
          tap((type) => {
            mfaStore.dispatch({
              mfaType: type,
            })
            handleSelectMfaMethodSubscription(type)
          })
        )
        .subscribe()
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
        subscription = null
      }
    }
  }, [
    currentCognitoUser,
    cognitoUserHasMultipleMfaMethods,
    currentCognitoUserEmail,
    handleSelectMfaMethodSubscription,
    mfaService,
    mfaStore,
    preferredMfaMethod,
  ])

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ textAlign: 'center', marginBlockEnd: 2 }}
      >
        {t('organisms.mfaLoginCodeVerification.heading')}
      </Typography>

      {showEmail ? (
        <Typography
          variant="body2"
          sx={{ textAlign: 'center', marginBlockEnd: 2 }}
        >
          <Trans
            i18nKey="organisms.mfaLoginCodeVerification.subHeading"
            values={{ email: currentCognitoUserEmail }}
          />
        </Typography>
      ) : null}

      {mfaType && (
        <AlertMessage severity="info" sx={{ marginBlockEnd: 4 }}>
          {t(
            `organisms.mfaLoginCodeVerification.info.${mfaType.toLowerCase()}` as TFuncKey
          )}
        </AlertMessage>
      )}

      <Divider sx={{ marginBlockEnd: 4, width: '100%' }} />

      <OtpInput
        name={mfaCodeVerificationLens.verificationCode.$key()}
        verifyCode={handleVerifyCode}
        sx={{
          '& .code-input-ctn': { maxWidth: 250, margin: 'auto' },
          '& .MuiFormHelperText-root': { textAlign: 'center' },
          marginBlockEnd: 4,
        }}
        {...(mfaType === MfaTypes.SMS
          ? { sendCode: () => handleSelectMfaMethod(MfaTypes.SMS) }
          : {})}
      />

      {cognitoUserHasMultipleMfaMethods && preferredMfaMethod && (
        <Box sx={{ textAlign: 'center', marginBlockEnd: 6 }}>
          {preferredMfaMethod === MfaTypes.Authenticator ? (
            <LinkButton
              onClick={() =>
                handleSelectMfaMethodSubscription(MfaTypes.SMS, true)
              }
            >
              {t(
                'organisms.mfaLoginCodeVerification.mfaMethodUnavailable.totp'
              )}
            </LinkButton>
          ) : (
            <LinkButton
              onClick={() =>
                handleSelectMfaMethodSubscription(MfaTypes.Authenticator, true)
              }
            >
              {t('organisms.mfaLoginCodeVerification.mfaMethodUnavailable.sms')}
            </LinkButton>
          )}
        </Box>
      )}

      <Box sx={{ textAlign: 'center', marginBlockEnd: 6 }}>
        <Typography variant="body2" sx={{ marginBlockEnd: 2 }}>
          {t('organisms.mfaLoginCodeVerification.havingTrouble')}
          <Link href={contactUsLink}>
            {t('organisms.mfaLoginCodeVerification.contactUs')}
          </Link>
        </Typography>
      </Box>
    </>
  )
}
