import React from 'react'
import { Button, ButtonProps } from '@mui/material'

export const LinkButton: React.FC<
  { children: React.ReactNode; underline?: boolean } & ButtonProps
> = ({ children, sx, underline = true, ...rest }) => {
  return (
    <Button
      disableRipple
      disableFocusRipple
      sx={{
        p: 0,
        fontWeight: 400,
        textTransform: 'none',
        textDecoration: underline ? 'solid 1px underline' : 'none',
        '&:hover': {
          fontWeight: 500,
          backgroundColor: 'transparent',
          textDecoration: 'solid 1px underline',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}
