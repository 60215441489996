import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'
import { GorillaAlert } from '@procom-labs/atoms'

import { rollbarInstance } from '@auth-portal/providers'
import { SocialLoginContext } from '@auth-portal/types'

import { Header } from './header'
import { LoginLink } from './login-link'
import { SocialMediaLogin } from './social-media-login'
import { TermsAndConditions } from './terms-and-conditions'

export const SignupMethods: React.FC<{}> = () => {
  const { t } = useTranslation('main')
  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaignId')

  useEffect(() => {
    const signupFailed = searchParams.get('error') === 'signupFailed'

    if (signupFailed) {
      rollbarInstance.error('Error: The sign up failed.')
      setErrorString(t('common.alert.somethingWrong'))
    }
  }, [searchParams, t])

  return (
    <>
      <Header heading={t('form.login.headingWithSocial')} />
      <Box sx={{ textAlign: 'center' }}>
        <Typography component="h1" variant="h5" mb={5.37}>
          {t('form.contractorSignup.title')}
        </Typography>
        {errorString && (
          <GorillaAlert severity="error" sx={{ mb: '24px' }}>
            {errorString}
          </GorillaAlert>
        )}

        <Box
          sx={{
            mt: 2,
            mb: 3,
          }}
        >
          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{
              maxWidth: '440px',
            }}
            component={Link}
            to={{
              pathname: '/signup-email',
              search: campaignId ? `?campaignId=${campaignId}` : '',
            }}
          >
            {t('form.createAccount.signupWithEmail')}
          </Button>
        </Box>

        <Typography
          component="p"
          variant="subtitle1"
          mb={5.37}
          sx={{ textAlign: 'center' }}
        >
          {t('form.contractorSignup.or')}
        </Typography>

        <SocialMediaLogin context={SocialLoginContext.Signup} />

        <LoginLink />

        <Box sx={{ marginTop: 4 }}>
          <TermsAndConditions />
        </Box>
      </Box>
    </>
  )
}
