import React, { ReactElement, useMemo } from 'react'
import { useObservable } from 'rxjs-hooks'

import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import { LogoWrapper } from '@procom-labs/atoms'
import {
  clientService,
  getOriginBase,
  IClientSettings,
  LoginTypes,
  ProcomSettings,
  useClientLogo,
} from '@procom-labs/common'

import { LogoSizes } from '@auth-portal/config/logo-sizes'
import { authService } from '@auth-portal/services'
import { ILogoSize, LogoSizeOptions } from '@auth-portal/types'

export const Logo: React.FC<{
  sx?: SxProps<Theme>
  svgSx?: SxProps<Theme>
  size: LogoSizeOptions
}> = ({ sx, svgSx, size }) => {
  const theme = useTheme()
  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$
  )
  const logoHref = useClientLogo()

  const loginType: LoginTypes | null =
    useObservable(() => authService.loginType$) || LoginTypes.Client
  const clientPortalHostname: string | null = useMemo(() => {
    return getOriginBase()
  }, [])
  const logoSize: ILogoSize = useMemo(
    () =>
      LogoSizes.find((logoConfig) => logoConfig.size === size) || LogoSizes[0],
    [size]
  )

  const authPortalHostname = window.location.hostname

  const SVG = styled('svg')(() => ({
    fill: '#008BCC',
    display: 'block',
    objectFit: 'contain',
    objectPosition: 'center center',
    padding: logoSize.padding || 0,
    use: {
      '--text-color': theme.palette.primary.main,
      '--translate': '0px',
    },
  }))

  const IMG = styled('img')(() => ({
    width: logoSize.width,
    padding: logoSize.padding || 2,
  }))
  const renderLogo = useMemo(() => {
    if (clientSettings?.common.logo?.imgExtension) {
      return <IMG src={logoHref} />
    }
    return (
      <SVG
        sx={{
          width: logoSize.width,
          height: logoSize.height,
          ...svgSx,
        }}
      >
        <use href={logoHref} />
      </SVG>
    )
  }, [
    IMG,
    SVG,
    clientSettings?.common.logo?.imgExtension,
    logoHref,
    logoSize.height,
    logoSize.width,
    svgSx,
  ])
  return (
    <LogoWrapper
      sx={sx}
      clientSettings={clientSettings || ProcomSettings}
      clientPortalHostname={clientPortalHostname}
      authPortalHostname={authPortalHostname}
      loginType={loginType}
      logoWrapperStyles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: logoSize?.margin,
      }}
    >
      {renderLogo}
    </LogoWrapper>
  ) as ReactElement
}
