import { authService } from '@auth-portal/services'
import { Preloader } from '@procom-labs/atoms'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { concat, from, Subscription, switchMap } from 'rxjs'
import {
  ClientQueryParams,
  CommonMessages,
  RecaptchaActions,
} from '@procom-labs/common/'
import { rollbarInstance } from '@auth-portal/providers'

export const ActivateFboInvitation: React.FC<{}> = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const emailParam = searchParams.get('email')
  const migrationToken = searchParams.get('migrationToken')
  const tenantParam = searchParams.get('tenant')
  const onboardingCodeParam = searchParams.get('onboardingCode')
  const destinationUrlParam = searchParams.get('destinationURL')

  const getRecaptchaToken = useCallback(async (): Promise<string> => {
    if (!executeRecaptcha) {
      throw new Error('executeRecaptcha is not ready')
    }
    try {
      const token = await executeRecaptcha(RecaptchaActions.ActivateFboMapping)
      return token
    } catch (e: any) {
      rollbarInstance.error(e)
      return ''
    }
  }, [executeRecaptcha])

  useEffect(() => {
    let subscription = new Subscription()
    if (
      emailParam &&
      tenantParam &&
      executeRecaptcha &&
      (migrationToken || onboardingCodeParam)
    ) {
      const email = decodeURIComponent(emailParam)

      subscription = from(getRecaptchaToken())
        .pipe(
          switchMap((token) =>
            concat(
              authService.activateFboUserMapping(
                migrationToken,
                onboardingCodeParam,
                email,
                token,
                tenantParam
              ),
              authService.verifyEmail({
                email,
                migrationToken,
                onboardingCode: onboardingCodeParam,
              })
            )
          )
        )
        .subscribe({
          complete: () => {
            if (destinationUrlParam) {
              localStorage.setItem(
                ClientQueryParams.destinationURL,
                destinationUrlParam
              )
            }
            navigate('/')
          },
          error: (err) => {
            rollbarInstance.error(err)
            navigate(`/?error=${CommonMessages.SomethingWrong}`)
          },
        })
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [
    executeRecaptcha,
    getRecaptchaToken,
    navigate,
    emailParam,
    migrationToken,
  ])

  return <Preloader center />
}
