import { lensProxy } from '../../util/lens-proxy'

export enum MfaStates {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum MfaTypes {
  SMS = 'SMS',
  Authenticator = 'TOTP',
}

export enum MfaFlows {
  Enable = 'Enable',
  Disable = 'Disable',
  Manage = 'Manage',
}

interface IMfaCodeVerificationFields {
  verificationCode: string
}

export const mfaCodeVerificationLens = lensProxy<IMfaCodeVerificationFields>()
