import { useMemo } from 'react'
import { useObservableState } from 'observable-hooks'

import { useTheme } from '@mui/material'

import { ProcomSettings } from '../config'
import { PaletteModeCode } from '../enums'
import { clientService } from '../services'
import { getVendorCode } from '../util'

export const useClientLogo = (): string => {
  const theme = useTheme()
  const clientSettings = useObservableState(
    clientService.clientSetting$,
    ProcomSettings
  )

  const clientCode =
    clientSettings?.common.logo?.assetClient ?? clientSettings?.common.name

  const themePalette = useMemo(() => {
    if (theme.palette.logoMode) {
      return theme.palette.logoMode
    }
    if (theme.palette.mode === PaletteModeCode.light) {
      return 'light'
    }
    return 'dark'
  }, [theme.palette.mode, theme.palette.logoMode])

  const vendorCode = getVendorCode()
  return useMemo(() => {
    if (clientSettings?.common.logo?.imgExtension) {
      return `/assets/vendors/${vendorCode}/${clientCode}/logo-${themePalette}.${clientSettings?.common.logo?.imgExtension}`
    }
    return `/assets/vendors/${vendorCode}/${clientCode}/logo-${themePalette}.svg#default`
  }, [
    clientCode,
    clientSettings?.common.logo?.imgExtension,
    themePalette,
    vendorCode,
  ])
}
