import { SiteList } from '../config'

export const getClientCode = (
  clientPortalBase?: string | null
): string | null => {
  const hostName = clientPortalBase || window.location.hostname
  return (
    SiteList.find((portal) =>
      portal.domains.some(
        (domain) =>
          domain.hostname === hostName || domain.authHostname === hostName
      )
    )?.code || null
  )
}
