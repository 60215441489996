import { AxiosRequestConfig, AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { authStorage } from '../util'
import { IPerformanceTracking } from '../models'

declare module 'axios' {
  interface AxiosRequestConfig {
    metadataPerfTracking?: IPerformanceTracking
  }
}

const axiosPerfTracking = Axios.create({})

const DefaultPerformanceData: IPerformanceTracking = {
  url: '',
  startTime: '',
  httpVerb: 'POST',
  instanceUiId: '',
  requestSource: 'Client Connections',
}

export class PerformanceTrackingService {
  environment: any

  rollbarInstance: any

  constructor({
    environment,
    rollbarInstance,
  }: {
    environment: any
    rollbarInstance: any
  }) {
    this.environment = environment
    this.rollbarInstance = rollbarInstance
  }

  getRequestPerfTracking = (
    config: AxiosRequestConfig
  ): IPerformanceTracking | null => {
    if (config.url && config.method) {
      // eslint-disable-next-line no-param-reassign
      config.metadataPerfTracking = {
        ...config.metadataPerfTracking,
        url: config.url,
        startTime: new Date().toISOString(),
        instanceUiId: '',
        httpVerb: config.method.toUpperCase(),
        requestSource: 'Client Connections',
      }

      return config.metadataPerfTracking
    }
    return null
  }

  doSendPerfTracking(perfTrackingData: IPerformanceTracking): void {
    const perfTrackingUrl = `${this.environment.FBO_PERFORMACE_URL}/performance/savePageLoaderApi`
    axiosPerfTracking.post(perfTrackingUrl, perfTrackingData).subscribe({
      error: (error) => {
        this.rollbarInstance.error(error)
      },
    })
  }

  sendPerfTracking = ({
    response,
    storeFboData,
    isError,
  }: {
    response: AxiosResponse
    storeFboData?: any
    isError?: boolean
  }): void => {
    if (response.config.metadataPerfTracking) {
      const authStoredData = authStorage.get()

      response.config.metadataPerfTracking = {
        ...response.config.metadataPerfTracking,
        endTime: new Date().toISOString(),
        duration:
          Date.now() -
          Date.parse(response.config.metadataPerfTracking.startTime),
        httpStatusCode: response.status,
        portalUserId: authStoredData?.user?.id,
        userProfileId: storeFboData?.profileId,
        exception: isError ? JSON.stringify(response?.data) : undefined,
        portalImpersonatorUserId: authStoredData?.supportUserId ?? undefined,
      }

      this.doSendPerfTracking(response.config.metadataPerfTracking)
    }
  }

  sendDirectPerformanceTracking(data: Partial<IPerformanceTracking>): void {
    const authStoredData = authStorage.get()
    this.doSendPerfTracking({
      ...DefaultPerformanceData,
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      portalUserId: authStoredData?.user?.id,
      duration: 1,
      httpStatusCode: 200,
      ...data,
    })
  }
}
