import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { from, interval, Observable, switchMap, takeWhile, tap } from 'rxjs'

import {
  errorToString,
  SubmissionTrackingStatus,
  UnProtectedLinksEnum,
  useSubscriptionRef,
} from '@procom-labs/common'

import { loginRequest } from '@auth-portal/config'
import { authService } from '@auth-portal/services'
import { ssoLoginTrackingDate } from '@auth-portal/util/get-tracking-data'
import { useMsal } from '@azure/msal-react'

import { useTrackingWrapper } from './use-tracking-wrapper'

type HandleErrorMessageType = (error?: string) => void
interface IMsSsoLoginResult {
  handleMsSsoLogin: (
    handleErrorMessages?: HandleErrorMessageType
  ) => Promise<void>
  handleMsSsoRedirect: () => Observable<{
    idToken?: string
    responseReceived: boolean
  }>
}

export const useMsSsoLogin = (): IMsSsoLoginResult => {
  const { t } = useTranslation('main')
  const { instance, accounts } = useMsal()
  const navigate = useNavigate()

  const { track } = useTrackingWrapper()
  const ssoLoginSubscriptionRef = useSubscriptionRef()

  const handleMsSsoLogin = useCallback(
    async (handleErrorMessages?: HandleErrorMessageType): Promise<void> => {
      handleErrorMessages?.(undefined)
      if (accounts.length === 1) {
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          const { idToken } = tokenResponse
          if (idToken) {
            ssoLoginSubscriptionRef.current = authService
              .microsoftSSOLogin(idToken)
              .subscribe({
                complete: () => track(ssoLoginTrackingDate(accounts[0])),
              })
          }
        } catch (tokenSilentError) {
          track({
            ...ssoLoginTrackingDate(
              accounts[0],
              SubmissionTrackingStatus.Failed
            ),
            errorMessage: errorToString(tokenSilentError),
          })
          handleErrorMessages?.(t('somethingWrong')) // setting error message in login-form
          navigate(UnProtectedLinksEnum.Login)
        }
      } else {
        await instance.loginRedirect(loginRequest)
      }
    },
    [accounts, instance, ssoLoginSubscriptionRef, track, navigate, t]
  )

  const handleRedirectResponse = useCallback((): Observable<{
    idToken?: string
    responseReceived: boolean
  }> => {
    return from(
      (async (): Promise<{ idToken?: string; responseReceived: boolean }> => {
        try {
          const response = await instance.handleRedirectPromise()
          if (response) {
            const { idToken } = response
            if (idToken) {
              return { idToken, responseReceived: true }
            }
          }
          return { responseReceived: false }
        } catch (error) {
          return { responseReceived: false }
        }
      })()
    )
  }, [instance])

  const handleMsSsoRedirect = useCallback((): Observable<{
    idToken?: string
    responseReceived: boolean
  }> => {
    // sometime microsoft redirect user to fallback screen before token is available, so we need to check for token in interval
    return interval(1000).pipe(
      switchMap(() => handleRedirectResponse()),
      takeWhile(({ responseReceived }) => !responseReceived, true),
      tap(({ idToken, responseReceived }) => {
        if (responseReceived && idToken) {
          ssoLoginSubscriptionRef.current = authService
            .microsoftSSOLogin(idToken)
            .subscribe({
              complete: () => track(ssoLoginTrackingDate(accounts[0])),
            })
        }
      })
    )
  }, [accounts, handleRedirectResponse, ssoLoginSubscriptionRef, track])

  return {
    handleMsSsoLogin,
    handleMsSsoRedirect,
  }
}
