import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import {
  errorToString,
  SubmissionTrackingStatus,
  UnProtectedLinksEnum,
} from '@procom-labs/common'

import { useTrackingWrapper } from '@auth-portal/hooks'
import { useMsSsoLogin } from '@auth-portal/hooks/use-ms-sso-login'
import { ssoLoginTrackingDate } from '@auth-portal/util/get-tracking-data'
import { useMsal } from '@azure/msal-react'

export const MsSsoCallBack: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('main')
  const { accounts } = useMsal()
  const { handleMsSsoRedirect } = useMsSsoLogin()
  const { track } = useTrackingWrapper()
  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const handleErrorMessages = useCallback(
    (errorMessage: string | undefined) => {
      setErrorString(errorMessage)
    },
    []
  )

  useEffect(() => {
    const subscription = handleMsSsoRedirect().subscribe({
      error: (err) => {
        track({
          ...ssoLoginTrackingDate(accounts[0], SubmissionTrackingStatus.Failed),
          errorMessage: errorToString(err),
        })
        handleErrorMessages?.(t('somethingWrong'))
        navigate(UnProtectedLinksEnum.Login)
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [accounts, handleErrorMessages, handleMsSsoRedirect, navigate, t, track])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ fontSize: 30, fontWeight: 600, height: '100vh' }}
    >
      {t('form.login.verifyEmail.redirectLoading')}
      {errorString && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ fontSize: 18, height: 400, mt: 3 }}
        >
          {errorString}
        </Box>
      )}
    </Box>
  )
}
