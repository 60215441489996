import { IClientDomain } from '../models/portal'
import { SiteList } from '../config/portal'

export const getClientDomainProperty = (
  clientPortalHostname: string | null,
  authPortalHostname: string | null,
  clientProperty: keyof IClientDomain
): Partial<IClientDomain> | null => {
  return (
    (SiteList.flatMap((portal) => portal.domains).find((domain) => {
      // We compare with client portal hostname if possible.
      // In the edge case clientPortalHostname is not available, use authPortalHostname as a fallback.
      // Edge case could be that auth portal was bookmarked and/or localstorage was cleared

      return (
        domain.hostname === clientPortalHostname ||
        domain.authHostname === authPortalHostname
      )
    })?.[clientProperty] as Partial<IClientDomain>) || null
  )
}
