import { getAuthPortalUrl } from '@procom-labs/common'

import { environment } from '@auth-portal/environment'
import {
  BrowserCacheLocation,
  PublicClientApplication,
} from '@azure/msal-browser'

const authPortalUrl = getAuthPortalUrl(window.location.hostname)

const msalConfig = {
  auth: {
    clientId: environment.MS_SSO_CLIENT_ID,
    authority: environment.MS_SSO_AUTHORITY,
    redirectUri: `${authPortalUrl}/signin-ms-sso-callback-spa`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true, // Recommended for IE11 or Edge
  },
}

export const loginRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
}

export const msalInstance = new PublicClientApplication(msalConfig)
