import { VendorCodes } from '@common/enums'

import { IAuthUser } from './auth-user'

export interface IAuthResponse {
  token: string
  refreshToken: string | null
  refreshTokenExpiredTime: string | null
  expiredTime: string
  user: IAuthUser | null
  isNewVendorClientContractorUser: boolean | null
  sendbirdAppId: string | null
  firstLogin: boolean | null
  supportUserId: string | null
}

export interface ISocialAuthResponse extends IAuthResponse {
  messageType?: string
  error?: string
}

export interface ISocialLoginTokens {
  idToken: string
  refreshToken: string
  accessToken: string
}

export type PasswordInfoResponse = {
  code: string
  hasPasswordExpired: boolean
  hasContractorProfile: boolean
}

export type PasswordVerificationCodeResponse = {
  isCodeValid: boolean
  hasCodeExpired: boolean
  hasReachedMaxAttempts?: boolean
}

export type ContactWithoutPasswordPayload = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  language: string
  originBase: string
  callbackUrl: string
  authPortalBase: string
  vendorCode: VendorCodes
  clientCode: string
  recaptchaToken: string
  companyName?: string
}

export enum IAccountType {
  Client = 'Client',
  Contractor = 'Contractor',
}
