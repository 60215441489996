export enum AwsErrorCodes {
  NotAuthorizedException = 'NotAuthorizedException',
  LimitExceededException = 'LimitExceededException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  ExpiredCodeException = 'ExpiredCodeException',
  TooManyRequestsException = `TooManyRequestsException`,
  InvalidPasswordException = 'InvalidPasswordException',
  UserNotFoundException = 'UserNotFoundException',
  UsernameExistsException = 'UsernameExistsException',
}
