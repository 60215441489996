import React from 'react'
import { PageNotFoundContent } from '@procom-labs/organisms'
import { LegalDocumentLayout } from '@auth-portal/components/legal-document-layout'

export const PageNotFound: React.FC = () => {
  return (
    <LegalDocumentLayout>
      <PageNotFoundContent showHomeButton />
    </LegalDocumentLayout>
  )
}
