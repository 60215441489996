import { IClientSettings } from '@common/models/clients-settings'

import { PaletteModeCode } from '../../../enums'
import { TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE } from '../../../util/helpers'

export const BmoSettings: IClientSettings = {
  common: {
    name: 'BMO',

    privacyPolicyURL: {
      en: 'https://www.bmo.com/main/about-bmo/privacy-security/our-privacy-code/',
      fr: 'https://www.bmo.com/principal/a-propos-de-nous/confidentialite-securite/notre-code-de-confidentialite/',
    },
    effectiveDate: TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE,
  },
  defaultThemeMode: {
    guestAuth: PaletteModeCode.light,
    guestClient: PaletteModeCode.light,
    authenticated: PaletteModeCode.light,
  },
}
