import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import {
  AwsErrorCodes,
  errorToString,
  HTTPResponseStatus,
  RecaptchaActions,
} from '@procom-labs/common'

import { authService } from '@auth-portal/services'
import { useObservable } from 'rxjs-hooks'
import {
  combineLatest,
  forkJoin,
  from,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
  concatMap,
  toArray,
} from 'rxjs'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { rollbarInstance } from '@auth-portal/providers'
import { IInitialAuthentication } from '@auth-portal/types'
import { ChangePassword } from './change-password'
import { Header } from './header'
import { GorillaAlert } from '@procom-labs/atoms'

export const CompleteRegistrationForm: React.FC<{}> = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation('main')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const showChangeForm = useObservable(() =>
    combineLatest([
      authService.currentCognitoUser$,
      authService.userAttribute$,
    ]).pipe(map(([user, attrs]) => !!(user && attrs)))
  )
  const emailParam = searchParams.get('email')
  const code = searchParams.get('code')
  const migrationTokenParam = searchParams.get('migrationToken')
  const sourceParam = searchParams.get('source')
  const tenantParam = searchParams.get('tenant')
  const onboardingCodeParam = searchParams.get('onboardingCode')
  const [errorString, setErrorString] = useState<string | null>(null)
  const [isFboMigrationTokenValid, setIsFboMigrationTokenValid] =
    useState<boolean>(false)
  const [migrationToken, setMigrationToken] = useState<string | null>(null)
  const error = !!errorString
  const getRecaptchaToken = useCallback(async (): Promise<{
    resendInvitationToken: string
    activateFboMappingToken: string
  }> => {
    if (!executeRecaptcha) {
      throw new Error('executeRecaptcha is not ready')
    }
    const recaptcha = {
      resendInvitationToken: '',
      activateFboMappingToken: '',
    }
    recaptcha.resendInvitationToken = await executeRecaptcha(
      RecaptchaActions.ResendInvitation
    )
    recaptcha.activateFboMappingToken = await executeRecaptcha(
      RecaptchaActions.ActivateFboMapping
    )
    return recaptcha
  }, [executeRecaptcha])

  const resendUserInvitation = useCallback(
    (
      decodedEmail: string,
      fboMigrationToken?: string | null,
      onboardingCode?: string | null
    ): Subscription => {
      return from(getRecaptchaToken())
        .pipe(
          switchMap((recaptcha) =>
            authService.resendUserInvitation(
              decodedEmail,
              recaptcha.resendInvitationToken,
              fboMigrationToken,
              onboardingCode
            )
          )
        )
        .subscribe({
          complete: () => {
            // this does set an error message, but it's only telling the user that their original link expired, and we've sent them new one
            setErrorString(t('common.cognitoErrors.inviteExpired'))
          },
          error: (e: any) => {
            const { status } = e.response
            rollbarInstance.error(e)
            if (status === HTTPResponseStatus.UnprocessableEntity) {
              // This means that the user has already created their account, so we redirect to login page and show the toast message
              navigate('/?accountExists=true')
            } else {
              setErrorString(errorToString(e))
            }
          },
        })
    },
    [getRecaptchaToken, navigate, t]
  )

  const handleAuthentication = useCallback(
    (initialAuthentication: IInitialAuthentication): Subscription => {
      return authService
        .authenticate({
          username: initialAuthentication.email,
          password: initialAuthentication.oneTimeCode,
          context: 'complete-registration',
        })
        .subscribe({
          error: (err) => {
            rollbarInstance.error(err)
            if (err.code === AwsErrorCodes.NotAuthorizedException) {
              resendUserInvitation(
                initialAuthentication.email,
                initialAuthentication.fboMigrationToken,
                initialAuthentication.onboardingCode
              )
            } else {
              setErrorString(errorToString(err.message))
            }
          },
        })
    },
    [resendUserInvitation]
  )

  useEffect(() => {
    let subscription: Subscription
    if (emailParam && code && executeRecaptcha) {
      const initialAuthentication: IInitialAuthentication = {
        email: decodeURIComponent(emailParam),
        oneTimeCode: decodeURIComponent(code),
      }

      if (migrationTokenParam && sourceParam && tenantParam) {
        // This handles the FBO user invitation flow
        setMigrationToken(migrationTokenParam)
        // Get migration info by FBO migration token
        authService
          .getUserMigrationInfo(migrationTokenParam, sourceParam)
          .subscribe({
            next: (response: any) => {
              const { data: migrationData } = response
              if (!migrationData.isMigrated) {
                // Throw error if the FBO user is not migrated yet
                setErrorString(t('fboUserInvitation.theUserIsNotMigratedYet'))
                setIsFboMigrationTokenValid(false)
              } else if (migrationData.isActivated) {
                // Throw error if the FBO user has been activated
                setErrorString(t('fboUserInvitation.theUserHasBeenActivated'))
                setIsFboMigrationTokenValid(false)
              } else {
                setIsFboMigrationTokenValid(true)
                // Now the FBO migration info is valid.
                // Authenticate the cognito user with the email and temporary password
                subscription = handleAuthentication({
                  ...initialAuthentication,
                  fboMigrationToken: migrationTokenParam,
                })
              }
            },
            error: (err: any) => {
              setErrorString(errorToString(err.response.data))
            },
          })
      } else if (onboardingCodeParam) {
        // This handles onboarding invitation flow.
        subscription = handleAuthentication({
          ...initialAuthentication,
          onboardingCode: onboardingCodeParam,
        })
      } else {
        // This handles client portal invitation flow.
        subscription = handleAuthentication(initialAuthentication)
      }
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [
    code,
    emailParam,
    executeRecaptcha,
    getRecaptchaToken,
    handleAuthentication,
    migrationTokenParam,
    onboardingCodeParam,
    sourceParam,
    t,
    tenantParam,
  ])

  const handleSubmit = useCallback(
    (values: any): Observable<any> => {
      const isFboUser = !!migrationToken && isFboMigrationTokenValid
      const isOnboardingInvitation = !!onboardingCodeParam
      if (
        (isFboUser && (!migrationToken || !tenantParam)) ||
        (isOnboardingInvitation && (!onboardingCodeParam || !tenantParam)) ||
        !emailParam
      ) {
        rollbarInstance.error(
          `Missing required params on complete registration: 
          migrationToken: ${migrationToken}, emailParam: ${emailParam}, tenantParam: ${tenantParam}, onboardingCodeParam: ${onboardingCodeParam}`
        )
        return throwError(() => t('common.alert.somethingWrong'))
      }

      const email = decodeURIComponent(emailParam)

      return forkJoin([
        authService.initialLogin(values.password),
        from(getRecaptchaToken()),
      ]).pipe(
        switchMap(([, recaptcha]) => {
          return isFboUser || isOnboardingInvitation
            ? // Execute activateFboUserMapping, setUserRegisteredOn and verifyEmail in sequence to avoid concurrency issue
              // start with of(null) to initiate the concatMap chain
              of(null).pipe(
                concatMap(() =>
                  authService.activateFboUserMapping(
                    migrationToken,
                    onboardingCodeParam,
                    emailParam,
                    recaptcha.activateFboMappingToken,
                    tenantParam
                  )
                ),
                concatMap(() => authService.setUserRegisteredOn()),
                concatMap(() =>
                  authService.verifyEmail({
                    email,
                    migrationToken,
                    onboardingCode: onboardingCodeParam,
                  })
                ),
                // collect all emitted values and emits them after all operations have completed
                toArray()
              )
            : of(null)
        }),
        switchMap(() => of({ clientPortalRedirectionEnabled: true }))
      )
    },
    [
      emailParam,
      getRecaptchaToken,
      isFboMigrationTokenValid,
      migrationToken,
      onboardingCodeParam,
      t,
      tenantParam,
    ]
  )

  return (
    <div className="form-outer complete-registration-form">
      <Header heading={t('form.completeRegistration.heading')} />
      <Typography
        component="p"
        variant="body2"
        sx={{ mb: '24px' }}
        align="center"
      >
        {t('common.preps.for')}&nbsp;
        <em>
          <strong>{emailParam}</strong>
        </em>
      </Typography>
      {error && (
        <GorillaAlert severity="error" sx={{ mb: '24px' }}>
          {errorString}
        </GorillaAlert>
      )}
      {/* We wait until the user and his attributes are set
                before showing the form since they are required to send a change password request. */}
      {showChangeForm && (
        <ChangePassword
          submitRequest={handleSubmit}
          submitLabel={t('form.completeRegistration.subLabel')}
          email={emailParam || ''}
          gtmEvent="notification-form-complete-registration"
        />
      )}
    </div>
  ) as ReactElement
}
