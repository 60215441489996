import React, { ReactElement, useMemo } from 'react'

import { Box, IconButton, Link, Stack, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { SocialLoginType, useIsClientFlexTrack } from '@procom-labs/common'
import FacebookSvg from '@procom-labs/common/src/assets/svg/social/facebook.svg'
import GoogleSvg from '@procom-labs/common/src/assets/svg/social/google.svg'
import LinkedInSvg from '@procom-labs/common/src/assets/svg/social/linkedin.svg'

export type SvgListType = {
  name: SocialLoginType
  svg: ReactElement
}

const SocialButton = styled(
  Link,
  {}
)(() => ({
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const SocialMediaButtonGroup: React.FC<{
  storageUrl: string
  handleClick: (socialLoginType: SocialLoginType) => void
  socialLoginTypes?: SocialLoginType[]
}> = ({
  storageUrl,
  handleClick,
  socialLoginTypes = [
    SocialLoginType.Google,
    SocialLoginType.Facebook,
    SocialLoginType.Linkedin,
  ],
}) => {
  const theme = useTheme()
  const isClientFlexTrack = useIsClientFlexTrack()
  const filteredSocialLoginTypes = isClientFlexTrack
    ? socialLoginTypes.filter((type) => type !== SocialLoginType.Linkedin)
    : socialLoginTypes

  const socialList: SvgListType[] = useMemo(
    () => [
      {
        name: SocialLoginType.Google,
        svg: (
          <svg
            data-src={GoogleSvg}
            data-loading="lazy"
            style={{ fill: theme.palette.primary.main }}
          />
        ),
      },
      {
        name: SocialLoginType.Facebook,
        svg: (
          <svg
            data-src={FacebookSvg}
            data-loading="lazy"
            style={{ fill: theme.palette.primary.main }}
          />
        ),
      },
      {
        name: SocialLoginType.Linkedin,
        svg: (
          <svg
            data-src={LinkedInSvg}
            data-loading="lazy"
            style={{ fill: theme.palette.primary.main }}
          />
        ),
      },
    ],
    [theme.palette.primary.main]
  )

  return (
    <Stack direction="row" spacing={2.5} justifyContent="center">
      {filteredSocialLoginTypes.map((socialLoginType) => {
        return (
          <SocialButton
            key={socialLoginType}
            onClick={() => handleClick(socialLoginType)}
            sx={{
              ...(socialLoginType === SocialLoginType.Microsoft && {
                flex: 1,
                textDecoration: 'unset',
              }),
            }}
          >
            {socialLoginType !== SocialLoginType.Microsoft ? (
              socialList.map((socialListItem) => {
                if (socialListItem.name === socialLoginType) {
                  return (
                    <IconButton key={socialListItem.name}>
                      {socialListItem.svg}
                    </IconButton>
                  )
                }
                return null
              })
            ) : (
              <>
                <Box
                  component="img"
                  alt={socialLoginType}
                  src={`${storageUrl}/procom-portal-assets/icons/${socialLoginType.toLocaleLowerCase()}.png`}
                />

                <Typography component="p" variant="subtitle1" ml={1}>
                  Microsoft 365
                </Typography>
              </>
            )}
          </SocialButton>
        )
      })}
    </Stack>
  ) as ReactElement
}
