import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import { PreferredLanguageType } from '@procom-labs/common'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    returnEmptyString: false,
    supportedLngs: Object.values(PreferredLanguageType),
    detection: {
      order: ['querystring', 'path', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      caches: ['cookie', 'localStorage'],
    },
    ns: ['main'],
    fallbackLng: PreferredLanguageType.EN,

    // defaultNS,
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    react: { useSuspense: true },
  })
export default i18n
