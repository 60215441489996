import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  Box,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  AppBar as MuiAppBar,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ClientPortalBackground, PortalToolbar } from '@procom-labs/atoms'
import {
  AppTypes,
  clientService,
  PreferredLanguageType,
} from '@procom-labs/common'

import { LogoSizeOptions } from '@auth-portal/types'

import { Logo } from './logo'

const AppBar = styled(MuiAppBar)({
  borderBottom: 'none',
})

export const LegalDocumentLayout: React.FC<{
  title?: string
  subTitle?: string
}> = ({ title, subTitle, children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { i18n } = useTranslation('main')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    clientService.getSettings(AppTypes.auth)
  }, [searchParams])

  const handleLanguageChange = (lang: string): void => {
    i18n.changeLanguage(lang)
  }

  return (
    <Box sx={{ position: 'relative', px: { xs: 1, sm: 2, md: 12 } }}>
      <AppBar position="fixed" color="transparent">
        <PortalToolbar
          sx={{
            display: 'flex',
            justifyContent: title ? 'space-between' : 'end',
            height: 'unset',
          }}
        >
          {!isMobile && (title || subTitle) ? (
            <Grid
              container
              item
              xs={12}
              md="auto"
              columnGap={2.5}
              alignItems="end"
            >
              {title ? (
                <Typography variant="h4" noWrap component="h3">
                  {title}
                </Typography>
              ) : null}
              {subTitle ? (
                <Typography variant="subtitle1" noWrap mb={0.6}>
                  {subTitle}
                </Typography>
              ) : null}
            </Grid>
          ) : null}
          <Stack
            direction="row"
            alignItems="center"
            columnGap={1}
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingLeft: 3,
              },
            }}
          >
            <Logo
              size={LogoSizeOptions.Small}
              svgSx={{
                fill: theme.palette.common.white,
                color: theme.palette.common.white,
                use: {
                  '--text-color': theme.palette.common.white,
                },
              }}
              sx={{
                height: 64,
                margin: 0,
                marginBlock: 2,
                paddingBlock: 1,

                [theme.breakpoints.down('sm')]: {
                  marginRight: 'auto',
                  justifyContent: 'space-between',
                  transform: 'translateX(-20px)',
                },

                [theme.breakpoints.up('lg')]: {
                  marginRight: 2,
                },
              }}
            />
            {!isMobile ? (
              <Divider
                sx={{
                  marginBlock: 3.5,
                  borderColor: theme.palette.common.white,
                }}
                orientation="vertical"
                flexItem
              />
            ) : null}

            <Select
              sx={{
                background: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                width: 80,
              }}
              value={i18n.language || PreferredLanguageType.EN}
              onChange={(e) => handleLanguageChange(e.target.value)}
            >
              {Object.values(PreferredLanguageType).map((val) => (
                <MenuItem key={val} value={val}>
                  <ListItemText primary={val.toUpperCase()} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </PortalToolbar>
      </AppBar>
      <Toolbar sx={{ height: 128 }} />
      {isMobile && (title || subTitle) ? (
        <Stack
          direction="row"
          alignItems="baseline"
          columnGap={2}
          mb={1}
          px={1}
        >
          <Typography variant="h4" gutterBottom component="h1">
            {title}
          </Typography>

          <Typography variant="subtitle1">{subTitle}</Typography>
        </Stack>
      ) : null}

      {children}
      <ClientPortalBackground />
    </Box>
  )
}
