export enum PaletteModeCode {
  light = 'light',
  dark = 'dark',
  glass = 'glass',
  sidebar = 'sidebar',
}

export enum MuiModalClosingReasons {
  BackdropClick = 'backdropClick',
  EscapeKeyDown = 'escapeKeyDown',
}
