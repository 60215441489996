import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Stack, Typography } from '@mui/material'

import { LogoSizeOptions } from '@auth-portal/types'

import { Logo } from '../logo'

export const Header: React.FC<{ heading?: string; description?: string }> =
  memo(({ heading, description }) => {
    const { t } = useTranslation('main')

    return (
      <Box>
        <Logo sx={{ marginBottom: 3 }} size={LogoSizeOptions.Large} />

        <Stack direction="column" spacing={1} mb={2}>
          <Typography
            component="h1"
            variant="h30Bold800"
            align="center"
            lineHeight={1.5}
          >
            {heading}
          </Typography>
          {description ? (
            <Typography
              component="p"
              variant="body1"
              className="form-description"
              sx={{
                lineHeight: 1.5,
                textAlign: { xs: 'left', sm: 'center' },
              }}
            >
              {t('form.reset.dsc')}
            </Typography>
          ) : null}
        </Stack>
      </Box>
    )
  })
