import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { TFuncKey, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EMPTY, from, map, Subscription, switchMap, tap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
  AlertTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { GorillaAlert, Preloader } from '@procom-labs/atoms'
import {
  clientService,
  EntityTypes,
  RecaptchaActions,
  validFirstNameRegex,
  validLastNameRegex,
  validPasswordRegex,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { Header } from '@auth-portal/components'
import { useRecaptcha } from '@auth-portal/hooks'
import { rollbarInstance } from '@auth-portal/providers'
import { authService } from '@auth-portal/services'
import { IClientPortalSignup } from '@auth-portal/types'

import {
  RenderCheckbox,
  validatePassword,
} from '../components/password-validation'

interface ValidationType {
  transKey?: TFuncKey
  valid: boolean
}

const passwordValidations: { [key: string]: TFuncKey } = {
  Min6Characters: 'form.passwordValidation.min6Characters',
  Min1number: 'form.passwordValidation.min1Number',
  Min1CapitalLetter: 'form.passwordValidation.min1CapitalLetter',
  Min1LowerCaseLetter: 'form.passwordValidation.min1LowerCaseLetter',
  PasswordMatch: 'form.passwordValidation.passwordMatch',
}

export const ClientSignup: React.FC<{}> = () => {
  const { t } = useTranslation('main')
  const rememberMeStorageKey = useObservable(
    () =>
      clientService.clientName$.pipe(
        map((name) => `${name ? name.toLowerCase() : 'portal'}.rememberme`)
      ),
    ''
  )
  const subscriptionRef = useRef<Subscription | null>()

  const [initialCredentials] = useState<IClientPortalSignup>({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    rememberMe: false,
  })

  const [passwordValidation, setPasswordValidation] = useState<
    ValidationType[]
  >([
    {
      transKey: passwordValidations.Min6Characters,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1number,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1CapitalLetter,
      valid: false,
    },
    {
      transKey: passwordValidations.Min1LowerCaseLetter,
      valid: false,
    },
    {
      transKey: passwordValidations.PasswordMatch,
      valid: false,
    },
  ])

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] =
    useState(false)

  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const { executeRecaptcha, getRecaptchaToken } = useRecaptcha()
  const code = searchParams.get('code')
  const { addAlert } = useAlert()
  const initialSignup = searchParams.get('initialSignup')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const accessExpired = searchParams.get('accessExpired')
  const entityType = searchParams.get('entityType')

  const validate = Yup.object().shape({
    firstName: Yup.string()
      .required(t('common.form.firstName.requiredError'))
      .matches(validFirstNameRegex, t('common.form.firstName.matchesError')),
    lastName: Yup.string()
      .required(t('common.form.lastName.requiredError'))
      .matches(validLastNameRegex, t('common.form.lastName.matchesError')),

    password: Yup.string()
      .required(t('form.changePassword.requiredError'))
      .matches(
        validPasswordRegex,
        t('form.passwordValidation.passwordNotStrong')
      ),
    confirmPassword: Yup.string()
      .required(t('form.passwordValidation.confirmPassword'))
      .oneOf(
        [Yup.ref('password'), null],
        t('form.passwordValidation.passwordNotMatch')
      ),
  })

  const passwordValidate = (values: IClientPortalSignup): void => {
    validatePassword(
      values || initialCredentials,
      passwordValidation,
      setPasswordValidation
    )
  }

  const handleSubmit = useCallback(
    (values: IClientPortalSignup, actions: FormikHelpers<any>): void => {
      const decodedEmail = decodeURIComponent(email || '')

      if (values.rememberMe) {
        localStorage.setItem(rememberMeStorageKey, decodedEmail || '')
      }

      subscriptionRef.current = authService
        .initialLogin(values.password)
        .pipe(
          switchMap((idToken) =>
            authService.registerCollaborator(
              values.firstName,
              values.lastName,
              idToken
            )
          )
        )
        .subscribe({
          next: () => {
            actions.setSubmitting(false)
            authService
              .setUserRegisteredOn()
              .pipe(
                tap(() => {
                  authService.redirectToClientPortal()
                })
              )
              .subscribe()
          },
          error: (e) => {
            actions.setSubmitting(false)
            rollbarInstance.error(e)
          },
        })
    },
    [email, rememberMeStorageKey]
  )

  const handleInitialSignup = useCallback(
    (values) => {
      if (email) {
        const decodedEmail = decodeURIComponent(email || '')

        if (values.rememberMe) {
          localStorage.setItem(rememberMeStorageKey, decodedEmail || '')
        }

        subscriptionRef.current = from(
          getRecaptchaToken(RecaptchaActions.Signup)
        )
          .pipe(
            switchMap((recaptchaToken) =>
              authService.clientSignup(
                decodedEmail,
                values.firstName,
                values.lastName,
                values.password,
                recaptchaToken
              )
            )
          )
          .subscribe({
            error: () => {
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
            },
          })
      }
    },
    [addAlert, email, getRecaptchaToken, rememberMeStorageKey, t]
  )

  useEffect(() => {
    if (email && code && !initialSignup && executeRecaptcha) {
      setIsLoading(true)
      const decodedEmail = decodeURIComponent(email)
      const decodedCode = decodeURIComponent(code)
      subscriptionRef.current = from(
        getRecaptchaToken(RecaptchaActions.GetAnonymousUserInfo)
      )
        .pipe(
          switchMap((recaptchaToken) => {
            return authService.getCollaboratorInfo(recaptchaToken, decodedEmail)
          }),
          switchMap((data) => {
            if (!data.isProfileClaimed) {
              return authService.authenticate({
                username: decodedEmail,
                password: decodedCode,
                context: 'complete-registration',
              })
            }
            navigate('/?accountExists=true')
            return EMPTY
          })
        )
        .subscribe({
          complete: () => {
            setIsLoading(false)
          },
          error: (err) => {
            setIsLoading(false)
            rollbarInstance.error(err)
            addAlert({
              message: t('common.alert.somethingWrong'),
              severity: 'error',
            })
          },
        })
    }
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [
    addAlert,
    code,
    email,
    executeRecaptcha,
    getRecaptchaToken,
    initialSignup,
    navigate,
    t,
  ])

  if (isLoading) {
    return <Preloader />
  }
  return (
    <Stack spacing={2}>
      {accessExpired && (
        <GorillaAlert severity="error">
          <AlertTitle>
            {entityType === EntityTypes.ClientJob &&
              t('form.createAccount.alert.accessExpiredTitle')}
            {entityType === EntityTypes.TalentPool &&
              t('form.createAccount.alert.accessExpiredTitleTalentList')}
          </AlertTitle>
          {t('form.createAccount.alert.accessExpiredSubtitle')}
        </GorillaAlert>
      )}
      <Header heading={t('form.createAccount.heading1')} />

      <Formik
        initialValues={initialCredentials}
        validationSchema={validate}
        validate={passwordValidate}
        onSubmit={initialSignup ? handleInitialSignup : handleSubmit}
        enableReinitialize
        validateOnMount
        validateOnBlur
      >
        {({
          values,
          setFieldValue,
          isValid,
          errors,
          touched,
          isSubmitting,
        }) => {
          return (
            <>
              <Form>
                <Grid container mb={2.5} spacing={2.5}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label={t('common.form.firstName.label')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label={t('common.form.lastName.label')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography
                  component="h1"
                  variant="h30Bold800"
                  align="center"
                  sx={{ my: 5 }}
                >
                  {t('form.createAccount.heading2')}
                </Typography>
                <Grid container spacing={2.5}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        onFocus={() => setShowPasswordValidation(true)}
                        InputLabelProps={{ shrink: true }}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label={t('form.changePassword.label')}
                        fullWidth
                        error={!!errors.password && touched.password}
                        InputProps={{
                          autoComplete: 'off',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex={-1}
                                aria-label={t('common.aria.passwordVisibility')}
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Field
                        component={TextField}
                        InputLabelProps={{ shrink: true }}
                        name="confirmPassword"
                        onFocus={() => setShowConfirmPasswordValidation(true)}
                        type={showPassword ? 'text' : 'password'}
                        label={t('form.changePassword.label3')}
                        error={
                          !!errors.confirmPassword && touched.confirmPassword
                        }
                        fullWidth
                        InputProps={{
                          autoComplete: 'off',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex={-1}
                                aria-label={t('common.aria.passwordVisibility')}
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2.5}>
                  <Grid item xs={6}>
                    {showPasswordValidation && (
                      <FormGroup sx={{ width: '100%' }}>
                        <Stack direction="row" flexWrap="wrap" textAlign="left">
                          {passwordValidation.map((validation) =>
                            validation.transKey !==
                            passwordValidations.PasswordMatch ? (
                              <RenderCheckbox
                                validation={validation}
                                key={validation.transKey}
                              />
                            ) : (
                              ''
                            )
                          )}
                        </Stack>
                      </FormGroup>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {showConfirmPasswordValidation && (
                      <FormGroup sx={{ width: '100%' }}>
                        <Stack direction="row" textAlign="left">
                          {passwordValidation.map((validation) =>
                            validation.transKey ===
                            passwordValidations.PasswordMatch ? (
                              <RenderCheckbox
                                validation={validation}
                                key={validation.transKey}
                              />
                            ) : (
                              ''
                            )
                          )}
                        </Stack>
                      </FormGroup>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="left">
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.rememberMe}
                        onClick={() =>
                          setFieldValue('rememberMe', !values.rememberMe)
                        }
                        color="primary"
                      />
                    }
                    componentsProps={{ typography: { lineHeight: 1.5 } }}
                    label={t('form.createAccount.rememberMe')}
                  />
                </Grid>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 4.375 }}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                >
                  {t('form.createAccount.btn')}
                </LoadingButton>
              </Form>
            </>
          )
        }}
      </Formik>
    </Stack>
  )
}
