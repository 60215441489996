import { SiteList } from '../config'
import { VendorCodes } from '../enums'

export const getClientVendorCode = (
  clientPortalBase?: string | null
): VendorCodes => {
  const hostName = clientPortalBase || window.location.hostname
  return (
    SiteList.find((portal) =>
      portal.domains.some(
        (domain) =>
          domain.hostname === hostName || domain.authHostname === hostName
      )
    )?.vendorCode || VendorCodes.PCGL
  )
}
