// https://fettblog.eu/typescript-typing-catch-clauses/
export const errorToString = (e: any): string => {
  let result: string
  if (e instanceof Error) {
    result = e.message
  } else if (typeof e === 'string') {
    result = e as string
  } else {
    result = e.toString()
  }
  // Axios response error message
  if (e.response && e.response.data && e.response.data.message) {
    result = e.response.data.message
  }
  return result
}

// Format is "CODE403: reason for error"
export const errorToCodeAndMessage = (
  e: any,
  defaultCode = 400
): { code: number; message: string } => {
  let message = errorToString(e)
  let code = defaultCode
  if (message.startsWith('CODE')) {
    const codeStr = message.slice(4, 7)
    code = parseInt(codeStr, 10)
    message = message.slice(8) // colon
  }
  return {
    code,
    message,
  }
}
