import { Stack, Typography, Button, SxProps, Theme } from '@mui/material'
import { PreferredLanguageType, clientService } from '@procom-labs/common'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const LangBtnSx: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 900,
  lineHeight: 1.7,
  minWidth: '37px',
  borderRadius: '50px',
  textDecoration: 'none',
  '&:hover': { textDecoration: 'none' },
  '&.Mui-disabled': {
    color: '#372E52',
    backgroundColor: 'common.white',
  },
  '&:not(.Mui-disabled)': {
    color: 'primary.main',
  },
}

export const LanguageSwitcher: React.FC<{}> = () => {
  const { i18n } = useTranslation('main')
  const isFrenchSelected = useMemo(() => {
    return (
      i18n.language?.toLowerCase() === PreferredLanguageType.FR.toLowerCase()
    )
  }, [i18n.language])

  const handleLanguage = (e: any): void => {
    i18n.changeLanguage(e.target.value).then(() => {
      clientService.resaveClientSettings()
    })
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={4}
      justifyContent="center"
    >
      <Typography variant="body1" lineHeight={1.5}>
        {t('footer.languages.title')}
      </Typography>
      <Stack direction="row" spacing={0.75}>
        <Button
          onClick={handleLanguage}
          value={PreferredLanguageType.EN}
          disabled={!isFrenchSelected}
          sx={LangBtnSx}
        >
          {t('footer.languages.en')}
        </Button>
        <Button
          onClick={handleLanguage}
          value={PreferredLanguageType.FR}
          disabled={isFrenchSelected}
          sx={LangBtnSx}
        >
          {t('footer.languages.fra')}
        </Button>
      </Stack>
    </Stack>
  )
}
