import { MutableRefObject, useEffect, useRef } from 'react'
import { Subscription } from 'rxjs'

export const useSubscriptionRef = (): MutableRefObject<Subscription | null> => {
  const subscriptionRef = useRef<Subscription | null>(null)

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  return subscriptionRef
}
