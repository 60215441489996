/* eslint-disable no-param-reassign */
import { AxiosRequestConfig } from 'axios'
import Axios from 'axios-observable'

import { environment } from '@auth-portal/environment'
import { performanceTrackingService } from '@auth-portal/services/performance-tracking.service'

export const axios = Axios.create({
  baseURL: environment.AUTH_API_URL,
})

function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const lng = localStorage.getItem('i18nextLng') || 'en'

  if (environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
    const metadataPerfTracking =
      performanceTrackingService.getRequestPerfTracking(config)
    if (metadataPerfTracking) config.metadataPerfTracking = metadataPerfTracking
  }
  config.headers = {
    'Accept-Language': lng,
    Accept: (config.headers && config.headers.Accept) || 'application/json',
    'Content-Type':
      (config.headers && config.headers['Content-Type']) || 'application/json',
    ...config.headers,
  }
  return config
}

axios.interceptors.request.use(requestInterceptor)
