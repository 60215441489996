import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import Axios from 'axios-observable'
import { map, Observable, tap } from 'rxjs'

import { PasswordVerificationCodeResponse } from '../models/auth/auth-response'
import { MfaFlows, MfaTypes } from '../models/user/mfa'
import { IMFADetails, IUser } from '../models/user/user'
import { MfaStore } from '../store'

interface IUpdateUserMfaDetailsPayload {
  recaptchaToken: string
  mfaDetails: IMFADetails[]
}
export class MfaService {
  environment: any

  axios: Axios

  authService: any

  userService: any

  mfaStore: MfaStore

  constructor(
    environment: any,
    axios: Axios,
    authService: any,
    mfaStore: MfaStore,
    userService?: any
  ) {
    this.environment = environment
    this.axios = axios
    this.authService = authService
    this.userService = userService
    this.mfaStore = mfaStore
  }

  resetMfaSettings(): void {
    this.mfaStore.mfaSettingsReset()
    this.authService.isMfaCodeRequestedSubject.value = false
  }

  updateMfaDetails(mfaDetails: IMFADetails[]): void {
    const phoneNumber = mfaDetails?.find(
      (item) => item.phoneNumber
    )?.phoneNumber
    this.mfaStore.dispatch({ userMfaDetails: mfaDetails })

    if (phoneNumber) this.mfaStore.dispatch({ userMfaPhoneNumber: phoneNumber })
  }

  updateUserMfaDetails({
    userId,
    payload,
  }: {
    userId: string
    payload: IUpdateUserMfaDetailsPayload
  }): Observable<IUser> {
    const url = `${this.environment.AUTH_API_URL}/User/${userId}/MFA`

    return this.axios.put<IUser>(url, payload).pipe(
      tap(({ data }) => {
        if (this.userService) {
          this.userService.userStore.dispatch({
            userProfile: data,
          })
        }
        this.mfaStore.dispatch({ userMfaDetails: data.mfaDetails })
      }),
      map(({ data }) => data)
    )
  }

  handleSelectMfaType({
    currentCognitoUser,
    currentCognitoUserEmail,
    mfaType,
  }: {
    currentCognitoUser: CognitoUser | null
    currentCognitoUserEmail: string
    mfaType: MfaTypes
  }): Observable<void> {
    return new Observable((observer) => {
      if (!currentCognitoUser) {
        observer.error('No currentCognitoUser')
        return
      }
      this.mfaStore.dispatch({ mfaType })

      const mfaTypeCognito =
        mfaType.toLowerCase() === MfaTypes.SMS.toLowerCase()
          ? 'SMS_MFA'
          : 'SOFTWARE_TOKEN_MFA'

      currentCognitoUser.sendMFASelectionAnswer(mfaTypeCognito, {
        onSuccess: (session: CognitoUserSession) => {
          return this.authService.handleCognitoUserOnSuccess({
            cognitoUser: currentCognitoUser,
            cognitoSession: session,
            username: currentCognitoUserEmail,
            sendGtmEvent: false,
            observer,
          })
        },
        onFailure: (error: any) => {
          return this.authService.handleCognitoUserOnFailure({
            cognitoUser: currentCognitoUser,
            username: currentCognitoUserEmail,
            error,
            observer,
          })
        },
        mfaRequired: () => {
          this.mfaStore.dispatch({ preferredMfaMethod: mfaType })
          return this.authService.handleCognitoUserMfaRequired({
            observer,
          })
        },
        totpRequired: () => {
          this.mfaStore.dispatch({ preferredMfaMethod: mfaType })
          return this.authService.handleCognitoUserTotpRequired({
            observer,
          })
        },
      })
    })
  }

  // Used to enable/disabled MFA
  handleSetUserMfaPreference({
    currentCognitoUser,
    userId,
    recaptchaToken,
    phoneNumber,
    isEnabled = true,
    mfaMethodRequested,
  }: {
    currentCognitoUser: CognitoUser
    userId: string
    recaptchaToken: string
    phoneNumber?: string
    isEnabled?: boolean
    mfaMethodRequested?: MfaTypes
  }): Observable<void | PasswordVerificationCodeResponse> {
    return new Observable((observer) => {
      const cognitoMfaSettings = {
        sms: {
          PreferredMfa: false,
          Enabled: false,
        },
        totp: {
          PreferredMfa: false,
          Enabled: false,
        },
      }

      let mfaSettings: IMFADetails[] =
        structuredClone(this.mfaStore.userMfaDetails) || []

      const updateMfaSettings = (): void => {
        const hasMfaMethodRequested = mfaSettings.some(
          (item) =>
            item.type?.toLowerCase() === mfaMethodRequested?.toLowerCase()
        )

        if (
          mfaSettings.length > 0 &&
          hasMfaMethodRequested &&
          mfaMethodRequested
        )
          mfaSettings =
            mfaSettings.map((settings: any) => {
              if (
                settings.type.toLowerCase() === mfaMethodRequested.toLowerCase()
              ) {
                return {
                  ...settings,
                  isEnabled,

                  ...(settings.type?.toLowerCase() ===
                    MfaTypes.SMS.toLowerCase() &&
                    phoneNumber && {
                      phoneNumber,
                    }),
                }
              }

              return {
                ...settings,
              }
            }) || []
        else if (mfaMethodRequested) {
          mfaSettings = [
            ...mfaSettings,
            {
              type: mfaMethodRequested,
              isEnabled: true,
              isPreferred: true,
              ...(mfaMethodRequested?.toLowerCase() ===
                MfaTypes.SMS.toLowerCase() &&
                phoneNumber && {
                  phoneNumber,
                }),
            },
          ]
        } else {
          mfaSettings = []
        }

        const isAuthenticatorEnabled = mfaSettings.some(
          (item) => item.type === MfaTypes.Authenticator && item.isEnabled
        )

        // When both SMS and Authenticator App are enabled, Authenticator is the preferred method
        // isPreferred: setHasPreferred(mfaMethodRequested, isEnabled),
        mfaSettings = mfaSettings.map((item) => {
          if (
            (isAuthenticatorEnabled &&
              item.type?.toLowerCase() ===
                MfaTypes.Authenticator.toLowerCase()) ||
            (!isAuthenticatorEnabled &&
              item.type?.toLowerCase() === MfaTypes.SMS.toLowerCase() &&
              item.isEnabled)
          ) {
            cognitoMfaSettings[
              item.type?.toLowerCase() as keyof typeof cognitoMfaSettings
            ].Enabled = true

            return {
              ...item,
              isPreferred: true,
            }
          }

          cognitoMfaSettings[
            item.type?.toLowerCase() as keyof typeof cognitoMfaSettings
          ].Enabled = item.isEnabled
          return {
            ...item,
            isPreferred: false,
          }
        })
      }

      updateMfaSettings()

      currentCognitoUser.setUserMfaPreference(
        cognitoMfaSettings.sms,
        cognitoMfaSettings.totp,
        (err: any) => {
          if (err) {
            observer.error(err)
            return
          }

          const payload: IUpdateUserMfaDetailsPayload = {
            recaptchaToken,
            mfaDetails: mfaSettings,
          }

          if (userId)
            this.updateUserMfaDetails({
              userId,
              payload,
            })
              .pipe(
                tap((data) => {
                  const isMfaEnabled = data.mfaDetails.some(
                    (item) => item.isPreferred
                  )

                  this.authService.updateCurrentUserMfaStatus(isMfaEnabled)
                })
              )
              .subscribe({
                complete: () => {
                  if (!isEnabled)
                    this.mfaStore.dispatch({ mfaFlow: MfaFlows.Disable })
                  this.mfaStore.dispatch({ isUserMfaSettingsUpdated: true })
                  if (this.mfaStore.mfaType) {
                    observer.next({
                      isCodeValid: true,
                      hasCodeExpired: false,
                    })
                  }
                  observer.complete()
                },
                error: () => {
                  observer.error(err)
                },
              })
        }
      )
    })
  }

  handleVerifySoftwareToken({
    currentCognitoUser,
    code,
    deviceName,
    recaptchaToken,
  }: {
    currentCognitoUser: CognitoUser
    code: string
    deviceName: string
    recaptchaToken: string
  }): Observable<void | { recaptchaToken: string }> {
    return new Observable((observer) => {
      if (currentCognitoUser) {
        currentCognitoUser.verifySoftwareToken(code, deviceName, {
          onSuccess: () => {
            observer.next({ recaptchaToken })
            observer.complete()
          },
          onFailure: (err) => {
            observer.error(err)
          },
        })
      }
    })
  }

  handleAssociateSoftwareToken({
    currentCognitoUser,
  }: {
    currentCognitoUser: CognitoUser
  }): Observable<string> {
    return new Observable((observer) => {
      currentCognitoUser?.associateSoftwareToken({
        associateSecretCode: (secretCode) => {
          observer.next(secretCode)
          observer.complete()
        },
        onFailure: (error) => {
          observer.error(error)
        },
      })
    })
  }

  handleSendMfaCode({
    currentCognitoUser,
    code,
    currentCognitoUserEmail,
  }: {
    currentCognitoUser: CognitoUser
    code: string
    currentCognitoUserEmail: string
  }): Observable<void | PasswordVerificationCodeResponse> {
    return new Observable((observer) => {
      currentCognitoUser?.sendMFACode(
        code,
        {
          onSuccess: (session) => {
            observer.next({
              isCodeValid: true,
              hasCodeExpired: false,
            })
            this.mfaStore.dispatch({ isCognitoUserValid: true })
            this.authService.isMfaCodeRequestedSubject.value = false
            this.mfaStore.resetMfaType()

            return this.authService.handleCognitoUserOnSuccess({
              cognitoUser: currentCognitoUser,
              cognitoSession: session,
              username: currentCognitoUserEmail,
              sendGtmEvent: false,
              observer,
            })
          },
          onFailure: (error) => {
            observer.next({
              isCodeValid: false,
              hasCodeExpired: false,
            })

            return this.authService.handleCognitoUserOnFailure({
              cognitoUser: currentCognitoUser,
              username: currentCognitoUserEmail,
              error,
              observer,
            })
          },
        },
        this.mfaStore.mfaType?.toLowerCase() ===
          MfaTypes.Authenticator?.toLowerCase()
          ? 'SOFTWARE_TOKEN_MFA'
          : 'SMS_MFA'
      )
    })
  }

  getPreferredMfaMethod(email: string): Observable<MfaTypes> {
    const url = `${
      this.environment.AUTH_API_URL
    }/User/preferred-mfa?${new URLSearchParams({
      email,
    })}`
    return this.axios.get<MfaTypes>(url).pipe(
      tap(({ data }) => {
        this.mfaStore.dispatch({ preferredMfaMethod: data })
      }),
      map(({ data }) => data)
    )
  }
}
