import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Rollbar from 'rollbar'

import { RecaptchaActions } from '../enums'

type UseRecaptchaType = {
  getRecaptchaToken: (action: RecaptchaActions) => Promise<string>
  executeRecaptcha:
    | ((action?: string | undefined) => Promise<string>)
    | undefined
}

export const useRecaptcha = ({
  rollbarInstance,
}: {
  rollbarInstance: Rollbar
}): UseRecaptchaType => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getRecaptchaToken = useCallback(
    async (action: RecaptchaActions): Promise<string> => {
      if (!executeRecaptcha) {
        throw new Error('executeRecaptcha is not ready')
      }
      try {
        return await executeRecaptcha(action)
      } catch (e: any) {
        rollbarInstance.error(e)
        return ''
      }
    },
    [executeRecaptcha, rollbarInstance]
  )

  return {
    executeRecaptcha,
    getRecaptchaToken,
  }
}
