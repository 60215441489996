export enum ActivityType {
  Login = 'signin',
  LoginFailed = 'signin-failed',
  NewPasswordRequired = 'new-password-required',
  SignUp = 'sign-up',
  SignUpFailed = 'sign-up-failed',
  Logout = 'logout',
  OtpRequest = 'opt-request',
  OtpVerification = 'opt-verification',
}
