import { useEffect, useMemo, useState } from 'react'
import { combineLatest, debounceTime, Observable } from 'rxjs'

import { BaseSubjectStore } from './base'

export const useSubjectSelector = <T, K extends keyof T>(
  stateStore: BaseSubjectStore<T>,
  keys: K | K[]
): { [P in K]: T[P] } => {
  const [state, setState] = useState<any>(() => {
    const stateValue = stateStore.getStateValue()
    return (Array.isArray(keys) ? keys : [keys]).reduce(
      (sta, key) => ({ ...sta, [key]: stateValue[key] }),
      {} as Partial<T>
    )
  })

  const normalizedKeys = useMemo(
    () => (Array.isArray(keys) ? keys : [keys]),
    [keys]
  )

  // Convert the keys array to a string using join. This allows us to later compare
  // the content of the array instead of its reference, helping with memoization.
  const normalizedKeysJoin = useMemo(
    () => normalizedKeys.join(','),
    [normalizedKeys]
  )

  useEffect(() => {
    const keysArray = normalizedKeysJoin.split(',') as K[]
    const observableMap = stateStore.select(keysArray)
    const sub = combineLatest(
      observableMap as { [Property in keyof T]: Observable<T[Property]> }
    ).subscribe({
      next: (value) => {
        setState(value)
      },
    })
    return () => {
      if (sub && !sub.closed) {
        sub.unsubscribe()
      }
    }
  }, [normalizedKeysJoin, stateStore])
  // Use the `useObservable` hook to subscribe to the observable and keep the
  // component updated with the latest state of the selected fields

  return state
}

export const useDebouncedSubjectSelector = <T, K extends keyof T>(
  stateStore: BaseSubjectStore<T>,
  keys: K | K[],
  delay: number
): { [P in K]: T[P] } => {
  const [state, setState] = useState<any>(() => {
    const stateValue = stateStore.getStateValue()
    return (Array.isArray(keys) ? keys : [keys]).reduce(
      (sta, key) => ({ ...sta, [key]: stateValue[key] }),
      {} as Partial<T>
    )
  })

  const normalizedKeys = useMemo(
    () => (Array.isArray(keys) ? keys : [keys]),
    [keys]
  )

  // Convert the keys array to a string using join. This allows us to later compare
  // the content of the array instead of its reference, helping with memoization.
  const normalizedKeysJoin = useMemo(
    () => normalizedKeys.join(','),
    [normalizedKeys]
  )
  // debounceTime added
  useEffect(() => {
    const keysArray = normalizedKeysJoin.split(',') as K[]
    const observableMap = stateStore.select(keysArray)
    const sub = combineLatest(
      observableMap as { [Property in keyof T]: Observable<T[Property]> }
    )
      .pipe(debounceTime(delay))
      .subscribe({
        next: (value) => {
          setState(value)
        },
      })
    return () => {
      if (sub && !sub.closed) {
        sub.unsubscribe()
      }
    }
  }, [delay, normalizedKeysJoin, stateStore])
  // Use the `useObservable` hook to subscribe to the observable and keep the
  // component updated with the latest state of the selected fields

  return state
}
