import * as Yup from 'yup'

import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'

export const isValidEmail = (str: string): boolean =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str)

export const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

// Only accept valid emails that have a domain different from gmail, hotmail, icloud, and outlook.
export const nonPersonalEmailRegex =
  /^[A-Z0-9._%+-]+@(?!gmail\.|hotmail\.|icloud\.|outlook\.)[A-Z0-9.-]+\.[A-Z]{2,4}$/i

// Only accept letters (including French accents), dash, and apostrophes
export const validFirstNameRegex = /^([a-zA-Z-À-ÿ]|'|-)+$/

// Accept letters (including French accents), dash, apostrophes and spaces
export const validLastNameRegex = /^([a-zA-Z-À-ÿ]|'|-)+$/

// Accept letters (including French accents), dash, apostrophes and spaces
export const validTitleRegex = /[a-zA-Z\-'\S]+/g

// Should have minimum 6 characters, 1 number, 1 lowercase and 1 uppercase letter
export const validPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{6,})/

// Should have minimum 6 characters, 1 number and 1 special character
export const validPasswordSpecialCharRegex =
  /^(?=.*\d)(?=.*[!@#$%^&*()_?,./\\|]).{6,}$/

// Accept dates in the format MM/DD/YYYY
export const validMedDateRegex =
  /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/

export const acceptedImgTypes = 'image/jpeg, image/jpg, image/png'

export const validateSIN = (sin: string | null): boolean => {
  if (!sin || sin?.charAt(0) === '0' || sin?.charAt(0) === '8') {
    return false
  }
  let sum = 0
  for (let i = 0; i < sin.length; i += 1) {
    const n = parseInt(sin.charAt(i), 10)
    if (i % 2 === 0) {
      sum += n
    } else {
      const x = 2 * n
      sum += x > 9 ? x - 9 : x
    }
  }
  return sum % 10 === 0
}

export const validateSSN = (ssn: string | null): boolean => {
  // Check if the SSN is 9 digits long
  if (!ssn || ssn.length !== 9) {
    return false
  }
  // Check if the SSN is in the correct format
  const regex = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/
  return regex.test(ssn)
}

function generateFormFields<K extends PropertyKey>(...keys: K[]): IFormFields {
  return Object.fromEntries(keys.map((k) => [k, ''])) as {
    [P in K]: string | RequiredStringSchema<string | undefined, AnyObject>
  }
}

interface IFormFields {
  firstName?: string | RequiredStringSchema<string | undefined, AnyObject>
  lastName?: string | RequiredStringSchema<string | undefined, AnyObject>
  email?: string | RequiredStringSchema<string | undefined, AnyObject>
  subject?: RequiredStringSchema<string | undefined, AnyObject>
  textarea?: RequiredStringSchema<string | undefined, AnyObject>
  password?: RequiredStringSchema<string | undefined, AnyObject>
  phoneNumberCognito?: RequiredStringSchema<string | undefined, AnyObject>
}

interface IExtraValidation {
  email?: {
    sameDomain?: boolean
  }
  subjectMaxLimit?: number
}

const extraValidationDefault = {
  subjectMaxLimit: 100,
}

export const basicUserInfoFormValidation = (
  i18n: any,
  fields: string[],
  extraValidation?: IExtraValidation
): IFormFields => {
  const subjectMaxLimit =
    extraValidation?.subjectMaxLimit ?? extraValidationDefault.subjectMaxLimit
  const formFields = {
    firstName: Yup.string()
      .max(50, i18n.t('common.form.firstName.maxError'))
      .required(i18n.t('common.form.firstName.requiredError'))
      .matches(
        validFirstNameRegex,
        i18n.t('common.form.firstName.matchesError')
      ),
    lastName: Yup.string()
      .max(100, i18n.t('common.form.lastName.maxError'))
      .required(i18n.t('common.form.lastName.requiredError'))
      .matches(validLastNameRegex, i18n.t('common.form.lastName.matchesError')),
    subject: Yup.string()
      .min(2, i18n.t('common.form.errors.minError', { count: 2 }))
      .max(
        subjectMaxLimit,
        i18n.t('common.form.errors.maxError', {
          count: subjectMaxLimit,
        })
      )
      .required(
        i18n.t('common.form.errors.requiredError', {
          label: i18n.t('common.form.subject.label').toLowerCase(),
        })
      )
      .matches(validTitleRegex, i18n.t('common.form.subject.matchesError')),
    textarea: Yup.string()
      .min(2, i18n.t('common.form.errors.minError', { count: 2 }))
      .required(
        i18n.t('common.form.errors.requiredError', {
          label: i18n.t('common.form.description.label').toLowerCase(),
        })
      ),
    email: Yup.string()
      .required(i18n.t('common.form.email.requiredError'))
      .test(
        'email-domain',
        i18n.t('common.form.email.sameDomain'),
        (value: string = '') => {
          return !extraValidation?.email?.sameDomain
            ? true
            : !value.includes('@') && extraValidation?.email?.sameDomain
        }
      ),
    password: Yup.string().required(i18n.t('common.form.password.error')),
    phoneNumberCognito: Yup.string()
      .required(i18n.t('common.form.phone.requiredError'))
      .min(12, i18n.t('common.form.phone.invalidError')),
  }

  const formFieldsFiltered = generateFormFields(...fields)

  fields.forEach((field: string) => {
    formFieldsFiltered[field as keyof IFormFields] =
      formFields[field as keyof IFormFields]
  })

  return {
    ...formFieldsFiltered,
  }
}

export const locationValidation = Yup.object()
  .shape({
    addressLine1: Yup.string().nullable(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    province: Yup.string().nullable(),
    postalCode: Yup.string().ensure().nullable(),
    countryCode: Yup.string().nullable(),
    country: Yup.string().nullable(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
  })
  .nullable()
