import { getClientPortalHost } from './get-client-portal-host'
import { getClientVendorCode } from './get-client-vendor-code'

export const setInitialVendorCode = () => {
  const clientPortalHost = getClientPortalHost()
  const vendorCode = getClientVendorCode(
    clientPortalHost || window.location.hostname
  )

  sessionStorage.setItem('VendorCode', vendorCode)
}
