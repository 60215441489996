import { SiteList } from '../config'
import { AppTypes, ClientNames, ClientQueryParams } from '../enums'
import { SuperSubject } from '../models'
import { IClientSettings } from '../models/clients-settings'
import { getClientPortalHost } from '../util'
import { getClientName } from '../util/get-client-name'

class ClientService {
  private clientSettingSubject = new SuperSubject<IClientSettings | null>(null)

  clientSetting$ = this.clientSettingSubject.observable$

  private clientNameSubject = new SuperSubject<string | null>(null)

  clientName$ = this.clientNameSubject.observable$

  private clientLoginTypeSubject = new SuperSubject<string | null>(null)

  clientLoginType$ = this.clientLoginTypeSubject.observable$

  private appTypeSubject = new SuperSubject<AppTypes | null>(null)

  appType$ = this.appTypeSubject.observable$

  private backDropLoadingSubject = new SuperSubject<boolean>(false)

  backDropLoading$ = this.backDropLoadingSubject.observable$

  set backDropLoading(v: boolean) {
    this.backDropLoadingSubject.value = v
  }

  resaveClientSettings(): void {
    // eslint-disable-next-line no-self-assign
    this.clientSettingSubject.value = this.clientSettingSubject.value
  }

  private updateFavicon(): void {
    const $favicons = document.querySelectorAll('.favicon')
    if (!$favicons) return
    const href = (value: string): string => {
      if (!this.clientSettingSubject.value) return value

      const placeholder =
        this.clientSettingSubject?.value.common.logo?.assetClient ??
        this.clientSettingSubject?.value.common.name

      return value.replace('placeholder', placeholder)
    }
    $favicons.forEach(($favicon) => {
      $favicon.setAttribute('href', href($favicon.getAttribute('href') ?? ''))
    })
  }

  getSettings(loginType: AppTypes): void {
    const clientName = (): string | null => {
      this.appTypeSubject.value = loginType
      const clientPortalBase = getClientPortalHost()
      if (loginType === AppTypes.auth) {
        return clientPortalBase
          ? getClientName(clientPortalBase)
          : ClientNames.Procom
      }
      return getClientName(window.location.hostname)
    }
    this.clientNameSubject.value = clientName()

    // Temporary way in switching profile view for sign up page
    const clientLoginType = (): string | null => {
      if (this.clientLoginTypeSubject.value) {
        return this.clientLoginTypeSubject.value
      }
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      return urlParams.get(ClientQueryParams.loginType)
    }

    this.clientLoginTypeSubject.value = clientLoginType()

    SiteList.find((portal) => {
      if (portal.name === this.clientNameSubject.value) {
        this.clientSettingSubject.value = portal.settings
        this.updateFavicon()
        return true
      }
      return false
    })
  }
}

export const clientService = new ClientService()
