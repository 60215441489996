import React, { createContext, useContext } from 'react'

import {
  AppService,
  AuthService,
  AvatarService,
  CandidateCartService,
  ChatService,
  HideNavbarService,
  InterviewCopilotService,
  JobAiService,
  JobService,
  MfaService,
  UtilsService,
} from '../services'

export interface Services {
  cartService: CandidateCartService
  clientJobService: JobService
  chatService: ChatService
  hideNavbarService: HideNavbarService
  jobAiService: JobAiService
  authService: AuthService
  avatarCollaboratorService: AvatarService
  mfaService: MfaService
  interviewCopilotService: InterviewCopilotService
  utilsService: UtilsService
  appService: AppService
}

const ServicesContext = createContext<Services>({} as Services)

export const ServicesProvider: React.FC<{ services: Services }> = ({
  children,
  services,
}) => {
  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  )
}

export const useServices = (): Services => {
  return useContext(ServicesContext)
}
