export enum gtmEvents {
  AccountManagerMessage = 'account-manager-message',
  AddInterviewNotes = 'add-interview-notes',
  CandidateExtendOffer = 'candidate-extend-offer',
  CandidateViewModal = 'candidate-view-modal',
  CandidateViewThumbUp = 'candidate-view-thumb-up',
  CandidateViewThumbDown = 'candidate-view-thumb-down',
  CandidateViewViewProfile = 'candidate-view-view-profile',
  ClickEmployerCandidate = 'click-employer-candidate',
  ClickEmployerJob = 'click-employer-job',
  ClickEmployerJobCandidate = `click-employer-job-candidate`,
  ClickJobCard = 'click-job-card',
  ClientPortalJobDescriptionAI = 'client-portal-job-description-ai',
  ClientPortalCreateNewJob = 'client-portal-create-new-job',
  ClientPortalRequestTalentList = 'client-portal-request-talent-list',
  deleteTalentPool = 'delete-talent-pool',
  FormBasicUserInfo = 'form-basic-user-info',
  FormCandidateDetail = 'form-candidate-detail',
  FormCandidateNotesDelete = 'form-candidate-notes-delete',
  FormCandidateSearch = 'form-candidates-search',
  FormChangePassword = 'form-change-password',
  FormCommunicationPreference = 'form-communication-preference',
  FormCreatePassword = 'form-create-password',
  FormCreateJob = 'form-create-job',
  FormDeleteAccount = 'form-delete-account',
  FormEditJob = 'form-edit-job',
  FormPublishJob = 'form-publish-job',
  FormEmployerJobAddCandidateBullhorn = 'form-employer-job-add-candidate-bullhorn',
  FormEmployerJobAddCandidateManual = 'form-employer-job-add-candidate-manual',
  FormEmployerJobCandidateExists = 'form-employer-job-add-candidate-exists',
  FormEmployerJobCandidateMultiple = 'form-employer-job-add-candidate-multiple-jobs',
  FormEmployerJobs = 'form-employer-jobs',
  FormExpenses = 'form-expenses',
  FormJobActivityCandidates = 'form-job-activity-candidates',
  FormJobExperience = `form-job-experience`,
  FormJobSearch = 'form-job-search',
  FormJobSubmit = 'form-job-submit',
  FormJobsRequiringAttention = 'form-jobs-requiring-attention',
  FormLogin = 'form-login',
  FormMsSSOLogin = 'form-ms-sso-login',
  FormPaymentPaid = 'form-payment-paid',
  FormPaymentPending = 'form-payment-pending',
  FormPaymentReports = 'form-payment-reports',
  FormProfileDetails = 'form-profile-details',
  FormResetPassword = 'form-reset-password',
  FormSearchBullhornCandidate = 'form-search-bullhorn-candidate',
  FormSearchManualCandidate = 'form-search-manual-candidate',
  FormSearchTalentPools = 'form-search-talent-pools',
  FormSignUp = 'form-signup',
  FormSignUpEmail = 'form-signup-email',
  FormTimesheets = 'form-timesheets',
  FormUploadAvatar = 'form-upload-avatar',
  FormWorkOrders = 'form-work-orders',
  FormWorkPreference = `form-work-preference`,
  InitialLogin = 'initial-login',
  ContractorSignup = 'contractor-signup',
  InviteCollaborator = 'invite-collaborator',
  InterviewSendRequest = 'interview-send-request',
  ImportTalentPool = 'import-talent-pool',
  ViewTalentPool = 'view-talent-pool',
  GenerateAiJobDescription = 'generate-ai-job-description',
  GenerateAiCandidateHighlights = 'generate-ai-candidate-highlights',
  GenerateAiResumeTips = 'generate-ai-resume-tips',
  ViewCandidates = 'view-candidates',
  OpenReloadingDialogForNewBuildNumber = 'open-reloading-dialog-for-new-build-number',
  PostponeReceivingNewBuildNumber = 'postpone-receiving-new-build-number',
  CallingNewBuildNumberApi = 'calling-new-build-number-api',
  ViewDocument = 'view-document',
  InitializingMsSsoLogin = 'initializing-ms-sso-login',
}

export enum gtmEventsPrefix {
  Notification = 'notification-', // When a form submit fails
}

export enum GtmFormSubmissionStatus {
  success = 'true',
  failed = 'false',
}
