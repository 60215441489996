import React, { useEffect } from 'react'
import { Provider } from '@rollbar/react'
import { useLocation } from 'react-router-dom'
import { RollbarProviderProps } from '@procom-labs/common'
import Rollbar, { Configuration } from 'rollbar'
import { environment } from '../environment'

export const rollbarConfig: Configuration = {
  ...environment.rollbar,
  enabled: environment.rollbar.enabled === 'true',
}

export const rollbarInstance = new Rollbar(rollbarConfig)

export const RollbarProvider: React.FC<RollbarProviderProps> = ({
  children,
}) => {
  const location = useLocation()

  useEffect(() => {
    rollbarInstance.configure({
      payload: {
        context: location.pathname,
      },
    })
  }, [location])

  return <Provider instance={rollbarInstance}>{children}</Provider>
}
