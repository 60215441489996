import Axios from 'axios-observable'
import { interval, map, Observable, of, switchMap } from 'rxjs'
import { catchError } from 'rxjs/operators'

interface AppServiceConfig {
  axios: Axios
}

export class AppService {
  private axios: Axios

  constructor(config: AppServiceConfig) {
    this.axios = config.axios
  }

  getAppVersion(
    intervalTime: number,
    trackError?: () => void
  ): Observable<string | null> {
    const url = `app-metadata.json`

    const appVersionObservable = interval(intervalTime).pipe(
      switchMap(() =>
        this.axios
          .get(url, {
            baseURL: window.location.origin,
          })
          .pipe(
            map((response) => (response.data?.buildNumber as string) || null),
            catchError(() => {
              if (trackError) trackError()
              return of(null)
            })
          )
      )
    )
    return appVersionObservable
  }
}
