import React, { ReactElement } from 'react'

import { BaseLayout, CompleteRegistrationForm } from '@auth-portal/components'

const CompleteRegistration: React.FC = () =>
  (
    <BaseLayout>
      <CompleteRegistrationForm />
    </BaseLayout>
  ) as ReactElement

export default CompleteRegistration
