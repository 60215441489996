import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { Box, Button, Stack, Typography } from '@mui/material'
import { Preloader } from '@procom-labs/atoms'
import {
  ClientQueryParams,
  LoginTypes,
  ProtectedLinksEnum,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import VerifySVG from '@auth-portal/assets/verify-email.svg'
import { Logo } from '@auth-portal/components/logo'
import { authService } from '@auth-portal/services'
import { LogoSizeOptions } from '@auth-portal/types'

type initialState = {
  state: {
    token: string | null
  }
}

export const VerifyEmail: React.FC<{}> = () => {
  const { t } = useTranslation('main')
  const [searchParams] = useSearchParams()

  const { state } = useLocation() as initialState
  const [message, setMessage] = useState(String)
  const { addAlert } = useAlert()

  const emailParam = searchParams.get('email')
  const codeParam = searchParams.get('code')
  const loginType = searchParams.get(ClientQueryParams.loginType)
  const subscriptionRef = useRef<Subscription | null>()

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (loginType) {
      localStorage.setItem(ClientQueryParams.loginType, loginType)
      authService.setLoginType()
    }

    if (emailParam && codeParam) {
      authService
        .verifyEmail({ email: emailParam, confirmationCode: codeParam })
        .subscribe({
          complete() {
            setMessage(t('form.verifyEmail.verified'))
            navigate(
              `/?${new URLSearchParams({
                emailVerified: 'true',
                loginType: loginType || LoginTypes.Client,
              })}`,
              { replace: true }
            )
          },
          error() {
            setMessage(t('form.verifyEmail.verificationFailed'))
          },
        })
    }
  })

  const handleSendVerification = useCallback(() => {
    if (state.token) {
      setIsLoading(true)
      subscriptionRef.current = authService
        .resendEmailVerification(
          ProtectedLinksEnum.Dashboard,
          LoginTypes.Client,
          true,
          state.token
        )
        .subscribe({
          complete: () => {
            setIsLoading(false)
            addAlert({
              severity: 'success',
              message: t('form.login.verifyEmail.alert'),
            })
          },
          error: () => {
            setIsLoading(false)
            addAlert({
              message: t('common.alert.somethingWrong'),
              severity: 'error',
              horizontal: 'left',
            })
          },
        })
    }
  }, [addAlert, state.token, t])

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  if (!codeParam)
    return (
      <Stack gap={3} px={3}>
        <Logo sx={{ marginBottom: 0.75 }} size={LogoSizeOptions.Large} />
        <Typography variant="h6" textAlign="center">
          {t('form.login.verifyEmail.title')}
        </Typography>
        <Box display="flex" justifyContent="center">
          <img src={VerifySVG} alt="verify email" />
        </Box>
        <Typography variant="body2">
          {t('form.login.verifyEmail.text1')} <strong>{emailParam}</strong>.{' '}
          {t('form.login.verifyEmail.text2')}
        </Typography>
        <Button
          variant="contained"
          onClick={handleSendVerification}
          fullWidth
          disabled={isLoading}
        >
          {t('form.login.verifyEmail.resendBtn')}
        </Button>
        <Button href="mailto:support@procomlabs.io" target="_blank" fullWidth>
          {t('form.login.verifyEmail.contactBtn')}
        </Button>
      </Stack>
    )

  if (!message) return <Preloader center />

  return (
    <div>
      <p>{message}</p>
    </div>
  )
}
