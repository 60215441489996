export class LocalStorage<T> {
  private readonly storageKey: string

  constructor(storageKey: string) {
    this.storageKey = storageKey
  }

  get(checkExpiry: boolean = false): T | null {
    const rawValue = localStorage.getItem(this.storageKey)
    if (!rawValue) return null

    const value = JSON.parse(rawValue) as T

    if (checkExpiry && this.isExpired(value)) return null

    return value
  }

  set(value: T): void {
    localStorage.setItem(this.storageKey, JSON.stringify(value))
  }

  clear(): void {
    localStorage.removeItem(this.storageKey)
  }

  private isExpired(v: any): boolean {
    if (!v || !v.expiredTime) return false

    return new Date(v.expiredTime).getTime() < new Date().getTime()
  }
}

export class SessionStorage<T> {
  private readonly storageKey: string

  constructor(storageKey: string) {
    this.storageKey = storageKey
  }

  get(checkExpiry: boolean = false): T | null {
    const rawValue = sessionStorage.getItem(this.storageKey)
    if (!rawValue) return null

    const value = JSON.parse(rawValue) as T

    if (checkExpiry && this.isExpired(value)) return null

    return value
  }

  set(value: T): void {
    sessionStorage.setItem(this.storageKey, JSON.stringify(value))
  }

  clear(): void {
    sessionStorage.removeItem(this.storageKey)
  }

  private isExpired(v: any): boolean {
    if (!v || !v.expiredTime) return false

    return new Date(v.expiredTime).getTime() < new Date().getTime()
  }
}
