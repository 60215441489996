import { useCallback } from 'react'

import { useTrackingEvent } from '@procom-labs/common'

import { environment } from '@auth-portal/environment'

interface ITrackProps {
  isGTMTracking?: boolean
  isMixPanelTracking?: boolean
  eventName: string
  [key: string]: any
}

interface IUseTrackingWrapper {
  track: ({ isGTMTracking, isMixPanelTracking, ...rest }: ITrackProps) => void
}

export const useTrackingWrapper = (): IUseTrackingWrapper => {
  const isTrackingEnabled = environment.ENABLE_DATA_TRACKING === 'true'
  const { trackData } = useTrackingEvent()

  const track = useCallback(
    ({
      isGTMTracking = true,
      isMixPanelTracking = false,
      ...rest
    }: ITrackProps) => {
      trackData({
        isGTMTracking,
        isMixPanelTracking,
        isTrackingEnabled,
        ...rest,
      })
    },
    [isTrackingEnabled, trackData]
  )
  return {
    track,
  }
}
