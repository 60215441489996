import React, { Dispatch, SetStateAction } from 'react'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import * as Yup from 'yup'

interface ValidationType {
  transKey?: TFuncKey
  valid: boolean
}

const passwordValidations: { [key: string]: TFuncKey } = {
  Min6Characters: 'form.passwordValidation.min6Characters',
  Min1number: 'form.passwordValidation.min1Number',
  Min1CapitalLetter: 'form.passwordValidation.min1CapitalLetter',
  Min1LowerCaseLetter: 'form.passwordValidation.min1LowerCaseLetter',
  PasswordMatch: 'form.passwordValidation.passwordMatch',
}

export const validatePassword = async (
  values: any,
  passwordValidation: ValidationType[],
  setPasswordValidation: Dispatch<SetStateAction<ValidationType[]>>
): Promise<void> => {
  const validation = [...passwordValidation]

  const validationMap: Partial<
    Record<TFuncKey, (password: string) => Promise<boolean>>
  > = {
    [passwordValidations.Min6Characters]: (password: string) =>
      Yup.string().min(6).isValid(password),
    [passwordValidations.Min1number]: (password: string) =>
      Yup.string().matches(/\d+/).isValid(password),
    [passwordValidations.Min1CapitalLetter]: (password: string) =>
      Yup.string()
        .matches(/[A-Z]+/)
        .isValid(password),
    [passwordValidations.Min1LowerCaseLetter]: (password: string) =>
      Yup.string()
        .matches(/[a-z]+/)
        .isValid(password),
    [passwordValidations.PasswordMatch]: (password: string) =>
      Yup.string()
        .oneOf([password])
        .isValid(password ? values.confirmPassword : null),
  }

  validation.forEach((item, index) => {
    const validationFunction = item.transKey
      ? validationMap[item.transKey]
      : () => Promise.resolve(false)
    if (validationFunction) {
      validationFunction(values.password).then((result: boolean) => {
        validation[index].valid = result
      })
    }
  })

  setPasswordValidation(validation)
}

export const RenderCheckbox: React.FC<{ validation: any }> = ({
  validation,
}) => {
  const { t } = useTranslation('main')
  const control = (
    <Checkbox
      icon={<CheckCircleOutlineIcon />}
      checkedIcon={<CheckCircleIcon />}
      checked={validation.valid}
      disableRipple={!validation.valid}
      color="success"
    />
  )

  return (
    <FormControlLabel
      key={validation.transKey}
      control={control}
      label={
        <Typography variant="body1" component="span">
          {t(validation.transKey)}
        </Typography>
      }
    />
  )
}
