import { IMFADetails, MfaFlows, MfaTypes } from '@common/models'

import { BaseSubjectStore } from '../subject-store'

interface MfaStoreState {
  isUserMfaSettingsUpdated: boolean
  userMfaDetails: IMFADetails[] | null
  userMfaPhoneNumber: string | null
  mfaFlow: MfaFlows | null
  mfaType: MfaTypes | null
  preferredMfaMethod: MfaTypes | null
  isCognitoUserValid: boolean
  showAuthenticatorAppDescription: boolean
  showMfaPhoneAlert: boolean
}

export class MfaStore extends BaseSubjectStore<MfaStoreState> {
  getDefaultValue(): Required<MfaStoreState> {
    return {
      isUserMfaSettingsUpdated: false,
      userMfaDetails: null,
      userMfaPhoneNumber: null,
      mfaFlow: null,
      mfaType: null,
      preferredMfaMethod: null,
      isCognitoUserValid: false,
      showAuthenticatorAppDescription: false,
      showMfaPhoneAlert: false,
    }
  }

  get userMfaDetails(): IMFADetails[] | null {
    return this.state.userMfaDetails.value
  }

  get mfaType(): MfaTypes | null {
    return this.state.mfaType.value
  }

  mfaSettingsReset(): void {
    this.dispatch({
      isUserMfaSettingsUpdated: false,
      mfaFlow: null,
      mfaType: null,
      isCognitoUserValid: false,
      showAuthenticatorAppDescription: false,
    })
  }

  resetMfaType(): void {
    this.dispatch({
      mfaType: null,
    })
  }
}
