import { FC, memo } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { SocialLoginType } from '@procom-labs/common'

import { environment } from '@auth-portal/environment'

interface IMicrosoftLoginButtonProps {
  onClick: () => void
}

const loginButtonText = 'Microsoft 365'

export const MicrosoftLoginButton: FC<IMicrosoftLoginButtonProps> = memo(
  ({ onClick }) => (
    <Stack
      direction="row"
      spacing={1.5}
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      mb={2}
      onClick={onClick}
      aria-label="Microsoft 365 Login"
    >
      <Box
        component="img"
        alt={SocialLoginType.Microsoft}
        src={`${
          environment.BLOB_STORAGE_URL
        }/procom-portal-assets/icons/${SocialLoginType.Microsoft.toLocaleLowerCase()}.png`}
      />
      <Typography component="p" variant="subtitle1" ml={1}>
        {loginButtonText}
      </Typography>
    </Stack>
  )
)
