export const toKebabCase = (str: string): string => {
  const regex =
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  const arr: any = str ? str.match(regex) : []

  return arr.map((x: string) => x.toLowerCase()).join('-')
}

export const toCamelCase = (str: string): string =>
  (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => cur + acc[0].toUpperCase() + acc.substring(1))

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const toPascalCase = (str: string): string =>
  capitalize(toCamelCase(str))

export const splitCamelOrPascalCase = (str: string): string => {
  const camelCase = str.charAt(0).toUpperCase() + str.slice(1)
  return camelCase.replace(/([0-9A-Z])/g, ' $&')
}

export const capitalizeWords = (str: string): string => {
  const arr = (str && str.split(' ')) || []
  const capitalizedArray = arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
  })
  return capitalizedArray.join(' ')
}

export const capitalizeWord = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const convertKeysToCamelCase = <T extends Record<string, any>>(
  obj: T
): Record<string, any> =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc[toCamelCase(key)] = value
    return acc
  }, {} as Record<string, any>)

export const convertArrayKeysToCamelCase = <T extends Record<string, any>>(
  array: T[]
): Record<string, any>[] => array.map(convertKeysToCamelCase)
