import React, { FC } from 'react'
import MaskedInput from 'react-text-mask'

type PhoneInputProps = {
  maskArray?: any
}

const defaultMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const PhoneInput: FC<PhoneInputProps> = React.forwardRef(
  ({ maskArray, ...inputProps }: PhoneInputProps, inputRef: any) => {
    return (
      <MaskedInput
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={maskArray || defaultMask}
        {...inputProps}
      />
    )
  }
)
