import { FC, Suspense, useEffect, useMemo } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter, useSearchParams } from 'react-router-dom'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { InputGlobalStyles, Preloader } from '@procom-labs/atoms'
import {
  AppTypes,
  ClientQueryParams,
  getClientPortalHost,
  PaletteModeCode,
  ServicesProvider,
  setInitialVendorCode,
  themeService,
} from '@procom-labs/common'

import { RollbarProvider } from './providers'
import { AppRoutes } from './routes'

import 'external-svg-loader'
import './style/main.scss'

import { services } from './config/services-config'
import { environment } from './environment'
import { MSALProvider } from './providers/msal.provider'

const AppThemeProvider: FC<{}> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const queryThemeMode = searchParams.get(ClientQueryParams.themeMode)
  const localThemeMode = localStorage.getItem(ClientQueryParams.themeMode)

  const themeMode = queryThemeMode ?? localThemeMode

  const appTheme = useMemo(() => {
    return themeService.initializeBaseTheme(
      getClientPortalHost(),
      AppTypes.auth,
      !!themeMode,
      themeMode as PaletteModeCode
    )
  }, [themeMode])

  useEffect(() => {
    if (queryThemeMode) {
      localStorage.setItem(ClientQueryParams.themeMode, queryThemeMode)
    }
  }, [queryThemeMode])

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
}

const App: FC<{}> = () => {
  setInitialVendorCode()
  return (
    <GoogleReCaptchaProvider reCaptchaKey={environment.GOOGLE_RECAPTCHA_KEY}>
      <BrowserRouter>
        <AppThemeProvider>
          <Suspense fallback={<Preloader center />}>
            <CssBaseline />
            {InputGlobalStyles}
            <RollbarProvider>
              <ServicesProvider services={services as any}>
                <MSALProvider>
                  <AppRoutes />
                </MSALProvider>
              </ServicesProvider>
            </RollbarProvider>
          </Suspense>
        </AppThemeProvider>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  )
}

export default App
