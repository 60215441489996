import React, { useCallback, useState } from 'react'
import { Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { from } from 'rxjs'

import { Box, Button, Divider, Link, Typography } from '@mui/material'
import { AlertMessage } from '@procom-labs/atoms'
import { EmailSupportLinks, RecaptchaActions } from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { DigitalCodeInput } from '@auth-portal/components/digital-code-input'
import { useRecaptcha } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'

interface FormValues {
  verificationCode: string
}

const defaultValues: FormValues = {
  verificationCode: '',
}

const MAX_LOGIN_ATTEMPTS = 1

export const VerifyDigitalCode: React.FC<{ email: string }> = ({ email }) => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const { getRecaptchaToken } = useRecaptcha()

  const [initialValues] = useState(defaultValues)
  const [otp, setOtp] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const handleGenerateOtp = useCallback(
    (code: string) => {
      from(getRecaptchaToken(RecaptchaActions.CodeSignIn)).subscribe({
        next: (token) => {
          authService.handleOtpSignIn(code, email, token).subscribe({
            next: () => {
              authService.redirectToClientPortal({})
            },
          })
        },
      })
    },
    [authService, email]
  )

  const handleVerifyCode = useCallback(
    (code: string) => {
      setIsVerifying(true)
      setOtp(code)
      from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).subscribe({
        next: (token) => {
          authService.validateOtpSignIn(email, code, token).subscribe({
            next: (response) => {
              if (response.hasCodeExpired) {
                addAlert({
                  severity: 'error',
                  message: t('form.alerts.codeExpired'),
                })
              } else if (response.hasReachedMaxAttempts) {
                addAlert({
                  severity: 'error',
                  message: t('form.alerts.maxAttempts', {
                    hour: MAX_LOGIN_ATTEMPTS,
                  }),
                })
              } else if (response.isCodeValid) {
                handleGenerateOtp(code)
              } else {
                addAlert({
                  severity: 'error',
                  message: t('form.alerts.invalidCode'),
                })
              }
              setIsVerifying(false)
            },
            error: (error) => {
              setIsVerifying(false)
              addAlert({
                severity: 'error',
                message: error.message,
              })
            },
          })
        },
      })
    },
    [authService, handleGenerateOtp, setOtp]
  )

  const handleSubmit = useCallback(() => {
    handleVerifyCode(otp)
  }, [otp, handleVerifyCode])

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ textAlign: 'center', marginBlockEnd: 2 }}
      >
        {t('organisms.mfaLoginCodeVerification.heading')}
      </Typography>

      <Typography
        variant="body2"
        sx={{ textAlign: 'center', marginBlockEnd: 2 }}
      >
        <Trans
          i18nKey="organisms.mfaLoginCodeVerification.subHeading"
          values={{ email: email }}
        />
      </Typography>

      <AlertMessage severity="info" sx={{ marginBlockEnd: 4 }}>
        {t(`form.login.alerts.verifyDigitalCode`)}
      </AlertMessage>
      <Divider sx={{ marginBlockEnd: 4, width: '100%' }} />

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form className="form-root">
            <DigitalCodeInput
              isVerifying={isVerifying}
              handleVerifyCode={handleVerifyCode}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginBlockEnd: 2, marginTop: 7 }}
              id="cta-mfa-verify-code"
            >
              {t('common.btn.verify')}
            </Button>
            <Link
              component={RouterLink}
              underline="none"
              to="/"
              sx={{ mt: 3, mb: 5 }}
            >
              <Typography
                variant="body1Bold700"
                lineHeight={1.5}
                component="span"
              >
                {t('form.reset.backLoginBtn')}
              </Typography>
            </Link>
          </Form>
        )}
      </Formik>
      <Box mt={3} sx={{ textAlign: 'center', marginBlockEnd: 6 }}>
        <Typography variant="body2" sx={{ marginBlockEnd: 2 }}>
          {t('organisms.mfaLoginCodeVerification.havingTrouble')}
          <Link href={`mailto:${EmailSupportLinks.ClientConnections}`}>
            {t('organisms.mfaLoginCodeVerification.contactUs')}
          </Link>
        </Typography>
      </Box>
    </>
  )
}
