import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'

import { Grid } from '@mui/material'
import {
  clientService,
  IClientSettings,
  IPrivacyPolicyURL,
  PageTypes,
} from '@procom-labs/common'
import {
  Footer,
  PrivacyPolicyPage as PrivacyPolicyDetail,
} from '@procom-labs/organisms'

import { LegalDocumentLayout } from '@auth-portal/components'
import { environment } from '@auth-portal/environment'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'

const PrivacyPolicyPage: React.FC = () => {
  const navigate = useNavigate()

  const { i18n, t } = useTranslation('main')

  const localLng = i18n.language?.toLowerCase()

  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$
  )

  useGtmVpv({
    pageType: PageTypes.PrivacyPolicy,
    pageTitle: PageTypes.PrivacyPolicy,
  })

  if (clientSettings?.common.privacyPolicyURL && localLng) {
    window.location.href =
      clientSettings.common.privacyPolicyURL[
        localLng as keyof IPrivacyPolicyURL
      ]
    return null
  }
  return (
    <LegalDocumentLayout
      title={t('common.inputs.termsAndPolicy.privacy')}
      subTitle={
        clientSettings?.common?.effectiveDate
          ? t('common.termsAndPolicy.updated', {
              effectiveDate: clientSettings?.common.effectiveDate,
            })
          : undefined
      }
    >
      <Grid
        container
        sx={{ paddingBottom: 5, px: { xs: 1, sm: 2, md: 4, lg: 6 } }}
      >
        <PrivacyPolicyDetail handleBack={(): void => navigate(`/`)} />
      </Grid>
      <Footer environment={environment} />
    </LegalDocumentLayout>
  ) as ReactElement
}

export default PrivacyPolicyPage
