import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export interface IUseGtmVpv {
  pageType: string
  pageTitle: string
  cancelEvent?: boolean
  pageURISuffix?: string
}

// Send Google Tag Manager Virtual Page View
export const useGtmVpv = ({
  pageType,
  pageTitle,
  pageURISuffix,
  cancelEvent = false,
}: IUseGtmVpv): void => {
  const sendDataToGTM = useGTMDispatch()
  const { i18n } = useTranslation('main')

  useEffect(() => {
    const suffixString = pageURISuffix ? `/${pageURISuffix}` : ''
    if (!cancelEvent)
      sendDataToGTM({
        event: 'VPV',
        pageURL: `${window.location.origin}${window.location.pathname}${suffixString}`,
        pageDOM: window.location.origin,
        pageURI: `${window.location.pathname}${suffixString}`,
        pageType,
        pageTitle,
        language: i18n.language,
      })
  }, [
    cancelEvent,
    i18n.language,
    pageTitle,
    pageType,
    pageURISuffix,
    sendDataToGTM,
  ])
}

export const useGtmVpvCallback = (): {
  sendToGtmVpv: ({ pageType, pageTitle }: IUseGtmVpv) => void
} => {
  const sendDataToGTM = useGTMDispatch()
  const { i18n } = useTranslation('main')

  const sendToGtmVpv = useCallback(
    ({ pageType, pageTitle, pageURISuffix }: IUseGtmVpv) => {
      const suffixString = pageURISuffix ? `/${pageURISuffix}` : ''
      sendDataToGTM({
        event: 'VPV',
        pageURL: `${window.location.origin}${window.location.pathname}${suffixString}`,
        pageDOM: window.location.origin,
        pageURI: `${window.location.pathname}${suffixString}`,
        pageType,
        pageTitle,
        language: i18n.language,
      })
    },
    [i18n.language, sendDataToGTM]
  )

  return { sendToGtmVpv }
}
