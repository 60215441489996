import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTrackingWrapper } from './use-tracking-wrapper'

interface IUseGtmVpv {
  pageType: string
  pageTitle: string
}

// Send Google Tag Manager Virtual Page View
export const useGtmVpv = ({ pageType, pageTitle }: IUseGtmVpv): void => {
  const { track } = useTrackingWrapper()
  const { i18n } = useTranslation('main')

  useEffect(() => {
    track({
      eventName: 'VPV',
      pageURL: window.location.origin + window.location.pathname,
      pageDOM: window.location.origin,
      pageURI: window.location.pathname,
      pageType,
      pageTitle,
      language: i18n.language,
    })
  }, [i18n.language, pageTitle, pageType, track])
}

export const useGtmVpvCallback = (): {
  sendToGtmVpv: ({ pageType, pageTitle }: IUseGtmVpv) => void
} => {
  const { track } = useTrackingWrapper()
  const { i18n } = useTranslation('main')

  const sendToGtmVpv = useCallback(
    ({ pageType, pageTitle }: IUseGtmVpv) => {
      track({
        eventName: 'VPV',
        pageURL: window.location.origin + window.location.pathname,
        pageDOM: window.location.origin,
        pageURI: window.location.pathname,
        pageType,
        pageTitle,
        language: i18n.language,
      })
    },
    [i18n.language, track]
  )

  return { sendToGtmVpv }
}
