import { useCallback, useEffect, useRef, useState } from 'react'

export const useCounter = (): {
  initCounter: ({
    count,
    interval,
  }: {
    count?: number
    interval?: number
  }) => void
  counter: number
  resetCounter: () => void
} => {
  const counterTimeout = useRef<ReturnType<typeof setTimeout>>()
  const [counter, setCounter] = useState<number>(0)

  const initCounter = useCallback(({ count = 30, interval = 1000 }) => {
    setCounter(count)
    counterTimeout.current = setInterval(() => {
      setCounter((val) => val - 1)
    }, interval)
  }, [])

  const resetCounter = useCallback(() => {
    setCounter(0)
    clearInterval(counterTimeout.current)
    counterTimeout.current = undefined
  }, [])

  useEffect(() => {
    if (!counter && counterTimeout.current) {
      resetCounter()
    }
  }, [counter, resetCounter])

  useEffect(() => {
    return () => {
      if (counterTimeout.current) {
        resetCounter()
      }
    }
  }, [resetCounter])

  return { initCounter, counter, resetCounter }
}
