import { ThemeOptions } from '@mui/material'
import { Theme } from '@mui/material/styles/createTheme'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import { IClientSettings, SuperSubject } from '../models'
import {
  baseThemeConfig,
  getDarkThemeConfig,
  getLightThemeConfig,
  SiteList,
} from '../config'
import { AppTypes, ClientNames, PaletteModeCode, VendorCodes } from '../enums'
import { deepMergeObjects, getClientName, getClientVendorCode } from '../util'
import { Observable } from 'rxjs'

export interface ThemeConfig {
  key: string
  config: Partial<ThemeOptions>
}

class ThemeService {
  private baseThemeModeSubject = new SuperSubject<PaletteModeCode>(
    PaletteModeCode.dark
  )

  baseThemeMode$: Observable<PaletteModeCode> =
    this.baseThemeModeSubject.observable$

  private getThemeConfig: (paletteModeCode: PaletteModeCode) => ThemeOptions =
    getDarkThemeConfig

  private themeMap: { [mode: string]: Theme } = {}

  private get baseTheme(): Theme {
    return this.themeMap[this.baseThemeModeSubject.value]
  }

  private set baseTheme(theme: Theme) {
    this.themeMap[this.baseThemeModeSubject.value] = theme
  }

  getOrCreateTheme(
    mode: PaletteModeCode,
    clientSettings: IClientSettings,
    forceCreate?: boolean,
    themeConfig?: ThemeConfig
  ): Theme {
    const themeKey = themeConfig?.key ? `${mode}-${themeConfig?.key}` : mode

    if (this.themeMap[themeKey] && !forceCreate) {
      return this.themeMap[themeKey]
    }

    const themeOptions = this.getThemeConfig(mode)

    const theme = createTheme(
      this.baseTheme,
      themeConfig?.key
        ? deepMergeObjects(themeOptions, themeConfig.config)
        : themeOptions
    )

    this.themeMap[themeKey] = theme

    return theme
  }

  initializeBaseTheme(
    clientPortalBase: string | null,
    appType: AppTypes,
    isAuthenticated?: boolean,
    userThemeMode?: PaletteModeCode | null,
    appThemeConfig?: Partial<ThemeOptions>
  ): Theme {
    this.themeMap = {}
    // Override color palette if domain is not Procom
    let clientThemeConfig = null
    const clientName = clientPortalBase
      ? getClientName(clientPortalBase)
      : ClientNames.Procom

    const clientVendorCode = clientPortalBase
      ? getClientVendorCode(clientPortalBase)
      : VendorCodes.PCGL

    for (const portal of SiteList) {
      if (
        portal.name === clientName &&
        portal.vendorCode === clientVendorCode
      ) {
        let themeMode
        if (isAuthenticated) {
          themeMode =
            userThemeMode ?? portal.settings.defaultThemeMode?.authenticated
        } else if (appType === AppTypes.auth) {
          themeMode = portal.settings.defaultThemeMode?.guestAuth
        } else if (appType === AppTypes.client) {
          themeMode = portal.settings.defaultThemeMode?.guestClient
        }

        this.baseThemeModeSubject.value = themeMode ?? PaletteModeCode.dark

        this.getThemeConfig =
          this.baseThemeModeSubject.value === PaletteModeCode.light
            ? getLightThemeConfig
            : getDarkThemeConfig

        clientThemeConfig = deepMergeObjects(
          baseThemeConfig,
          this.getThemeConfig(this.baseThemeModeSubject.value)
        )
        break
      }
    }
    clientThemeConfig = clientThemeConfig || baseThemeConfig
    clientThemeConfig = appThemeConfig
      ? deepMergeObjects(clientThemeConfig, appThemeConfig)
      : clientThemeConfig

    const theme = responsiveFontSizes(createTheme(clientThemeConfig))

    // Based on https://mui.com/material-ui/customization/typography/#responsive-font-sizes
    theme.typography.body1.fontSize = '1.6rem'
    delete theme.typography.body1[theme.breakpoints.up('sm')]
    delete theme.typography.body1[theme.breakpoints.up('md')]
    delete theme.typography.body1[theme.breakpoints.up('lg')]

    theme.typography.body2.fontSize = '1.4rem'
    delete theme.typography.body2[theme.breakpoints.up('sm')]
    delete theme.typography.body2[theme.breakpoints.up('md')]
    delete theme.typography.body2[theme.breakpoints.up('lg')]

    theme.typography.h6.fontSize = '1.6rem'

    this.baseTheme = theme

    return theme
  }
}

export const themeService = new ThemeService()
