import React from 'react'
import { Trans } from 'react-i18next'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Typography } from '@mui/material'

export const ClientPortalBanner: React.FC<{}> = ({ children }) => {
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box flexWrap="nowrap" pl={desktopView ? 20 : 0}>
      <Typography
        variant="h2Bold800"
        textAlign={desktopView ? 'left' : 'center'}
      >
        <Trans i18nKey="clientsSettings.procom.bannerText.client.description" />
      </Typography>

      {children}
    </Box>
  )
}
