import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { IconButton as MuiIconButton, SxProps, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const CloseIconButton = styled(
  MuiIconButton,
  {}
)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.action.active,
}))

export const ModalCloseButton: React.FC<{
  handleClose: () => void
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
  id?: string
}> = ({ handleClose, sx, iconSx, id }) => {
  const { t } = useTranslation('main')

  return (
    <CloseIconButton
      aria-label={t('common.aria.close')}
      onClick={handleClose}
      sx={{ ...sx }}
      id={id}
    >
      <CloseIcon sx={{ fontSize: 24, ...iconSx }} />
    </CloseIconButton>
  )
}
