import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { combineLatest, EMPTY, from, of, Subscription, switchMap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MuiLink from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { GorillaAlert } from '@procom-labs/atoms'
import {
  ClientNames,
  clientService,
  CompanyNames,
  errorToString,
  gtmEvents,
  gtmEventsPrefix,
  HTTPResponseStatus,
  IClientSettings,
  RecaptchaActions,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'

import { rollbarInstance } from '@auth-portal/providers'
import { authService } from '@auth-portal/services'
import { IUserClaimProfile, IUserSignUp } from '@auth-portal/types'

import { useRecaptcha, useTrackingWrapper } from '../hooks'
import { Header } from './header'
import { LoginLink } from './login-link'

const initialCredentials: IUserSignUp = {
  email: '',
  password: '',
  confirmPassword: '',
  TncAndPrivacyPolicy: false,
}

const InitialUser: IUserClaimProfile = {
  email: '',
  isExistingUser: false,
}
export const SignupForm: React.FC<{}> = () => {
  const { addAlert } = useAlert()
  const [userClaimingProfile, setUserClaimingProfile] =
    useState<IUserClaimProfile>(InitialUser)
  const [isClaimingProfile, setIsClaimingProfile] = useState(false)
  const { t } = useTranslation('main')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { getRecaptchaToken } = useRecaptcha()
  const [credentials, setCredentials] =
    useState<IUserSignUp>(initialCredentials)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [errorString, setErrorString] = useState<string | undefined>(undefined)
  const error = !!errorString
  const subscriptionRef = useRef<Subscription | null>()
  const { track } = useTrackingWrapper()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const clientSettings: IClientSettings | null = useObservable(
    () => clientService.clientSetting$
  )

  const campaignId = searchParams.get('campaignId')
  const validate = Yup.object().shape({
    email: Yup.string()
      .required(t('form.email.requiredError'))
      .email(t('form.email.invalidError')),
    password: Yup.string()
      .required(t('form.changePassword.requiredError'))
      .min(6, t('form.passwordValidation.min6Characters'))
      .matches(/[A-Z]+/, t('form.passwordValidation.min1CapitalLetter'))
      .matches(/[a-z]+/, t('form.passwordValidation.min1LowerCaseLetter'))
      .matches(/\d+/, t('form.passwordValidation.min1Number')),
    confirmPassword: Yup.string()
      .required(t('form.confirmPasswordValidation.requiredField'))
      .oneOf(
        [Yup.ref('password'), null],
        t('form.passwordValidation.passwordMustMatch')
      ),
    TncAndPrivacyPolicy: Yup.bool().oneOf(
      [true],
      t('form.contractorSignup.required')
    ),
  })

  const displayClientConnections = useMemo(
    () =>
      clientSettings?.common.name === ClientNames.KPMG
        ? CompanyNames.ClientConnections
        : '',
    [clientSettings?.common.name]
  )

  const userId = searchParams.get('code')

  useEffect(() => {
    const subscription = new Subscription()

    if (userId && executeRecaptcha) {
      subscription.add(
        from(getRecaptchaToken(RecaptchaActions.Signup))
          .pipe(
            switchMap((token) =>
              authService.getUserEmailById(`${userId}`, token)
            )
          )
          .subscribe({
            next: ({ data }) => {
              if (data?.isExistingUser) {
                from(getRecaptchaToken(RecaptchaActions.ClaimContractorProfile))
                  .pipe(
                    switchMap((token) =>
                      authService.claimContractorProfile(userId, token)
                    )
                  )
                  .subscribe({})
                addAlert({
                  severity: 'warning',
                  message: t('form.login.alerts.claimedProfileExists'),
                })
                navigate(`/?email=${data.email}`)
              } else {
                setUserClaimingProfile(data)
                setIsClaimingProfile(true)
              }
            },
          })
      )
    }
    return () => {
      if (subscription && subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [addAlert, executeRecaptcha, getRecaptchaToken, navigate, t, userId])

  const handleSubmit = (values: any, actions: any): void => {
    const { email, password } = values

    const userEmail =
      userClaimingProfile.email.trim().length === 0
        ? email
        : userClaimingProfile.email

    subscriptionRef.current = from(
      getRecaptchaToken(RecaptchaActions.GetAnonymousUserInfo)
    )
      .pipe(
        switchMap((token) => authService.getPasswordInfo(userEmail, token)),
        switchMap((data) => {
          return combineLatest([
            of(data),
            from(getRecaptchaToken(RecaptchaActions.Signup)),
          ])
        }),
        switchMap(([data, token]) => {
          if (!data.hasPasswordExpired) {
            return authService.signUp(
              userEmail,
              password,
              userId,
              isClaimingProfile,
              token,
              campaignId || null
            )
          }
          navigate('/reset-expired-password', {
            state: {
              email,
              code: data?.code,
            },
            replace: true,
          })
          return EMPTY
        })
      )

      .subscribe({
        error: (err) => {
          const { status } = err.response
          let errorMessage = ''

          if (status === HTTPResponseStatus.UnprocessableEntity) {
            setErrorString(
              t('common.alert.emailExist', {
                email,
              })
            )

            errorMessage = t('common.alert.emailExist')
            rollbarInstance.error('This email already exists')
            actions.setErrors({ email: t('form.verifyEmail.emailExist') })
          } else {
            setErrorString(errorToString(t('somethingWrong')))
            errorMessage = t('somethingWrong')
          }

          track({
            eventName: `${gtmEventsPrefix.Notification}${gtmEvents.FormSignUpEmail}`,
            submissionStatus: 'false',
            email,
            errorMessage,
          })
        },
      })
    subscriptionRef.current.add(() => actions.setSubmitting(false))
  }

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (userClaimingProfile.email)
      setCredentials({
        ...initialCredentials,
        email: userClaimingProfile.email,
      })
  }, [userClaimingProfile.email])

  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <>
      <Formik
        initialValues={credentials}
        validationSchema={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, touched, errors, isSubmitting }) => {
          const isFormFilled =
            !!values.email &&
            !!values.password &&
            !!values.confirmPassword &&
            values.TncAndPrivacyPolicy

          return (
            <>
              {error && (
                <GorillaAlert
                  severity="error"
                  variant="filled"
                  sx={{
                    mb: 3,
                    '.MuiAlert-action': {
                      pt: 0,
                      alignItems: 'center',
                    },
                  }}
                  action={
                    <>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/"
                        size="small"
                      >
                        {t('form.login.heading')}
                      </Button>
                      <IconButton
                        size="small"
                        aria-label={t('common.aria.close')}
                        onClick={() => setErrorString('')}
                        sx={{ color: (theme) => theme.palette.common.white }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  }
                >
                  {errorString}
                </GorillaAlert>
              )}
              <Header />

              <Form noValidate>
                <Stack
                  spacing={2}
                  sx={{
                    px: {
                      xs: 1,
                      sm: 4,
                      lg: 5,
                      xl: 7,
                      k2: 12.5,
                    },
                  }}
                >
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label={t('form.email.label')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...(isClaimingProfile && {
                      variant: 'filled',
                    })}
                  />
                  <FormControl>
                    <Field
                      component={TextField}
                      name="password"
                      InputLabelProps={{ shrink: true }}
                      type={showPassword ? 'text' : 'password'}
                      label={t('form.changePassword.label')}
                      fullWidth
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label={t('common.aria.passwordVisibility')}
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <FormHelperText sx={{ display: 'flex', ml: 0, mt: 1 }}>
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 17, mr: 1, mt: 0.1 }}
                        color={
                          !errors.password && values.password
                            ? 'success'
                            : 'inherit'
                        }
                      />
                      {t('form.passwordValidation.passwordValidation')}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <Field
                      component={TextField}
                      name="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      label={t('form.changePassword.label3')}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label={t('common.aria.passwordVisibility')}
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText
                      sx={{ display: 'flex', mb: 2, ml: 0, mt: 1 }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ fontSize: 17, mr: 1, mt: 0.1 }}
                        color={
                          !errors.confirmPassword && values.confirmPassword
                            ? 'success'
                            : 'inherit'
                        }
                      />
                      {t('form.passwordValidation.passwordMustMatch')}
                    </FormHelperText>
                  </FormControl>

                  <FormControl sx={{ mb: 6.375 }}>
                    <Field
                      type="checkbox"
                      component={CheckboxWithLabel}
                      name="TncAndPrivacyPolicy"
                      Label={{
                        label: (
                          <Typography
                            variant="body2"
                            sx={(theme) => ({
                              color: theme.palette.text.secondary,
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                            })}
                          >
                            {t('form.contractorSignup.agreement', {
                              companyName: displayClientConnections,
                            })}
                            <MuiLink
                              tabIndex={-1}
                              component={RouterLink}
                              to="/terms-of-use"
                              target="_blank"
                              sx={{ fontSize: 14 }}
                            >
                              {t('footer.terms')}
                            </MuiLink>
                            {t('footer.and')}&nbsp;
                            <MuiLink
                              tabIndex={-1}
                              component={RouterLink}
                              to="/privacy-policy"
                              target="_blank"
                              sx={{ fontSize: 14 }}
                            >
                              {t('footer.privacyPolicy')}
                            </MuiLink>
                          </Typography>
                        ),
                      }}
                    />
                    {touched.TncAndPrivacyPolicy &&
                    errors.TncAndPrivacyPolicy ? (
                      <FormHelperText
                        id="TncAndPrivacyPolicy-text"
                        error
                        sx={{
                          ml: 0,
                        }}
                      >
                        {errors.TncAndPrivacyPolicy}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Stack>

                <Box mt={2} mb={3}>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="secondary"
                    sx={{ maxWidth: '440px' }}
                    type="submit"
                    disabled={!isFormFilled}
                    loading={isSubmitting}
                  >
                    {t('form.createAccount.signupWithEmail')}
                  </LoadingButton>
                </Box>

                <Button
                  variant="text"
                  size="large"
                  onClick={handleGoBack}
                  startIcon={<ArrowBackIcon />}
                >
                  {t('form.createAccount.back')}
                </Button>

                <LoginLink />
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}
