import React, { Suspense, useEffect, useMemo, useState } from 'react'
import Parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { catchError, from, map, Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import { Preloader } from '@procom-labs/atoms'
import {
  ClientNames,
  clientService,
  PreferredLanguageType,
  toKebabCase,
} from '@procom-labs/common'

import { LegalDocuments } from './legal-documents'

const style = `<style>
    .mainDiv { margin-top: 16px;  padding-block: 20px; width: fit-content}
    h2 { font-size: 2rem; font-weight: 500; margin-block: 0 }
    .overline {line-height: 2.66;
    text-transform: uppercase;
    margin-bottom: 0.35em; font-size: 1.203rem;}
    .sectionTitle {font-size: 24px;}
    .justify {text-align: justify;}
    .footer {font-size: 12px; text-align: center}
    </style>`

const getPrivacyFetchRequest = (clientName: string, lang: string) =>
  from(import(`../../assets/html/${clientName}/privacy-policy-${lang}`))

export const PrivacyPolicyPage: React.FC<{
  handleBack?: () => void
  lang?: PreferredLanguageType
}> = React.memo(({ handleBack, lang }) => {
  const { i18n } = useTranslation('main')
  const [content, setContent] = useState('')

  const clientName = useObservable(
    () =>
      clientService.clientSetting$.pipe(
        map((settings) => {
          return settings ? toKebabCase(settings.common.name) : null
        })
      ),
    null
  )

  const localLng = useMemo(() => {
    return lang || i18n.language?.toLowerCase() || PreferredLanguageType.EN
  }, [lang, i18n.language])

  const parsedHtml = useMemo(() => {
    return content?.trim() ? Parse(style + content) : ''
  }, [content])

  useEffect(() => {
    let subscription: Subscription | null = null
    if (clientName) {
      subscription = getPrivacyFetchRequest(clientName, localLng)
        .pipe(
          catchError(() =>
            getPrivacyFetchRequest(ClientNames.Procom.toLowerCase(), localLng)
          )
        )
        .subscribe({
          next: (value) => {
            setContent(value.PrivacyPolicy)
          },
          error: () => setContent(''),
        })
    }

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [clientName, localLng])

  if (content) {
    return (
      <Suspense fallback={<Preloader center />}>
        <LegalDocuments handleBack={handleBack}>{parsedHtml}</LegalDocuments>
      </Suspense>
    )
  }

  return null
})
