import React, { useEffect } from 'react'
import { authService } from '@auth-portal/services'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { FailedSocialLoginCallbackUrl } from '@auth-portal/util'

const SignInLinkedinCallback: React.FC<{}> = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const stateParam = searchParams.get('state')
  const state: {
    campaignId: string
  } | null = stateParam
    ? JSON.parse(decodeURIComponent(window.atob(stateParam))) // Decode needed for special chars
    : null
  useEffect(() => {
    const code = searchParams.get('code')
    const subscription = new Subscription()

    if (code)
      subscription.add(
        authService.linkedinLogin(code, state?.campaignId || null).subscribe()
      )
    else navigate(FailedSocialLoginCallbackUrl())

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [navigate, searchParams, state?.campaignId])

  return null
}

export default SignInLinkedinCallback
