import { IFeatureFlag } from '../../models'
import { BaseSubjectStore } from '../subject-store'

interface FeatureFlagStoreState {
  featureFlags: IFeatureFlag[]
}

class FeatureFlagStore extends BaseSubjectStore<FeatureFlagStoreState> {
  getDefaultValue(): Required<FeatureFlagStoreState> {
    return {
      featureFlags: [],
    }
  }

  setFeatureFlags(flags: IFeatureFlag[]): void {
    this.dispatch({ featureFlags: flags })
  }
}

export const featureFlagStore = new FeatureFlagStore()
