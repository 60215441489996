/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import ReactCodeInput from 'react-verification-code-input'
import { Observable, Subscription } from 'rxjs'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { LinkButton } from '@procom-labs/atoms'
import {
  errorToString,
  PasswordVerificationCodeResponse,
  useCounter,
} from '@procom-labs/common'

import { useAlert } from '../../hooks'

export const OtpInput: React.FC<{
  name: string
  label?: string
  sendCode?: () => Observable<any>
  verifyCode: (
    code: string
  ) => Observable<PasswordVerificationCodeResponse> | any
  showValidationIcon?: boolean
  sx?: SxProps<Theme>
  fields?: number
  width?: string
  sendCodeOnInitialLoad?: boolean
}> = ({
  name,
  label,
  sendCode,
  verifyCode,
  showValidationIcon,
  fields = 6,
  sx,
  width,
  sendCodeOnInitialLoad,
}) => {
  const theme = useTheme()
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')
  const [field, meta, helpers] = useField<string>(name)

  const { initCounter, counter } = useCounter()
  const [isVerifying, setIsVerifying] = useState(false)

  const codeRef = useRef<ReactCodeInput | null>(null)
  const subscriptionRef = useRef<Subscription | null>()

  const values = useMemo(
    () => (field.value ? field.value.split('') : []),
    [field.value]
  )

  const handleChange = useCallback(() => {
    if (meta.error) {
      helpers.setError('')
    }
  }, [helpers, meta.error])

  const clearSubscription = useCallback((): void => {
    if (subscriptionRef.current && !subscriptionRef.current.closed) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = null
    }
  }, [])

  const handleSendCode = useCallback(() => {
    if (sendCode) {
      setIsVerifying(true)
      handleChange()
      clearSubscription()
      codeRef.current?.__clearvalues__()
      subscriptionRef.current = sendCode().subscribe({
        complete: () => {
          initCounter({})
        },
        error: (err) => {
          const errorDesc =
            typeof err === 'string' ? errorToString(err) : err.message
          addAlert({
            severity: 'error',
            message: errorDesc || 'Request failed',
          })
        },
      })
      subscriptionRef.current.add(() => setIsVerifying(false))
    }
  }, [addAlert, clearSubscription, handleChange, initCounter, sendCode])

  const handleVerifyCode = useCallback(
    (code: string) => {
      if (!meta.touched) {
        helpers.setTouched(true)
      }
      setIsVerifying(true)
      clearSubscription()
      subscriptionRef.current = verifyCode(code).subscribe({
        next: ({
          isCodeValid,
          hasCodeExpired,
          hasReachedMaxAttempts,
        }: {
          isCodeValid: boolean
          hasCodeExpired: boolean
          hasReachedMaxAttempts: boolean
        }) => {
          if (isCodeValid) {
            helpers.setValue(code)
          } else {
            if (hasCodeExpired) {
              handleSendCode()
              helpers.setError(t('common.inputs.otp.codeExpired'))
            } else if (hasReachedMaxAttempts) {
              helpers.setError(t('common.inputs.otp.maxAttempts'))
            } else {
              helpers.setError(t('common.inputs.otp.invalidCode'))
            }
            codeRef.current?.__clearvalues__()
          }
        },
        error: (err: any) => {
          const errorDesc =
            typeof err === 'string' ? errorToString(err) : err.message
          addAlert({
            severity: 'error',
            message: errorDesc || 'Request failed',
          })
          helpers.setTouched(false)
          codeRef.current?.__clearvalues__()
        },
      })
      subscriptionRef.current?.add(() => setIsVerifying(false))
    },
    [
      addAlert,
      clearSubscription,
      handleSendCode,
      helpers,
      meta.touched,
      t,
      verifyCode,
    ]
  )

  useEffect(() => {
    if (sendCode && sendCodeOnInitialLoad) {
      handleSendCode()
    }
    return clearSubscription
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  return (
    <Box sx={{ ...sx }}>
      <FormControl
        fullWidth
        error={!!(!isVerifying && meta.touched && meta.error)}
        sx={{
          '.code-input-ctn': {
            width: width ?? '100% !important',
            div: {
              display: 'flex',
              columnGap: 1,
              input: {
                flexBasis: 0,
                flexGrow: 1,
                borderRadius: '4px',
                fontFamily: 'inherit',
                width: '100% !important',
                caretColor: 'currentColor',
                border: `solid 3px ${theme.palette.primary.light}`,
                '&:focus': {
                  border: `solid 3px ${theme.palette.primary.main}`,
                },
                '&:focus + input': {
                  borderLeft: `solid 3px ${theme.palette.primary.light}`,
                },
              },
            },
            'div[class*="styles_loading"]': {
              height: '54px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        }}
      >
        {label ? <FormLabel sx={{ marginBottom: 2 }}>{label}</FormLabel> : null}
        <ReactCodeInput
          autoFocus
          className="code-input-ctn"
          ref={codeRef}
          values={values}
          loading={isVerifying}
          onChange={handleChange}
          onComplete={handleVerifyCode}
          fields={fields}
        />
        {showValidationIcon && (
          <Grid
            mt={2}
            container
            flexWrap="nowrap"
            columnGap={1}
            alignItems="center"
          >
            {field.value ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CheckCircleOutlineIcon />
            )}
            <Typography variant="caption">
              {t('common.inputs.otp.validation')}
            </Typography>
          </Grid>
        )}
        {!isVerifying && meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormControl>

      {!field.value && sendCode && (
        <Grid
          item
          container
          xs={12}
          mt={3.2}
          mb={2.2}
          columnGap={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" color="text.secondary">
            {t('common.inputs.otp.noCode')}
          </Typography>

          <LinkButton
            disabled={isVerifying || counter > 0}
            underline={false}
            onClick={handleSendCode}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <>
              {t('common.inputs.otp.resendBtn')}

              {counter > 0 && (
                <>
                  {t('common.inputs.otp.resendIn')} {counter}
                </>
              )}
            </>
          </LinkButton>
        </Grid>
      )}
    </Box>
  )
}
