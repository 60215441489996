import { useCallback } from 'react'

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { useMixPanelTracking } from '../tracking/TrackingProvider'
import { useCommonTrackData } from './use-common-track-data'

interface IMixPanelTracking {
  [key: string]: any
}

interface IGTMTracking {
  [key: string]: any
}

export interface ITrack extends IMixPanelTracking, IGTMTracking {
  isTrackingEnabled: boolean
  isMixPanelTracking?: boolean
  isGTMTracking?: boolean
  eventName: string
  [key: string]: any
}

interface IUseTrackingEvent {
  trackData: (properties: ITrack) => void
}

export const useTrackingEvent = (): IUseTrackingEvent => {
  const commonProperties = useCommonTrackData()
  const { track: sendDatToMixPanel } = useMixPanelTracking()
  const sendDataToGTM = useGTMDispatch()

  const mixPanelTracking = useCallback(
    (mixPanelProperties: IMixPanelTracking) => {
      sendDatToMixPanel?.({
        name: mixPanelProperties?.eventName,
        properties: {
          ...mixPanelProperties,
        },
      })
    },
    [sendDatToMixPanel]
  )

  const gtmTracking = useCallback(
    (gtmProperties: IGTMTracking) => {
      sendDataToGTM({
        event: gtmProperties?.eventName,
        ...gtmProperties,
        ...commonProperties,
      })
    },
    [commonProperties, sendDataToGTM]
  )

  const trackData = useCallback(
    // gtm and mixpanel tracking are defaulted to true
    ({ isGTMTracking = true, isMixPanelTracking = true, ...rest }: ITrack) => {
      const { isTrackingEnabled, ...filteredRest } = rest
      if (isTrackingEnabled) {
        if (isMixPanelTracking && !!filteredRest.eventName) {
          mixPanelTracking(filteredRest)
        }
        if (isGTMTracking) {
          gtmTracking(filteredRest)
        }
      }
    },
    [mixPanelTracking, gtmTracking]
  )

  return {
    trackData,
  }
}
