import { SiteList } from '../config'
import { getOriginBase } from './getOriginBase'

export const getClientPortalHost = (): string | null => {
  return (
    getOriginBase() ||
    SiteList.flatMap((portal) => portal.domains).find((domain) => {
      return domain.authPortalUrl === window.location.origin
    })?.hostname ||
    ''
  )
}
