import Axios from 'axios-observable'
import { map, Observable } from 'rxjs'

import { IFeatureFlag } from '@common/models'

interface UtilsServiceConfig {
  axios: Axios
  jobEndpoint?: string
  authEndpoint: string
}

export class UtilsService {
  private readonly axios: Axios

  private readonly jobEndpoint?: string

  private readonly authEndpoint: string

  constructor(config: UtilsServiceConfig) {
    this.axios = config.axios
    this.jobEndpoint = config.jobEndpoint
    this.authEndpoint = config.authEndpoint
  }

  getResume(id: string): Observable<ArrayBuffer> {
    const url = `${this.jobEndpoint}/File/resume?${new URLSearchParams({
      fileId: `${id}`,
    })}`
    return this.axios
      .get<ArrayBuffer>(url, {
        responseType: 'arraybuffer',
      })
      .pipe(map(({ data }) => data))
  }

  getVendorFeatureFlags(): Observable<IFeatureFlag[]> {
    return this.axios
      .get<IFeatureFlag[]>(`${this.authEndpoint}/Vendor/feature-flag`)
      .pipe(map(({ data }) => data))
  }
}
