import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Grid, Stack, Typography } from '@mui/material'
import NotFoundSVG from '@procom-labs/common/src/assets/svg/not-found.svg'

export const PageNotFoundContent: React.FC<{
  showHomeButton?: boolean
}> = ({ showHomeButton = true }) => {
  const { t } = useTranslation('main')
  return (
    <Stack
      sx={{
        minHeight: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      spacing={5}
    >
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <svg
          style={{ height: 300, width: 384 }}
          data-src={NotFoundSVG}
          data-loading="lazy"
        />
      </Grid>
      {showHomeButton && (
        <>
          <Typography variant="subtitle1" paragraph>
            {t('organisms.pageNotExist')}
          </Typography>
          <Button
            href="/"
            variant="contained"
            size="large"
            sx={{ '&:hover': { color: '#fff' } }}
          >
            {t('organisms.backToHomeBtn')}
          </Button>
        </>
      )}
    </Stack>
  ) as ReactElement
}
