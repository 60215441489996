export enum PageTypes {
  Dashboard = 'Dashboard',
  LandingPages = 'Landing Pages',
  Login = 'Login',
  Register = 'Register',
  ForgotPassword = 'Forget Password',
  BuildProfile = 'Build Profile',
  SearchJobs = 'Search Jobs',
  Jobs = 'Jobs',
  JobDetails = 'Job Details',
  TermUse = 'Terms of Use',
  PrivacyPolicy = 'Privacy Policy',
  ClientDocs = 'Documents',
  Logout = 'Log out',
  Search = 'Search',
  AllJobs = 'All Jobs',
  RecommendedJobs = 'Recommended Jobs',
  MyJobs = 'My Jobs',
  AccountSettings = 'Account Settings',
  ChangePassword = 'Change Password',
  UploadResume = 'Upload Resume',
  Verify = 'Verify',
  WorkPreference = 'Work Preference',
  CommunicationPreference = 'Communication Preference',
  JobExperience = 'Job Experience',
  ExploreJobs = 'Explore Jobs',
  Recommended = 'Recommended',
  WorkOrders = 'Work Orders',
  Timesheets = 'Timesheets',
  Payment = 'Payment',
  Transaction = 'Transaction',
  Expenses = 'Expenses',
  EmployerJobs = 'Employer Jobs',
  EmployerJobDetail = 'Employer Job detail',
  EmployerJobsNewJob = 'Employer New Job',
  EmployerJobEdit = 'Employer Edit Job',
  EmployerJobAddCandidate = 'Employer Job Add Candidate',
  Candidates = 'Candidates',
  CandidateDetail = 'Candidate Detail',
  JobApply = 'Job Apply',
  TalentPools = 'TalentPools',
  TalentPoolDetails = 'TalentPoolDetails',
  ClientJobCreate = 'Client Job Create',
  ClientJobEdit = 'Client Job Edit',
  BullhornJobGenerator = 'Bullhorn Job Generator',
  ResumeCopilot = 'Resume Copilot',
  CandidateViewResume = 'Candidate View Resume',
  CandidateViewDocuments = 'Candidate View Documents',
  CandidateViewNotes = 'Candidate View Notes',
  CandidateViewTimeLine = 'Candidate View Timeline',
}
