import React, { useEffect } from 'react'

import { authService } from '@auth-portal/services'
import { useNavigate } from 'react-router-dom'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'
import { PageTypes } from '@procom-labs/common'

const Logout: React.FC<{}> = () => {
  const navigate = useNavigate()

  useGtmVpv({
    pageType: PageTypes.Logout,
    pageTitle: PageTypes.Logout,
  })

  useEffect(() => {
    const subscription = authService.logout().subscribe()
    subscription.add(() => {
      const searchParams = new URLSearchParams(window.location.search)
      navigate(`/initial-auth-route?${searchParams}`)
    })
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [navigate])

  return null
}

export default Logout
