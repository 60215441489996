export enum AuthProfileType {
  VendorAdmin = 'VendorAdmin',
  ServiceAccount = 'ServiceAccount',
  AccountManager = 'AccountManager',
  HiringManager = 'HiringManager',
  ResourceManager = 'ResourceManager',
  Recruiter = 'Recruiter',
  Contractor = 'Contractor',
  ClientService = 'ClientService',
  AppSupport = 'AppSupport',
  Marketer = 'Marketer',
}
export enum AuthSignupType {
  Email = 'email',
  Social = 'social',
}

export enum FboProfileTypeId {
  Organizational = 1,
  Temp = 3,
  CanadianSP = 4,
  IncWorker = 5,
  SubvendorWorker = 6,
  W2Worker = 7,
  USIncWokrer = 8,
}

export enum FboUrlTypeId {
  TimesheetDetail = 1,
  ExpenseDetail = 2,
  CreateExpense = 3,
}
