import React, { ReactElement } from 'react'

import { ResetPasswordForm, BaseLayout } from '@auth-portal/components'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'
import { PageTypes } from '@procom-labs/common'
import { useTranslation } from 'react-i18next'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation('main')
  useGtmVpv({
    pageType: PageTypes.ForgotPassword,
    pageTitle: t('form.reset.title', { lng: 'en' }),
  })

  return (
    <BaseLayout>
      <ResetPasswordForm />
    </BaseLayout>
  ) as ReactElement
}

export default ResetPassword
