/* eslint-disable no-unused-vars */
export enum HTTPResponseStatus {
  Ok = 200,
  Created = 201,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  FailedDependency = 424,
  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
}
