import { IClientSettings } from '@common/models/clients-settings'

import { TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE } from '../../../util/helpers'

export const DemoClientSettings: IClientSettings = {
  common: {
    name: 'DemoClient',
    effectiveDate: TERMS_OF_USE_AND_PRIVACY_POLICY_EFFECTIVE_DATE,
  },
}
