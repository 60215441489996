import { FC } from 'react'

import { AlertColor, SxProps, Theme } from '@mui/material'

import { GorillaAlert } from '../gorilla'

export const AlertMessage: FC<{
  text?: string
  severity?: AlertColor
  sx?: SxProps<Theme>
  onClose?: () => void
}> = ({ text, severity = 'error', sx, onClose, children }) => {
  return (
    <>
      <GorillaAlert
        severity={severity}
        sx={{ mb: 3, ...sx }}
        {...(onClose
          ? {
              onClose: () => {
                if (onClose) onClose()
              },
            }
          : {})}
      >
        {text && text}
        {children}
      </GorillaAlert>
    </>
  )
}
