import React from 'react'
import ReactCodeInput from 'react-verification-code-input'

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const DigitalCodeInput: React.FC<{
  isVerifying: boolean
  handleVerifyCode: (code: string) => void
}> = ({ isVerifying, handleVerifyCode }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        '& .code-input-ctn': { maxWidth: 200, margin: 'auto' },
      }}
    >
      <Box
        sx={{
          '.code-input-ctn': {
            width: '100% !important',
            div: {
              display: 'flex',
              columnGap: 1,
              input: {
                flexBasis: 0,
                flexGrow: 1,
                borderRadius: '4px',
                fontFamily: 'inherit',
                width: '100% !important',
                caretColor: 'currentColor',
                border: `solid 3px ${theme.palette.primary.light}`,
                '&:focus': {
                  border: `solid 3px ${theme.palette.primary.main}`,
                },
                '&:focus + input': {
                  borderLeft: `solid 3px ${theme.palette.primary.light}`,
                },
              },
            },
            'div[class*="styles_loading"]': {
              height: '54px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        }}
      >
        <ReactCodeInput
          autoFocus
          className="code-input-ctn"
          loading={isVerifying}
          onComplete={handleVerifyCode}
          fields={4}
        />
      </Box>
    </Box>
  )
}
