export const FailedSocialLoginCallbackUrl = (): string => {
  const socialLoginContext = localStorage.getItem('socialLoginContext')

  if (socialLoginContext) {
    localStorage.removeItem('socialLoginContext')
    const to =
      socialLoginContext.toLowerCase() === 'login'
        ? ''
        : socialLoginContext.toLowerCase()
    return `/${to}?${new URLSearchParams({
      error: `${socialLoginContext.toLowerCase()}Failed`,
    })}`
  }
  return `/?${new URLSearchParams({
    error: 'loginFailed',
  })}`
}
