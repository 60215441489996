import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
  IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js'
import { AxiosError, AxiosResponse } from 'axios'
import {
  catchError,
  EMPTY,
  map,
  Observable,
  Subscriber,
  switchMap,
  tap,
  throwError,
} from 'rxjs'

import {
  ActivityType,
  AuthProfileType,
  AwsErrorCodes,
  awsService,
  ClientQueryParams,
  closePopupWindow,
  ContactWithoutPasswordPayload,
  errorToString,
  getClientName,
  getClientPortalHost,
  getVendorCode,
  gtmEvents,
  gtmEventsPrefix,
  IAccountType,
  IAuthResponse,
  ICollaboratorUserInfo,
  ISocialLoginTokens,
  LoginTypes,
  MfaTypes,
  PasswordInfoResponse,
  PasswordVerificationCodeResponse,
  ProtectedLinksEnum,
  SiteList,
  SocialRoutes,
  SuperSubject,
} from '@procom-labs/common'

import { Pool } from '@auth-portal/config'
import { environment } from '@auth-portal/environment'
import { axios } from '@auth-portal/lib'
import {
  IUserAttributes,
  IUserEmailVerification,
  IUserSocialSession,
} from '@auth-portal/types'
import {
  FailedSocialLoginCallbackUrl,
  getCallbackURL,
  getDestinationURL,
  getOriginBaseUrl,
} from '@auth-portal/util'

import i18n from '../i18n'
import { rollbarInstance } from '../providers'
import { performanceTrackingService } from './performance-tracking.service'

declare let dataLayer: any // Global variable used by GTM to capture data. Defined to avoid TS warnings. Not compiled into JS.

const localStorageValues = {
  callbackUrl: getCallbackURL(),
  originBase: getOriginBaseUrl(),
  destinationURL: getDestinationURL(),
  authPortalBase: window.location.origin,
}

interface AuthenticateResponseObject {
  mfaType?: MfaTypes
}

type AuthenticateResponse = Observable<void | AuthenticateResponseObject>

interface IRefreshUserSessionInfoResponse {
  idToken: string
}

class AuthService {
  // Needed to resend MFA SMS code
  currentCognitoUserPasswordSubject = new SuperSubject('')

  isMfaCodeRequestedSubject = new SuperSubject(false)

  isMfaCodeRequested$ = this.isMfaCodeRequestedSubject.observable$

  cognitoUserHasMultipleMfaMethodsSubject = new SuperSubject(false)

  cognitoUserHasMultipleMfaMethods$ =
    this.cognitoUserHasMultipleMfaMethodsSubject.observable$

  isCognitoUserSessionExpiredSubject = new SuperSubject(false)

  isCognitoUserSessionExpired$ =
    this.isCognitoUserSessionExpiredSubject.observable$

  private readonly ACCOUNT_ENDPOINT = `${environment.AUTH_API_URL}/Account`

  private readonly SITECONFIGURATION_ENDPOINT = `${environment.AUTH_API_URL}/SiteConfiguration`

  private currentCognitoUserSubject = new SuperSubject<CognitoUser | null>(null)

  currentCognitoUser$: Observable<CognitoUser | null> =
    this.currentCognitoUserSubject.observable$

  private currentOtpUserSubject = new SuperSubject<IAuthResponse | null>(null)

  private currentCognitoUserEmailSubject = new SuperSubject('')

  currentCognitoUserEmail$: Observable<string> =
    this.currentCognitoUserEmailSubject.observable$

  private currentUserSessionSubject =
    new SuperSubject<CognitoUserSession | null>(null)

  private currentUserSocialSession =
    new SuperSubject<IUserSocialSession | null>(null)

  currentUserSocialSession$ = this.currentUserSocialSession.observable$

  private loginTypeSubject = new SuperSubject<LoginTypes | null>(null)

  loginType$ = this.loginTypeSubject.observable$

  private userAttributeSubject = new SuperSubject<IUserAttributes | null>(null)

  userAttribute$ = this.userAttributeSubject.observable$

  constructor() {
    axios.interceptors.response.use(
      this.handleResponse,
      this.handleResponseError
    )
  }

  getIfUserExist(email: string, recaptchaToken: string): Observable<boolean> {
    const url = `${this.ACCOUNT_ENDPOINT}/user-info?${new URLSearchParams({
      email,
      includeCognitoUser: 'false',
    })}`

    const headers = {
      recaptchaToken,
    }
    return axios.get(url, { headers }).pipe(map(({ data }) => data))
  }

  handleCheckClientDomain(
    email: string,
    recaptchaToken: string
  ): Observable<boolean> {
    const url = `${
      this.ACCOUNT_ENDPOINT
    }/validate-email-domain?${new URLSearchParams({
      email,
    })}`

    const headers = {
      recaptchaToken,
    }
    return axios.get(url, { headers }).pipe(map(({ data }) => data))
  }

  generateSignInOtp(
    email: string,
    recaptchaToken: string,
    language: string,
    isOtp = true
  ): Observable<any> {
    const url = `${this.ACCOUNT_ENDPOINT}/password-verification-code`
    return axios
      .post(url, {
        originBase: getOriginBaseUrl(),
        email,
        recaptchaToken,
        language,
        isOtp,
      })
      .pipe(
        map(({ data }) => {
          this.trackActivity(
            email,
            ActivityType.OtpRequest,
            true,
            'Verification code generated successfully'
          ).subscribe()
          return data
        }),
        this.catchErrorWrapper(email, ActivityType.OtpRequest, false)
      )
  }

  validateOtpSignIn(
    email: string,
    verificationCode: string,
    recaptchaToken: string
  ): Observable<PasswordVerificationCodeResponse> {
    const url = `${this.ACCOUNT_ENDPOINT}/password-verification-code-validity`
    return axios
      .post<PasswordVerificationCodeResponse>(url, {
        email,
        verificationCode,
        recaptchaToken,
      })
      .pipe(
        map(({ data }) => {
          const { isCodeValid, hasCodeExpired } = data
          const isSuccess = isCodeValid && !hasCodeExpired
          if (isSuccess) {
            this.trackActivity(
              email,
              ActivityType.OtpVerification,
              isSuccess,
              'Verification code validated successfully.'
            ).subscribe()
          } else {
            this.trackActivity(
              email,
              ActivityType.OtpVerification,
              isSuccess,
              `Verification code validation failed. ${JSON.stringify(data)}`
            ).subscribe()
          }
          return data
        }),
        this.catchErrorWrapper(email, ActivityType.OtpVerification, false)
      )
  }

  handleOtpSignIn(
    code: string,
    username: string,
    recaptchaToken: string
  ): Observable<IAuthResponse> {
    const url = `${this.ACCOUNT_ENDPOINT}/code-signin`
    return axios
      .post<IAuthResponse>(url, {
        code,
        username,
        recaptchaToken,
      })
      .pipe(
        map(({ data }) => {
          this.currentOtpUserSubject.value = data
          return data
        }),
        this.catchErrorWrapper(username, ActivityType.OtpVerification, false)
      )
  }

  createContactWithoutPassword(
    payload: ContactWithoutPasswordPayload,
    accountType: string
  ): Observable<void> {
    const url = `${this.ACCOUNT_ENDPOINT}/${
      accountType === IAccountType.Client ? 'client-contact/sign-up' : 'signup'
    }`
    return axios.post(url, payload).pipe(
      map(({ data }) => {
        return data
      })
    )
  }

  handleCognitoUserOnSuccess({
    cognitoSession,
    observer,
    isNewUser,
    username,
    queryParams,
  }: {
    cognitoSession: CognitoUserSession
    observer?: Subscriber<void>
    isNewUser?: boolean
    username: string
    queryParams?: object
  }): void {
    this.setCurrentUserSession(cognitoSession)
    if (observer) {
      this.trackActivity(
        username,
        isNewUser ? ActivityType.SignUp : ActivityType.Login,
        true
      ).subscribe({
        complete: () => {
          this.redirectToClientPortal(queryParams)
          observer.complete()
        },
      })
    }
  }

  handleCognitoUserOnFailure({
    observer,
    isNewUser,
    username,
    error,
    context,
  }: {
    observer?: Subscriber<void>
    isNewUser?: boolean
    username: string
    error: any
    context?: string | null
  }): void {
    const errorMessage = {
      code: error.code,
      name: error.name,
      message: error.message,
      stack: error.stack,
    }
    if (observer) {
      this.trackActivity(
        username,
        isNewUser ? ActivityType.SignUpFailed : ActivityType.LoginFailed,
        false,
        `${awsService.translateAWSErrors(
          error.code,
          i18n,
          context || ''
        )} ${JSON.stringify(errorMessage)}`
      ).subscribe({
        complete: () => {
          // NotAuthorizedException + MFA active means that MFA session is expired
          if (
            error.code === AwsErrorCodes.NotAuthorizedException &&
            this.isMfaCodeRequestedSubject.value
          ) {
            this.resetCognito()
            this.isCognitoUserSessionExpiredSubject.value = true
          }

          observer.error({
            code: error.code,
            message: awsService.translateAWSErrors(
              error.code,
              i18n,
              context || ''
            ),
          })
          rollbarInstance.error(error)
        },
      })
    }
  }

  handleCognitoUserTotpRequired({
    observer,
  }: {
    observer: Subscriber<AuthenticateResponseObject>
  }): void {
    this.isMfaCodeRequestedSubject.value = true
    observer.next({ mfaType: MfaTypes.Authenticator })
    observer.complete()
  }

  handleCognitoUserMfaRequired({
    observer,
    password,
  }: {
    observer: Subscriber<AuthenticateResponseObject>
    password?: string
  }): void {
    this.isMfaCodeRequestedSubject.value = true
    // Password is already saved if multiple MFA methods are enabled
    if (password) this.currentCognitoUserPasswordSubject.value = password

    observer.next({ mfaType: MfaTypes.SMS })
    observer.complete()
  }

  microsoftSSOLogin(idToken: string): Observable<any> {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
    return axios
      .get(`${this.ACCOUNT_ENDPOINT}/azure/common/token`, config)
      .pipe(
        map((response) => {
          const { data } = response
          this.currentUserSocialSession.value = {
            idToken: data.token,
          }
          this.redirectToClientPortal(
            {
              refreshToken: data.refreshToken,
              expiredTime: data.expiredTime,
              newAccount:
                data.isNewVendorClientContractorUser ||
                data.user?.isNewVendorClientContractorUser,
              refreshTokenExpiredTime: data.refreshTokenExpiredTime,
            },
            true
          )
          return response.data
        })
      )
  }

  authenticate({
    username,
    password,
    context,
    queryParams,
  }: {
    username: string
    password: string
    context?: string | null
    queryParams?: object
  }): AuthenticateResponse {
    return new Observable((observer) => {
      if (
        !this.currentCognitoUserSubject.value ||
        (this.currentCognitoUserSubject.value &&
          this.currentCognitoUserSubject.value.getUsername() !== username)
      ) {
        this.currentCognitoUserSubject.value = new CognitoUser({
          Username: username,
          Pool,
        })
      }
      const user = this.currentCognitoUserSubject.value
      this.currentCognitoUserEmailSubject.value = username
      const clientName = getClientName(getClientPortalHost())
      const userAuthDetails: IAuthenticationDetailsData = {
        Username: username,
        Password: password,
        ClientMetadata: {
          clientName,
        },
      }

      const isNewUser =
        queryParams &&
        ('newAccount' in queryParams || 'firstLogin' in queryParams)

      const authDetails = new AuthenticationDetails(userAuthDetails)

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          this.handleCognitoUserOnSuccess({
            cognitoSession: data,
            observer,
            isNewUser,
            username,
            queryParams,
          })
        },
        onFailure: (error) => {
          this.handleCognitoUserOnFailure({
            observer,
            isNewUser,
            username,
            error,
            context,
          })
        },

        newPasswordRequired: (userAttributes: IUserAttributes) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
          const { email_verified, ...rest } = userAttributes
          this.userAttributeSubject.value = rest
          // Reference: Use case 23
          //  https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js
          this.trackActivity(
            username,
            ActivityType.NewPasswordRequired,
            true
          ).subscribe({
            complete: () => {
              observer.complete()
            },
          })
        },

        totpRequired: () => {
          this.handleCognitoUserTotpRequired({ observer })
        },

        mfaRequired: () => {
          this.handleCognitoUserMfaRequired({ observer, password })
        },

        selectMFAType: () => {
          this.isMfaCodeRequestedSubject.value = true
          this.cognitoUserHasMultipleMfaMethodsSubject.value = true
          this.currentCognitoUserPasswordSubject.value = password
          observer.next()
          observer.complete()
        },
      })
    })
  }

  resetCognito(): void {
    this.currentCognitoUserSubject.value = null
    this.currentCognitoUserEmailSubject.value = ''
    this.currentCognitoUserPasswordSubject.value = ''
    this.currentUserSessionSubject.value = null
    this.isMfaCodeRequestedSubject.value = false
  }

  registerCollaborator(
    firstName: string,
    lastName: string,
    idToken: CognitoIdToken | string
  ): Observable<any> {
    const url = '/User/register'

    const headers = {
      Authorization: `Bearer ${idToken}`,
    }

    const data = {
      firstName,
      lastName,
    }

    return axios.put(url, data, { headers }).pipe(map(() => {}))
  }

  changePassword(
    password: string,
    email: string,
    confirmationCode: string
  ): Observable<any> {
    const url = '/Account/complete-reset-password'

    const data = {
      confirmationCode,
      email,
      password,
    }

    return axios
      .post(url, data)
      .pipe(
        this.catchErrorWrapper(
          email,
          ActivityType.NewPasswordRequired,
          false,
          'change-password'
        )
      )
  }

  initialLogin(password: string): Observable<string> {
    return new Observable((observer) => {
      if (this.currentCognitoUserSubject.value) {
        this.currentCognitoUserSubject.value.completeNewPasswordChallenge(
          password,
          this.userAttributeSubject.value,
          {
            onSuccess: () => {
              const idToken =
                this.currentCognitoUserSubject.value
                  ?.getSignInUserSession()
                  ?.getIdToken()
                  .getJwtToken() || ''
              observer.next(idToken)
              observer.complete()
            },
            onFailure: (error) => {
              observer.error(awsService.translateAWSErrors(error.code, i18n))
              rollbarInstance.error(error)
            },
          }
        )
      } else {
        observer.error(awsService.translateAWSErrors('', i18n))
      }
    })
  }

  signUp(
    Email: string,
    Password: string,
    UserId: string | null,
    IsClaimingProfile: boolean,
    RecaptchaToken: string,
    CampaignId: string | null
  ): AuthenticateResponse {
    const url = '/Account/signup'
    const clientInfo = SiteList.find((client) =>
      client.domains.find(
        (domain) => domain.authHostname === window.location.host
      )
    )

    const data = {
      Email,
      Password,
      UserId,
      IsClaimingProfile,
      RecaptchaToken,
      CampaignId: CampaignId || null,
      VendorCode: getVendorCode(),
      ClientCode: clientInfo?.code || null,
      OriginBase: localStorageValues.originBase,
      CallbackURL: localStorageValues.callbackUrl,
      DestinationURL: localStorageValues.destinationURL || '',
      AuthPortalBase: localStorageValues.authPortalBase,
      ProfileType: AuthProfileType.Contractor,
      language: i18n.language,
    }

    return axios.post(url, data).pipe(
      tap(() => {
        dataLayer.push({
          event: gtmEvents.FormSignUpEmail,
          submissionStatus: 'true',
          email: Email,
        })
      }),
      switchMap(() =>
        this.authenticate({
          username: Email,
          password: Password,
          queryParams: {
            newAccount: true,
          },
        })
      )
    )
  }

  getSocialLoginAwsToken(
    code: string,
    campaignId: string | null
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }

    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: environment.cognito.AWS_CLIENT_ID,
      redirect_uri: `${window.location.origin}/${SocialRoutes.SocialCallbackURL}`,
      code,
    })

    return axios
      .post(`${environment.cognito.AWS_AUTH_URL}/oauth2/token`, body, {
        headers,
      })
      .pipe(
        tap(({ data }) => {
          this.setCognitoSignInUserSession({
            refreshToken: data.refresh_token,
            idToken: data.id_token,
            accessToken: data.access_token,
          })
        }),
        switchMap(({ data }) => {
          this.currentUserSocialSession.value = {
            accessToken: data.access_token,
            tokenType: data.token_type,
            idToken: data.id_token,
          }
          return this.socialLogin(campaignId)
        })
      )
  }

  socialLogin(campaignId: string | null): Observable<any> {
    const url = '/Account/signin-social-cognito'

    const headers = {
      Authorization: `${this.currentUserSocialSession.value?.tokenType} ${this.currentUserSocialSession.value?.idToken}`,
    }

    const originBase = getOriginBaseUrl()

    const payload = {
      originBase,
      accessToken: this.currentUserSocialSession.value?.accessToken,
      tokenType: this.currentUserSocialSession.value?.tokenType,
      campaignId,
    }

    return axios
      .post<{ isNewVendorClientContractorUser: boolean }>(url, payload, {
        headers,
      })
      .pipe(
        tap(({ data }) => {
          dataLayer.push({
            event: `form-${localStorage.getItem('socialLoginContext')}`,
            submissionStatus: 'true',
            email: 'null',
            loginWith: localStorage.getItem('socialLoginType'),
          })

          localStorage.removeItem('socialLoginType')
          localStorage.removeItem('socialLoginContext')
          this.redirectToClientPortal(
            {
              newAccount: data.isNewVendorClientContractorUser,
            },
            true
          )
        }),
        catchError((err) => {
          dataLayer.push({
            event: `${gtmEventsPrefix.Notification}${gtmEvents.FormLogin}`,
            submissionStatus: 'false',
            email: 'null',
            loginWith: localStorage.getItem('socialLoginType'),
            errorMessage: errorToString(err),
          })
          localStorage.removeItem('socialLoginType')

          closePopupWindow(FailedSocialLoginCallbackUrl())
          return EMPTY
        })
      )
  }

  linkedinLogin(
    code: string | null,
    campaignId: string | null
  ): Observable<any> {
    const url = '/Account/signin-social-linkedin'

    const originBase = getOriginBaseUrl()
    const payload = {
      originBase,
      campaignId,
      authorizationCode: code,
      redirectUri: `${window.location.origin}/signin-linkedin-callback`,
    }
    return axios.post<IAuthResponse>(url, payload).pipe(
      tap(({ data }) => {
        dataLayer.push({
          event: `form-${localStorage.getItem('socialLoginContext')}`,
          submissionStatus: 'true',
          email: 'null',
          loginWith: localStorage.getItem('socialLoginType'),
        })
        localStorage.removeItem('socialLoginType')

        this.currentUserSocialSession.value = {
          idToken: data.token, // This is procom's ID token
        }
        localStorage.removeItem('socialLoginContext')
        this.redirectToClientPortal(
          {
            refreshToken: data.refreshToken,
            expiredTime: data.expiredTime,
            newAccount:
              data.isNewVendorClientContractorUser ||
              data.user?.isNewVendorClientContractorUser,
            refreshTokenExpiredTime: data.refreshTokenExpiredTime,
          },
          true
        )
      }),
      catchError((err) => {
        dataLayer.push({
          event: `${gtmEventsPrefix.Notification}${gtmEvents.FormLogin}`,
          submissionStatus: 'false',
          email: 'null',
          loginWith: localStorage.getItem('socialLoginContext'),
          errorMessage: errorToString(err),
        })
        localStorage.removeItem('socialLoginType')

        closePopupWindow(FailedSocialLoginCallbackUrl())
        return EMPTY
      })
    )
  }

  logout(): Observable<void> {
    return new Observable((observer) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.signOut()
        observer.complete()
      } else {
        observer.error('There is no user session')
        rollbarInstance.info('There is no user session')
      }
    })
  }

  requestPasswordReset(email: string, recaptchaToken: string): Observable<any> {
    const callbackUrl = getCallbackURL()
    const originBase = getOriginBaseUrl()
    const destinationURL = getDestinationURL()
    const authPortalBase = window.location.origin
    const headers = {
      recaptchaToken,
    }

    const url = `/Account/request-reset-password?${new URLSearchParams({
      Email: email,
      OriginBase: originBase,
      CallbackURL: callbackUrl,
      DestinationURL: destinationURL || '',
      AuthPortalBase: authPortalBase,
      LoginType:
        this.loginTypeSubject.value?.toLowerCase() ||
        LoginTypes.Client.toLowerCase(),
    })}`

    return axios
      .get(url, { headers })
      .pipe(
        this.catchErrorWrapper(email, ActivityType.NewPasswordRequired, false)
      )
  }

  verifyEmail(userEmailVerification: IUserEmailVerification): Observable<any> {
    const url = '/Account/verify-user-email'
    const data = {
      email: userEmailVerification.email,
      confirmationCode: userEmailVerification.confirmationCode,
      migrationToken: userEmailVerification.migrationToken,
      onboardingCode: userEmailVerification.onboardingCode,
    }

    return axios.post(url, data).pipe(catchError(this.catchAwsError))
  }

  getPasswordInfo(
    email: string,
    recaptchaToken: string
  ): Observable<PasswordInfoResponse> {
    const headers = {
      recaptchaToken,
    }
    return axios
      .get<PasswordInfoResponse>(
        `${
          environment.AUTH_API_URL
        }/Account/user-password-info?${new URLSearchParams({
          email,
          originBase: getOriginBaseUrl() || window.location.origin,
        })}`,
        { headers }
      )
      .pipe(map(({ data }) => data))
  }

  migrateSignUp(
    email: string,
    password: string,
    recaptchaToken: string,
    verificationCode: string,
    userId: string
  ): Observable<any> {
    const data = {
      userId,
      email,
      password,
      recaptchaToken,
      verificationCode,
      VendorCode: getVendorCode(),
      OriginBase: localStorageValues.originBase,
      CallbackURL: localStorageValues.callbackUrl,
      DestinationURL: localStorageValues.destinationURL || '',
      AuthPortalBase: localStorageValues.authPortalBase,
      language: i18n.language,
    }

    return axios
      .post(`${this.ACCOUNT_ENDPOINT}/migrated-user-signup`, data)
      .pipe(switchMap(() => this.authenticate({ username: email, password })))
  }

  sendVerficationCode(code: string, recaptchaToken: string): Observable<void> {
    return axios
      .post(`${environment.AUTH_API_URL}/Account/password-verification-code`, {
        code,
        recaptchaToken,
        originBase: encodeURIComponent(
          getOriginBaseUrl() || window.location.origin
        ),
      })
      .pipe(map(() => {}))
  }

  validateVerficationCode(
    code: string,
    verificationCode: string,
    recaptchaToken: string
  ): Observable<PasswordVerificationCodeResponse> {
    return axios
      .post<PasswordVerificationCodeResponse>(
        `${environment.AUTH_API_URL}/Account/password-verification-code-validity`,
        {
          code,
          verificationCode,
          recaptchaToken,
        }
      )
      .pipe(map(({ data }) => data))
  }

  getUserMigrationInfo(
    migrationToken: string,
    source: string
  ): Observable<any> {
    const url = `/UserMapping/GetUserMigrationInfo/${migrationToken}/${source}`
    const baseURL = environment.MAP_FBO_USER_SERVICE_BASE_URL
    const headers = {
      OSApiId: environment.MAP_FBO_USER_SERVICE_API_ID,
      OSApiKey: environment.MAP_FBO_USER_SERVICE_API_KEY,
    }

    return axios.get(url, { baseURL, headers })
  }

  getUserEmailById(id: string, token: string): Observable<any> {
    const url = `User/${id}/email`
    const headers = {
      Accept: 'text/plain',
      RecaptchaToken: token,
    }
    return axios.get<string>(url, { headers })
  }

  claimContractorProfile(
    userId: string,
    recaptchaToken: string
  ): Observable<any> {
    const url = `ContractorProfile/claim`
    const data = {
      userId,
      recaptchaToken,
    }
    return axios.post(url, data)
  }

  activateFboUserMapping(
    migrationToken: string | null,
    onboardingCode: string | null,
    email: string | null,
    recaptchaToken: string,
    fboTenantName: string | null
  ): Observable<any> {
    const url = '/Account/activate-fbo-mapping'
    const data = {
      migrationToken,
      onboardingCode,
      email,
      recaptchaToken,
      fboTenantName,
    }

    return axios.post(url, data)
  }

  setUserRegisteredOn(): Observable<any> {
    const idToken = this.currentCognitoUserSubject?.value
      ?.getSignInUserSession()
      ?.getIdToken()
      .getJwtToken()
    const headers = {
      Authorization: `Bearer ${idToken}`,
    }

    const url = 'Account/set-user-registered-on'
    return axios.put(url, null, { headers })
  }

  redirectToClientPortal(
    customQueryParams?: object,
    socialLogin?: boolean
  ): void {
    const callbackURL = getCallbackURL()
    let queryParams: any
    if (socialLogin) {
      queryParams = {
        ...customQueryParams,
        token: this.currentUserSocialSession.value?.idToken,
      }
    } else {
      const isOptUserSubject = this.currentOtpUserSubject?.value
        ? this.currentOtpUserSubject?.value?.token
        : ''
      queryParams = {
        token: this.currentCognitoUserSubject?.value
          ? this.currentCognitoUserSubject?.value
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()
          : isOptUserSubject,
        ...customQueryParams,
      }
    }
    if (callbackURL && queryParams.token) {
      const destinationURL = getDestinationURL()
      if (destinationURL) {
        queryParams.destinationURL = decodeURIComponent(destinationURL)
        localStorage.removeItem(ClientQueryParams.destinationURL)
      }

      // window.opener.length > 1 = GTM debugger active
      // GTM debugger uses window.opener
      if (window.opener && window.opener.length > 1) {
        const callbackHref = `${decodeURIComponent(
          callbackURL
        )}?${new URLSearchParams(queryParams)}`

        closePopupWindow(callbackHref)
      } else {
        window.location.href = `${decodeURIComponent(
          callbackURL
        )}?${new URLSearchParams(queryParams)}`
      }
    }
  }

  setLoginType(): void {
    let loginType = localStorage.getItem(ClientQueryParams.loginType)
    if (loginType) {
      loginType =
        loginType === LoginTypes.Contractor &&
        environment.ENABLE_CONTRACTOR_LOGIN !== 'true'
          ? LoginTypes.Client
          : loginType
      this.loginTypeSubject.value = loginType as LoginTypes
    }
  }

  resendUserInvitation(
    email: string,
    recaptchaToken: string,
    fboMigrationToken?: string | null,
    onboardingCode?: string | null
  ): Observable<any> {
    const { originBase, destinationURL } = localStorageValues
    const url = '/Account/resend-user-invitation'

    const body = {
      email,
      fboMigrationToken,
      recaptchaToken,
      originBase,
      onboardingCode,
      destinationUrl: destinationURL,
    }

    return axios.post(url, body)
  }

  getSiteConfigurationByOriginBase(): Observable<any> {
    const url = `${this.SITECONFIGURATION_ENDPOINT}/single?baseUrl=${localStorageValues.originBase}`
    return axios.get<any>(url)
  }

  getUserInfoByEmail(email: string, recaptchaToken: string): Observable<any> {
    const headers = {
      recaptchaToken,
    }
    const url = `/Account/user-info?${new URLSearchParams({
      email,
    })}`
    return axios.get<boolean>(url, { headers }).pipe(map(({ data }) => data))
  }

  clientSignup(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    recaptchaToken: string
  ): Observable<any> {
    const url = 'Account/client/sign-up'
    const body = {
      email,
      password,
      recaptchaToken,
      firstName,
      lastName,
      vendorCode: getVendorCode(),
      originBase: localStorageValues.originBase,
      callbackURL: localStorageValues.callbackUrl,
      destinationURL:
        localStorageValues.destinationURL || ProtectedLinksEnum.Dashboard,
      authPortalBase: localStorageValues.authPortalBase,
    }

    return axios.post(url, body).pipe(
      switchMap(() =>
        this.authenticate({
          username: email,
          password,
          queryParams: { firstLogin: true },
        })
      )
    )
  }

  resendEmailVerification(
    destinationURL: string,
    loginType: LoginTypes,
    newCPUser?: boolean,
    token?: string
  ): Observable<any> {
    let headers = {}
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return axios.post(
      `${environment.AUTH_API_URL}/Account/resend-confirmation-code`,
      {
        destinationURL,
        loginType: loginType.toLowerCase(),
        newCPUser: newCPUser || false,
        callBackUrl: localStorageValues.originBase,
      },
      {
        headers,
      }
    )
  }

  getCollaboratorInfo(
    recaptchaToken: string,
    email: string,
    token?: string
  ): Observable<ICollaboratorUserInfo> {
    const headers = {
      recaptchaToken,
    }
    return axios
      .get<ICollaboratorUserInfo>(
        `${
          environment.AUTH_API_URL
        }/Account/collaborator-user-info?${new URLSearchParams({
          email,
          originBase: window.location.origin,
          ...(token && { token }),
        })}`,
        { headers }
      )
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  trackActivity(
    email: string,
    activityType: ActivityType,
    isSuccess: boolean,
    errorMessage?: string
  ): Observable<any> {
    return axios
      .post(
        `${environment.AUTH_API_URL}/Account/user-authentication-activity-log`,
        {
          email,
          activityType,
          isSuccess,
          errorMessage,
        }
      )
      .pipe(map(() => {}))
  }

  catchErrorWrapper(
    email: string,
    activityType: ActivityType,
    isSuccess: boolean,
    context = ''
  ): any {
    return catchError((error: AxiosError) => {
      const cognitoErrorCode = errorToString(error)
      this.trackActivity(
        email,
        activityType,
        isSuccess,
        cognitoErrorCode
      ).subscribe()
      return throwError(
        () =>
          new Error(
            awsService.translateAWSErrors(cognitoErrorCode, i18n, context)
          )
      )
    })
  }

  private catchAwsError(error: AxiosError): Observable<never> {
    const cognitoErrorCode = errorToString(error)
    return throwError(
      () => new Error(awsService.translateAWSErrors(cognitoErrorCode, i18n))
    )
  }

  private refreshUserAttributes(): Observable<IUserAttributes> {
    return new Observable((observer) => {
      if (this.currentCognitoUserSubject.value) {
        this.currentCognitoUserSubject.value.getUserAttributes(
          (error: Error | undefined, attributes) => {
            if (error) {
              observer.error(error.message)
              rollbarInstance.error(error)
            } else if (attributes) {
              const mappings = attributes.reduce(
                (obj, item) =>
                  Object.assign(obj, {
                    [item.Name]: item.Value,
                  }),
                {}
              )
              observer.next({
                email: '',
                email_verified: '',
                name: '',
                sub: '',
                ...mappings,
              })
              observer.complete()
            }
          }
        )
      } else {
        observer.error()
        rollbarInstance.error()
      }
    })
  }

  refreshUserSessionInfo(): Observable<IRefreshUserSessionInfoResponse> {
    return new Observable((observer) => {
      const user = Pool.getCurrentUser() // Get user from Cognito's cache (local storage)
      if (user) {
        this.currentCognitoUserSubject.value = user

        // This will automatically get a new id token if refresh token is valid
        user.getSession((error: Error | null, session: CognitoUserSession) => {
          if (error) {
            observer.error(error.message)
            rollbarInstance.error(error)
          } else {
            this.setCurrentUserSession(session)
            const idToken: string = session.getIdToken().getJwtToken()
            observer.next({ idToken })
            observer.complete()
          }
        })
      } else {
        observer.error('No user found in localstorage')
        rollbarInstance.warning('No user found in cognito local storage')
      }
    })
  }

  setCognitoSignInUserSession(socialLoginTokens: ISocialLoginTokens): void {
    // Create a new session baed on what we stored from social login
    const newUserSession = new CognitoUserSession({
      AccessToken: new CognitoAccessToken({
        AccessToken: socialLoginTokens.accessToken,
      }),
      IdToken: new CognitoIdToken({
        IdToken: socialLoginTokens.idToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: socialLoginTokens.refreshToken,
      }),
    })

    const email = newUserSession.getIdToken().decodePayload()?.email || ''

    // For Social Login the user is not saved in local storage
    // we create one using the email from the token
    const user = new CognitoUser({
      Username: email,
      Pool,
    })

    user.setSignInUserSession(newUserSession)
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        rollbarInstance.error(error)
      } else {
        this.setCurrentUserSession(session)
        this.currentCognitoUserSubject.value = user
      }
    })
  }

  private setCurrentUserSession(cognitoUserSession: CognitoUserSession): void {
    this.currentUserSessionSubject.value = cognitoUserSession
  }

  // arrow syntax has to be used to pass `this` reference
  private handleResponse = (response: AxiosResponse): AxiosResponse => {
    if (environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
      performanceTrackingService.sendPerfTracking({ response })
    }
    return response
  }

  private handleResponseError = (error: AxiosError): Promise<any> => {
    const { response } = error
    if (response && response.status >= 400 && response.status !== 404) {
      rollbarInstance.error(error)
    }
    if (response && environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
      performanceTrackingService.sendPerfTracking({ response, isError: true })
    }
    return Promise.reject(error)
  }
}

export const authService = new AuthService()
