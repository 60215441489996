import {
  gtmEvents,
  ITrackingPanel,
  SubmissionTrackingStatus,
} from '@procom-labs/common'

import { ISsoLoginTrackingData } from '@auth-portal/types'
import { AccountInfo } from '@azure/msal-browser'

export const ssoLoginTrackingDate = (
  account: AccountInfo,
  submissionStatus = SubmissionTrackingStatus.Submitted,
  trackingProps: ITrackingPanel = {}
): ISsoLoginTrackingData => {
  const {
    isGTMTracking = true,
    isMixPanelTracking = true,
    isTrackingEnabled = true,
  } = trackingProps
  return {
    eventName: gtmEvents.FormMsSSOLogin,
    submissionStatus,
    email: account.username,
    name: account.name ?? '',
    loginType: 'MS_SSO_Login',
    isGTMTracking,
    isMixPanelTracking,
    isTrackingEnabled,
  }
}
