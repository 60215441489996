import { useMemo } from 'react'
import { useObservableEagerState } from 'observable-hooks'

import { AppTypes } from '../enums'
import { clientService } from '../services'

export const useIsGorillaTheme = (): boolean => {
  const appType = useObservableEagerState(clientService.appType$)

  return useMemo(() => {
    return appType === AppTypes.auth || appType === AppTypes.client
  }, [appType])
}
