import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { map } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import {
  ClientQueryParams,
  getVendorCode,
  LoginTypes,
  PageTypes,
  VendorCodes,
} from '@procom-labs/common'

import { BaseLayout, SignupMethods } from '@auth-portal/components'
import { useGtmVpv } from '@auth-portal/hooks/use-gtm-vpv'
import { ClientSignup } from '@auth-portal/routes/signup-client'
import { authService } from '@auth-portal/services'

const ContractorSignup: React.FC = () => {
  const [searchParams] = useSearchParams()
  const loginTypeParam = searchParams.get(ClientQueryParams.loginType)

  const loginType: LoginTypes | null = useObservable(
    () => authService.loginType$.pipe(map((val) => val)),
    null
  )

  useGtmVpv({
    pageType: PageTypes.Register,
    pageTitle: PageTypes.Register,
  })
  useEffect(() => {
    if (
      loginTypeParam &&
      loginTypeParam.toLowerCase() !== loginType?.toLowerCase()
    ) {
      localStorage.setItem(ClientQueryParams.loginType, loginTypeParam)
      authService.setLoginType()
    }
  }, [loginType, loginTypeParam])

  if (
    loginType?.toLowerCase() === LoginTypes.Client.toLowerCase() ||
    ![VendorCodes.PCGL, VendorCodes.DEMO, VendorCodes.DEMOSANDBOX].includes(getVendorCode())
  ) {
    return (
      <BaseLayout>
        <ClientSignup />
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <SignupMethods />
    </BaseLayout>
  )
}

export default ContractorSignup
