import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { from, Subscription, switchMap } from 'rxjs'
import * as yup from 'yup'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import CircularProgressIcon from '@mui/material/CircularProgress'
import { GorillaAlert } from '@procom-labs/atoms'
import {
  errorToString,
  HTTPResponseStatus,
  RecaptchaActions,
} from '@procom-labs/common'
import { OtpInput, useAlert } from '@procom-labs/molecules'

import { authService } from '@auth-portal/services'

import { useRecaptcha } from '../hooks'

type ExpiredPasswordPayload = {
  email: string
  password: string
  confirmPassword: string
  verificationCode: string
}

type ExpiredPasswordParams = {
  email: string
  code: string
}

export const ExpiredPasswordForm: React.FC<{}> = () => {
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const { state } = useLocation()

  const [searchParams] = useSearchParams()
  const paramEmail = searchParams.get('email')
  const paramCode = searchParams.get('code')

  const { email, code } = state
    ? (state as ExpiredPasswordParams)
    : { email: paramEmail || '', code: paramCode || '' }

  const subscriptionRef = useRef<Subscription | null>()

  const [meta, setMeta] = useState({
    showPassword: false,
    showConfirmPassword: false,
  })

  const { getRecaptchaToken, executeRecaptcha } = useRecaptcha()

  const initialValue: ExpiredPasswordPayload = useMemo(
    () => ({
      email,
      password: '',
      confirmPassword: '',
      verificationCode: '',
    }),
    [email]
  )

  const validationSchema = useMemo(
    () =>
      yup.object({
        codeVerified: yup.boolean().oneOf([true], 'Verification required'),
        password: yup
          .string()
          .required(t('form.changePassword.requiredError'))
          .min(6, t('form.passwordValidation.min6Characters'))
          .matches(/[A-Z]+/, t('form.passwordValidation.min1CapitalLetter'))
          .matches(/[a-z]+/, t('form.passwordValidation.min1LowerCaseLetter'))
          .matches(/\d+/, t('form.passwordValidation.min1Number')),
        confirmPassword: yup
          .string()
          .required()
          .oneOf(
            [yup.ref('password'), null],
            t('form.passwordValidation.passwordMustMatch')
          ),
      }),
    [t]
  )

  const handleSubmit = (values: ExpiredPasswordPayload, actions: any): void => {
    actions.setSubmitting(false)
    subscriptionRef.current = from(getRecaptchaToken(RecaptchaActions.Signup))
      .pipe(
        switchMap((token: string) => {
          return authService.migrateSignUp(
            values.email,
            values.password,
            token,
            values.verificationCode,
            code
          )
        })
      )
      .subscribe({
        error: (error) => {
          const { status } = error.response
          addAlert({
            severity: 'error',
            message:
              status === HTTPResponseStatus.UnprocessableEntity
                ? t('common.alert.emailExist', {
                    email,
                  })
                : errorToString(error),
          })
        },
      })
    subscriptionRef.current.add(() => actions.setSubmitting(false))
  }

  const handleShowPassword = useCallback(
    () =>
      setMeta((obj) => ({
        ...obj,
        showPassword: !obj.showPassword,
      })),
    []
  )

  const handleShowConfirmPassword = useCallback(
    () =>
      setMeta((obj) => ({
        ...obj,
        showConfirmPassword: !obj.showConfirmPassword,
      })),
    []
  )

  const handleVerifyCode = useCallback(
    (otpCode: string) =>
      from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).pipe(
        switchMap((token: string) =>
          authService.validateVerficationCode(code, otpCode, token)
        )
      ),
    [getRecaptchaToken, code]
  )

  const handleSendCode = useCallback(() => {
    return from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).pipe(
      switchMap((token: string) => authService.sendVerficationCode(code, token))
    )
  }, [getRecaptchaToken, code])

  useEffect(() => {
    return () => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
        subscriptionRef.current = null
      }
    }
  }, [])

  if (executeRecaptcha) {
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          container
          xs={10}
          mb={6}
          alignItems="center"
          justifyContent="center"
          direction="column"
          rowGap={2}
        >
          <Grid item display="inline">
            <Typography variant="body2" display="inline">
              {t('form.expiredPassword.for')} &nbsp;
            </Typography>
            <Typography variant="subtitle1Bold" display="inline">
              {email}
            </Typography>
          </Grid>
          <GorillaAlert
            severity="error"
            iconMapping={{
              warning: <InfoOutlinedIcon color="error" />,
            }}
            sx={{
              alignItems: 'center',
              borderRadius: '10px',
              maxWidth: 400,
            }}
          >
            <Typography variant="body2" color="text.primary">
              {t('common.inputs.otp.alert')}
            </Typography>
          </GorillaAlert>
        </Grid>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValue}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnMount
        >
          {({ values, isSubmitting, errors, dirty }) => {
            return (
              <Grid
                item
                container
                xs={10}
                noValidate
                component={Form}
                sx={{ margin: '0 auto' }}
              >
                <Grid item container rowGap={2} xs={12}>
                  {!values.verificationCode ? (
                    <Grid item xs={12}>
                      <OtpInput
                        name="verificationCode"
                        label={t('common.inputs.otp.enterCode')}
                        sendCode={handleSendCode}
                        verifyCode={handleVerifyCode}
                        sendCodeOnInitialLoad
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Grid item container rowGap={2} xs={12}>
                        <Grid item xs={12}>
                          <Field name="password">
                            {({ field }: any) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t('form.expiredPassword.label')}
                                InputLabelProps={{ shrink: true }}
                                type={meta.showPassword ? 'text' : 'password'}
                                InputProps={{
                                  autoComplete: 'off',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        tabIndex={-1}
                                        disabled={!values.password}
                                        onClick={handleShowPassword}
                                        edge="end"
                                      >
                                        {meta.showPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          columnGap={1}
                          flexWrap="nowrap"
                        >
                          {errors.password || !dirty ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <CheckCircleIcon color="success" />
                          )}
                          <Typography variant="caption">
                            {t('form.passwordValidation.passwordValidation')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="confirmPassword">
                            {({ field }: any) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t('form.expiredPassword.label2')}
                                InputLabelProps={{ shrink: true }}
                                type={
                                  meta.showConfirmPassword ? 'text' : 'password'
                                }
                                InputProps={{
                                  autoComplete: 'off',
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        tabIndex={-1}
                                        disabled={!values.confirmPassword}
                                        onClick={handleShowConfirmPassword}
                                        edge="end"
                                      >
                                        {meta.showConfirmPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          columnGap={1}
                          flexWrap="nowrap"
                        >
                          {errors.confirmPassword || !dirty ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <CheckCircleIcon color="success" />
                          )}
                          <Typography variant="caption">
                            {t('form.passwordValidation.passwordMustMatch')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  rowGap={1}
                  justifyContent="center"
                  mt={1}
                >
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    fullWidth
                    endIcon={
                      isSubmitting ? (
                        <CircularProgressIcon size={24} />
                      ) : (
                        <ArrowForwardIcon />
                      )
                    }
                    disabled={isSubmitting || !values.verificationCode}
                  >
                    {t('common.btn.login')}
                  </Button>
                </Grid>
              </Grid>
            )
          }}
        </Formik>
      </Grid>
    )
  }
  return null
}
